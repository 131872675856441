import classNames from 'classnames';
import { FC } from 'react';

import './tabs.scss';

interface ITab {
  isSelected: boolean;
  isDisabled?: boolean;
  text: string;
  clickHandler: () => void;
}

export interface ITabsProps {
  tabsList: ITab[];
  withScrollIntoView?: boolean;
  isCentered?: boolean;
}

export const Tabs: FC<ITabsProps> = ({ tabsList, withScrollIntoView = false }) => {
  return (
    <div className="tabs">
      <div className="grid-container tabs__list">
        {tabsList.map(({ isSelected, isDisabled, text, clickHandler }, index) => (
          <button
            className={classNames('tabs__tab', {
              'tabs__tab--selected': isSelected,
              'tabs__tab--disabled': isDisabled,
            })}
            onClick={(e) => {
              clickHandler();
              if (e.target && withScrollIntoView) {
                (e.target as HTMLBaseElement)?.scrollIntoView({ block: 'nearest', inline: 'end' });
              }
            }}
            key={index}
          >
            {text}
          </button>
        ))}
      </div>
    </div>
  );
};
