import { FC } from 'react';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { ICSCorImage, ICSCorPageConfiguration } from 'corporate-interfaces';
import { isEmpty } from 'lodash-es';
import { useContent } from 'hooks/use-content';

export interface ICorRecipeSchema {
  heading: string;
  description: string;
  image: ICSCorImage;
}

export const CorRecipeSchema: FC<ICorRecipeSchema> = ({ heading, description, image }) => {
  const excludeHtmlTagsRegEx = /<\/?(?!li\b)[^>]+(>|$)/g;

  const { getContentByKey } = useContent();
  const ingredientsList = getContentByKey('ingredients_list', '');
  const datePublished = getContentByKey('publication_date', '');
  const recipeInstructions = getContentByKey(
    'modular_blocks.0.directions.directions_2_column_group.directions_right_column_content.0',
    ''
  );
  const pageConfiguration: ICSCorPageConfiguration = getContentByKey(
    'page_configuration',
    {} as ICSCorPageConfiguration
  );
  const { seo_metadata } = pageConfiguration;

  const recipeStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Recipe',
    name: heading,
    image: corContentstackImageLinkResolver(image)?.url ?? '',
    description,
    keywords: seo_metadata?.keywords?.[0] ?? '',
    recipeIngredient: ingredientsList
      .replace(excludeHtmlTagsRegEx, '')
      .split('<li>')
      .filter((ingredient) => !isEmpty(ingredient)),
    recipeInstructions: [recipeInstructions.replace(excludeHtmlTagsRegEx, '')],
    datePublished,
    author: {
      '@type': 'Organization',
      name: 'Dawn Food Products, Inc',
      url: 'https://www.dawnfoods.com/',
    },
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(recipeStructuredData),
      }}
    />
  );
};
