import { FC, useEffect, useState, useCallback, PropsWithChildren, useRef } from 'react';
import Stack, { onEntryChange } from 'corporate-utils/contenstack-sdk/live-preview';
import {
  CorCardList,
  CorHero,
  CorTwoColumn,
  CorForm,
  CorThreeColumn,
  CorAccordionComponent,
  CorDistributionMap,
  CorFilteredList,
  CorNewsListing,
} from 'corporate-components';
import { CorLivePreviewLayout } from 'corporate-components/cor-live-preview-layout/cor-live-preview-layout';
import { useLocation } from 'react-router-dom';
import { CorporatePageType } from 'corporate-constants/page-types.constants';
import { Corporate } from 'corporate-components/cor-pages/corporate/corporate';
import { useLivePreviewContext } from 'hooks/use-live-preview';
import { ContentstackContext } from 'context/contentstack';
import { Routes } from 'constants/routes.enum';
import { CorporateLayout } from 'layouts/corporate-layout';
import { GlobalModal } from 'components/modals/global-modal';
import { UseShopLivePreview } from 'layouts/shop-live-preview';
import { LIVE_PREVIEW_SHOP_URL } from 'constants/live-preview-shop';
import { ShopLivePreviewUrls } from 'constants/live-preview-shop';
import { useLocalStorage } from 'hooks/use-local-storage';
import {
  ContenstackLivePreviewPageLocale,
  CorContentStackLanguages,
  CorContentStackLanguagesMap,
  CorContentStackLanguagesURL,
} from 'constants/cor-locale.enum';

const PATH_CONTENT_TYPE_ID_INDEX: number = 2;
const URL_ENTRY_INDEX = 1;

const LIVE_PREVIEW_MAP = {
  hero: {
    contentType: 'hero',
    component: CorHero,
    data: (entry: any) => {
      return {
        hero: {
          hero: [entry],
        },
      };
    },
  },
  '2-column-content': {
    contentType: '2_column_content',
    component: CorTwoColumn,
    data: (entry: any) => {
      return {
        two_column_content: {
          container_background_color: entry.container_background_color,
          two_column_content_reference: [entry],
        },
      };
    },
  },
  'filtered-list': {
    contentType: 'filtered_list',
    component: CorFilteredList,
    data: (entry: any) => {
      return {
        content_list: {
          container_background_color: entry.container_background_color,
          content_or_filtered_list: [entry],
        },
      };
    },
  },
  'news-listing': {
    contentType: 'news_listing',
    component: CorNewsListing,
    data: (entry: any) => {
      return {
        content_list: {
          container_background_color: entry.container_background_color,
          content_or_filtered_list: [entry],
        },
      };
    },
  },
  'card-list': {
    contentType: 'card_list',
    component: CorCardList,
    data: (entry: any) => {
      return {
        card_list: {
          container_background_color: entry.container_background_color,
          card_list: [entry],
        },
      };
    },
  },
  '3-column-content': {
    contentType: '3_column_content',
    component: CorThreeColumn,
    data: (entry: any) => {
      return {
        three_column_content: {
          container_background_color: entry.container_background_color,
          three_column_content: [entry],
        },
      };
    },
  },
  form: {
    contentType: 'contentstack_form',
    component: CorForm,
    data: (entry: any) => {
      return {
        form: {
          container_background_color: entry.container_background_color,
          form: [entry],
        },
      };
    },
  },
  accordion: {
    contentType: 'accordion',
    component: CorAccordionComponent,
    data: (entry: any) => {
      return {
        accordion: {
          container_background_color: entry.container_background_color,
          accordion: [entry],
        },
      };
    },
  },
  'distribution-listing': {
    contentType: 'distribution_listing',
    component: CorDistributionMap,
    reference: ['distribution_centers_1', 'distribution_centers_2'],
    data: (entry: any) => {
      return {
        distribution_map: {
          container_background_color: entry.container_background_color,
          distribution_map: [entry],
        },
      };
    },
  },
};

LIVE_PREVIEW_MAP[CorporatePageType.Standard] = {
  contentType: CorporatePageType.Standard,
  reference: [
    'breadcrumbs',
    'modular_blocks.hero.hero',
    'modular_blocks.two_column_content.two_column_content_reference',

    'notifications.messages',
    'success.messages',
    'error.messages',

    'common.environment_message',
    'common.impersonation',
    'common.site_wide_banner',

    'common',
    'modular_blocks.three_column_content.three_column_content',
    'modular_blocks.card_list.card_list',
    'modular_blocks.accordion.accordion',
    'modular_blocks.form.form',
    'modular_blocks.form.form.error_messages.messages',
    'modular_blocks.leadership.leadership',
    'modular_blocks.featured_content.featured_content.references.modular_blocks.hero.hero',
    'modular_blocks.content_list.content_or_filtered_list',
    'modular_blocks.distribution_map.distribution_map.distribution_centers_1',
    'modular_blocks.distribution_map.distribution_map.distribution_centers_2',
  ],
  data: (entry: any) => {
    return {
      ...entry,
      contentTypeName: CorporatePageType.Standard,
    };
  },
};

LIVE_PREVIEW_MAP[CorporatePageType.Insights] = {
  contentType: CorporatePageType.Insights,
  reference: [
    'breadcrumbs',
    'modular_blocks.hero.hero',
    'modular_blocks.two_column_content.two_column_content_reference',

    'common.environment_message',
    'common.impersonation',
    'common.site_wide_banner',

    'related_insights_section.related_insights',
    'related_insights_section.see_all_button',
  ],
  data: (entry: any) => {
    return {
      ...entry,
      contentTypeName: CorporatePageType.Insights,
    };
  },
};

LIVE_PREVIEW_MAP[CorporatePageType.Recipes] = {
  contentType: CorporatePageType.Recipes,
  reference: [
    'breadcrumbs',

    'notifications.messages',
    'success.messages',
    'error.messages',

    'common.environment_message',
    'common.impersonation',
    'common.site_wide_banner',
    'common.shared_recipe_detail_page',

    'related_recipes_section.related_recipe',
    'related_recipes_section.see_all_button',
    'additional_notes_section.chef_bio',
    'modals.success_error_modal',
    'modals.reorder_success_modal',
  ],
  data: (entry: any) => {
    return {
      ...entry,
      contentTypeName: CorporatePageType.Recipes,
    };
  },
};

LIVE_PREVIEW_MAP[CorporatePageType.News] = {
  contentType: CorporatePageType.News,
  reference: [
    'breadcrumbs',
    'modular_blocks.hero.hero',

    'common.environment_message',
    'common.impersonation',
    'common.site_wide_banner',

    'modular_blocks.three_column_content.three_column_content',
    'related_news_section.related_news',
    'related_news_section.see_all_button',
  ],
  data: (entry: any) => {
    return {
      ...entry,
      contentTypeName: CorporatePageType.News,
    };
  },
};

const getLivePreviewContentType = (url: string, locale) => {
  const isShopLivePreviewUrlPresent = Object.values(ShopLivePreviewUrls).includes(
    url.replace('/', '') as ShopLivePreviewUrls
  );

  if (url.startsWith(LIVE_PREVIEW_SHOP_URL) || isShopLivePreviewUrlPresent) {
    const urlContentType: string = isShopLivePreviewUrlPresent
      ? ShopLivePreviewUrls.Shop_Home
      : url.split('/')[PATH_CONTENT_TYPE_ID_INDEX];

    return UseShopLivePreview(urlContentType);
  }

  const defaultLocale = ContenstackLivePreviewPageLocale.get(CorContentStackLanguages.ENGLISH_UNITED_STATES);
  const livePreviewPage = ContenstackLivePreviewPageLocale.get(locale);
  let urlContentType;

  if (livePreviewPage) {
    if (url.includes('/entries')) {
      urlContentType = url.split('/')[PATH_CONTENT_TYPE_ID_INDEX];
    } else if (url.includes(livePreviewPage?.NEWS) || (defaultLocale && url.includes(defaultLocale?.NEWS))) {
      urlContentType = CorporatePageType.News;
    } else if (url.includes(livePreviewPage?.RECIPES) || (defaultLocale && url.includes(defaultLocale?.RECIPES))) {
      urlContentType = CorporatePageType.Recipes;
    } else if (url.includes(livePreviewPage?.INSIGHTS) || (defaultLocale && url.includes(defaultLocale?.INSIGHTS))) {
      urlContentType = CorporatePageType.Insights;
    } else {
      urlContentType = CorporatePageType.Standard;
    }
  }

  return LIVE_PREVIEW_MAP[urlContentType];
};

interface ILivePreviewLayout extends PropsWithChildren {}

export const LivePreviewLayout: FC<ILivePreviewLayout> = ({ children }) => {
  const { enableLivePreview } = useLivePreviewContext();

  const [livePreviewData, setLivePreviewData] = useState<any>({});
  const location = useLocation();
  const { pathname } = location;
  const comesFromLivePreviewPage = pathname.startsWith(Routes.LivePreviewPage);
  const urlEntry = comesFromLivePreviewPage ? pathname.split(Routes.LivePreviewPage)[URL_ENTRY_INDEX] : pathname;
  const [locale, setValue] = useLocalStorage('locale', null);
  const latestFetchId = useRef(0);

  const livePreviewContentType = getLivePreviewContentType(urlEntry, locale);

  useEffect(() => {
    const splitUrl = urlEntry.split('/');

    if (splitUrl[1].length <= 2) {
      const userLocale = (splitUrl[1] ?? '').replace(/^,/, '/');
      const formattedUrl = `/${userLocale}${splitUrl[2] === 'fr' ? '/fr' : ''}` as CorContentStackLanguagesURL;

      const newLocale = CorContentStackLanguagesMap.get(formattedUrl);

      setValue(newLocale);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  Stack.startLivePreview(enableLivePreview);

  const fetchData = useCallback(async () => {
    // For data fetching we have race condition.
    // To handle it we should ignore
    // previous request data if new request is triggered.
    // The fetchId property is used for this purpose.
    const fetchId = ++latestFetchId.current;

    const response = (await Stack.getEntry({
      contentTypeUid: livePreviewContentType.contentType,
      url: urlEntry,
      referenceFieldPath: livePreviewContentType.reference,
      forceWaitForLivePreview: comesFromLivePreviewPage,
    })) as any;

    if (fetchId !== latestFetchId.current) {
      return;
    }

    const result = response[0][0];

    setValue(result?.locale);

    setLivePreviewData(livePreviewContentType.data(result));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livePreviewContentType, urlEntry, comesFromLivePreviewPage]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  const isLoadingLivePreview = comesFromLivePreviewPage && Object.keys(livePreviewData).length === 0;
  const isComponent = livePreviewContentType.component !== undefined;
  const Component = livePreviewContentType.component || Corporate;
  const isShopComponent = Boolean(isComponent && (urlEntry === '/home' || urlEntry.startsWith('/shop')));

  return isLoadingLivePreview ? (
    <div>Loading Live Preview...</div>
  ) : isComponent && !isShopComponent ? (
    <CorLivePreviewLayout>
      <ContentstackContext.Provider value={livePreviewData}>
        <Component />
        <GlobalModal />
      </ContentstackContext.Provider>
    </CorLivePreviewLayout>
  ) : (
    <CorporateLayout>
      <CorLivePreviewLayout>
        <ContentstackContext.Provider value={livePreviewData}>
          <Component />
          <GlobalModal />
        </ContentstackContext.Provider>
      </CorLivePreviewLayout>
    </CorporateLayout>
  );
};
