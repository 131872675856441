import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getBuyItAgainSkuSubtotal, getBuyItAgainSkuSubtotalConstants } from 'store/home/actions';

export interface IGetBuyItAgainSkuSubtotalPayload {
  sku: string;
  amount?: number;
}

export function* getBuyItAgainSkuSubtotalHandler({
  payload: { sku, amount },
}: IAction<IGetBuyItAgainSkuSubtotalPayload>) {
  const url = `${Endpoints.SKU_SUBTOTAL_PRICE}/${sku}?amount=${amount || ''}`;
  yield put(doRequest(getBuyItAgainSkuSubtotal, url, 'get'));
}

export default function* getBuyItAgainSkuSubtotalSaga() {
  yield takeLatest(getBuyItAgainSkuSubtotalConstants[Actions.REQUEST], getBuyItAgainSkuSubtotalHandler);
}
