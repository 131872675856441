import { FC, lazy, ReactElement, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableLayoutComponent } from 'layouts';
import { Location } from 'history';
import { cloneDeep } from 'lodash-es';
import config from 'config/app';
import { Endpoints } from 'constants/endpoints.enum';
import { Routes } from 'constants/routes.enum';
import {
  selectHasTemporaryPassword,
  selectIsAuthorised,
  selectIsHouseAccountUser,
  selectIsImpersonateModeOn,
  selectIsInternalUser,
  selectIsInvoiceOnlyCustomer,
  selectUser,
  selectUserRole,
} from 'store/auth/selectors';
import { useLocalStorage } from 'hooks/use-local-storage';
import { selectImpersonationStoppedOnce } from 'store/dashboard/selectors';
import { getUserInfo, impersonationStop } from 'store/auth/actions';
import { addProductToCart } from 'store/shopping-cart/actions';
import { IAddProductToCartPayload } from 'store/shopping-cart/sagas/add-product-to-cart';
import { ContentstackProvider } from 'context/contentstack';
import { GlobalModalState } from 'context/global-modal-context';
import { IAppRoute, IContentstackParams } from 'routes/routes';
import { GlobalModal } from 'components/modals/global-modal';
import { PrivateRoute } from 'components/router/private-route';
import { DocumentHead } from 'components/document-head';
import { LoadingIndicator } from 'components/loading-indicator';
import { ErrorBoundary } from 'components/error-boundary';
import { populateCachedAnalyticsParams, sendPageView } from 'utils/analytics';
import { checkPermissions } from 'utils/check-permissions';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { contentstackConfig } from 'constants/contentstack';
import { Layouts } from 'constants/layouts.enum';
import { StickySectionsHeightsProvider } from 'context/sticky-sections-heights-context';
import { LivePreviewRoute } from 'components/router/live-preview-route';
import {
  getContentStackURL,
  getLocaleCodeByURL,
  getLocaleURLByCode,
  isInternationalSite,
  isLocaleURL,
} from 'utils/get-locale-params';
import { ALLOWED_PAGES, useLocaleDetection } from 'hooks/use-locale-detection';
import { CorContentStackLanguages, CorContentStackLanguagesURL, FONT_PAGES } from 'constants/cor-locale.enum';
import { showGeolocationRedirect } from 'store/geolocation-modal/actions';
import { isPreference_EU } from 'utils/get-geolocation-preference';
import { updateGTMScript } from 'utils/gtm-utils';
import { allowedShopLocales } from 'constants/shop-locales';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { checkIfShopUrlValid } from 'utils/check-if-shop-url-valid';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';
import { checkIfAccountUrlValid } from 'utils/check-if-account-url-valid';
import { useGetUserRole } from 'hooks/use-get-user-role';
import { useGetTranslatedUrl } from 'hooks/use-get-translated-url';

const Error404Page = lazy(() => import('pages/error-404'));
const FONT_CLASSNAME = 'international-font';

interface IRouteWrapper extends Partial<ReactElement>, IAppRoute {
  location?: Location;
}

export interface ILocationParams {
  lang?: string;
  country?: string;
}

const StrategyComponent = ({ Component, children, ...props }: React.PropsWithChildren<any>) => {
  const content: any = useContentstackContent();
  const { lang, country }: ILocationParams = useParams();
  let ComponentWrapped;

  if (
    props.location.pathname.includes(Routes.ShopHomePage) ||
    props.location.pathname.includes(Routes.CanadaFrenchShop)
  ) {
    const isValidUrl = checkIfShopUrlValid(lang, country);

    ComponentWrapped = !!content?.c_is_not_found_page || !isValidUrl ? Error404Page : Component;
  } else {
    ComponentWrapped = !!content?.c_is_not_found_page ? Error404Page : Component;
  }

  return <ComponentWrapped {...props}>{children}</ComponentWrapped>;
};

const LayoutStrategyComponent = ({ Layout, LayoutNotFound, children }: React.PropsWithChildren<any>) => {
  const content: any = useContentstackContent();

  const LayoutWrapped = !!content?.c_is_not_found_page ? LayoutNotFound : Layout;

  return <LayoutWrapped>{children}</LayoutWrapped>;
};

export const RouteWrapper: FC<IRouteWrapper> = ({
  path,
  exact,
  isPrivate,
  isRestricted,
  isInternal,
  layout,
  unauthorizedLayout,
  component: Component,
  routes,
  contentstackParams,
  unauthorizedContentstackParams,
  requiredPermissions,
  hasDynamicContentstackPageUrl = false,
  additionalContentTypeToFetch,
}) => {
  const isAuthorisedUser = useSelector(selectIsAuthorised);
  const { loaded } = useSelector(selectUser);
  const isInternalUser = useSelector(selectIsInternalUser);
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const hasTemporaryPassword = useSelector(selectHasTemporaryPassword);
  const userRoles = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname: locationPathname } = useLocation();
  const impersonationStoppedOnce = useSelector(selectImpersonationStoppedOnce);
  const isLivePreview = locationPathname.includes(Routes.LivePreviewPage);
  const [locale, setValue] = useLocalStorage('locale', null);
  const [preference] = useLocalStorage('preference', null);
  const { locationData } = useLocaleDetection(locationPathname, locale, isPreference_EU(preference));
  const { isSuperAdminOrAdmin } = useGetUserRole();
  const isHouseAccount = useSelector(selectIsHouseAccountUser);
  const { getTranslatedUrl } = useGetTranslatedUrl();
  const paymentsUrl = getTranslatedUrl({
    usEnglishUrl: Routes.AccountPayments,
    caFrenchUrl: Routes.CanadaFrenchPayments,
  });

  const updatePasswordUrl = getTranslatedUrl({
    usEnglishUrl: Routes.UpdatePasswordPage,
    caFrenchUrl: Routes.CanadaFrenchUpdatePasswordPage,
  });

  const isPaymentRoute =
    locationPathname.includes(Routes.AccountPayments) ||
    locationPathname.includes(Routes.PaymentSummary) ||
    locationPathname.includes(Routes.PaymentDetails) ||
    locationPathname.includes(Routes.CanadaFrenchPayments) ||
    locationPathname.includes(Routes.CanadaFrenchPaymentSummary) ||
    locationPathname.includes(Routes.CanadaFrenchPaymentDetails);

  const invoiceOnlyAllowedRoutes = [
    Routes.PaymentSummary,
    Routes.ProfilePage,
    Routes.AccountPayments,
    Routes.UpdatePasswordPage,
    Routes.PaymentDetails,
    Routes.CanadaFrenchShopAccount,
    Routes.CanadaFrenchPayments,
    Routes.CanadaFrenchPaymentDetails,
    Routes.CanadaFrenchPaymentSummary,
    Routes.CanadaFrenchUpdatePasswordPage,
  ];

  const isRouteAllowedForInvoiceOnly = invoiceOnlyAllowedRoutes.some((route) => locationPathname.includes(route));
  const isAccountPathnameValid = checkIfAccountUrlValid(locationPathname);

  const setRedirect = (localeCode: string, localeUrl: string) => {
    setValue(localeCode);
    history.push(localeUrl);
  };

  useEffect(() => {
    if (locationData?.regionSite && ALLOWED_PAGES.includes(locationPathname as any)) {
      const localeCode = getLocaleCodeByURL(locationData?.regionSite);

      if (localeCode !== CorContentStackLanguages.ENGLISH_UNITED_STATES) {
        setRedirect(localeCode, locationData?.regionSite);
        if (locationData?.countrySite) {
          dispatch(
            showGeolocationRedirect({
              isOpen: true,
              countrySite: locationData?.countrySite,
              regionSite: locationData?.regionSite,
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  useEffect(() => {
    if (locationPathname.includes(Routes.DashboardPage) && !impersonationStoppedOnce) {
      dispatch(impersonationStop.request());
    } else {
      dispatch(getUserInfo.request());
    }

    if (
      (locationPathname.includes(Routes.ShopHomePage) || locationPathname.includes(Routes.CanadaFrenchShop)) &&
      isInternationalSite(locale) &&
      !allowedShopLocales.includes(locale)
    ) {
      setValue(CorContentStackLanguages.ENGLISH_UNITED_STATES);
    }
    const preference_EU = isPreference_EU(preference);

    if (preference_EU && locationPathname === CorContentStackLanguagesURL.ENGLISH_EUROPE) {
      const localeUrl = getLocaleURLByCode(preference_EU);
      setRedirect(preference_EU, localeUrl);
    }

    const localeByURL = isLocaleURL(locationPathname);
    const isUsaPages = !!localeByURL && localeByURL === CorContentStackLanguages.ENGLISH_UNITED_STATES;
    const isShopPages =
      locationPathname.includes(Routes.ShopHomePage) || locationPathname.includes(Routes.CanadaFrenchShop);

    if (localeByURL !== locale) {
      locationPathname === CorContentStackLanguagesURL.ENGLISH_UNITED_STATES
        ? history.push(getLocaleURLByCode(locale))
        : setValue(localeByURL);
    }

    //Implement new Font
    if (localeByURL && FONT_PAGES.includes(localeByURL as CorContentStackLanguages)) {
      document.body.classList.add(FONT_CLASSNAME);
    }
    updateGTMScript(isUsaPages, isShopPages);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locationPathname]);

  // This useEffect is needed because of ProtonAI requirement
  useEffect(() => {
    if (
      !(window as any).protonAddToCart &&
      (path === Routes.ShopHomePage ||
        [
          Routes.AccountOrderHistoryPage,
          Routes.ProductListPage,
          Routes.ProductSearchPage,
          Routes.ProductDetailsPage,
          Routes.ShoppingCartPage,
          Routes.OrderDetails,
        ].some((item) => locationPathname.includes(item)))
    ) {
      (window as any).protonAddToCart = (productId, qty, sendGAFlag = false) => {
        dispatch(
          addProductToCart.request<IAddProductToCartPayload>({
            quantity: qty,
            sku: productId,
            sendGA: sendGAFlag,
          })
        );
      };
    }

    return () => {
      (window as any).protonAddToCart = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPathname]);

  if (!loaded) {
    return <LoadingIndicator />;
  }

  // a user with a temporary password must change it before proceeding
  if (
    hasTemporaryPassword &&
    !isImpersonateModeOn &&
    !(
      path.includes(Routes.UpdatePasswordPage) ||
      path.includes(Routes.CanadaFrenchUpdatePasswordPage) ||
      path.includes(Routes.LoginPage)
    )
  ) {
    return <Redirect to={updatePasswordUrl} />;
  }

  // deny access to the update password page unless the user has a temporary password
  if (
    (path.includes(Routes.UpdatePasswordPage) || path.includes(Routes.CanadaFrenchUpdatePasswordPage)) &&
    !hasTemporaryPassword
  ) {
    return <Redirect to={isInvoiceOnlyUser || isShopLocaleInternational() ? paymentsUrl : Routes.ShopHomePage} />;
  }

  if (isAuthorisedUser) populateCachedAnalyticsParams();

  // certain pages (e.g. login, select location, register) can't be accessed "at will" even by logged-in users
  if (isRestricted && isAuthorisedUser && (!isInternalUser || isImpersonateModeOn)) {
    return <Redirect to={getLocalizedShopUrl(Routes.ShopHomePage)} />;
  }

  const internalRedirectTarget = sessionStorage.getItem('targetLink');

  if (isInternalUser && internalRedirectTarget) {
    sessionStorage.removeItem('targetLink');

    return <Redirect to={internalRedirectTarget} />;
  }

  if (requiredPermissions && !checkPermissions(userRoles, requiredPermissions)) {
    if (isAuthorisedUser) {
      if (isInternalUser) {
        return <Redirect to={Routes.DashboardPage} />;
      } else {
        return <Redirect to={Routes.ShopHomePage} />;
      }
    }
  }

  if (isInternal && !isInternalUser) {
    if (isAuthorisedUser) {
      return <Redirect to={Routes.ShopHomePage} />;
    } else {
      isPrivate && sessionStorage.setItem('targetLink', window.location.pathname + window.location.search);
      window.location.assign(`${config.apiUrl}${Endpoints.SSO_URL}`);
      return null;
    }
  }

  if (
    (locationPathname.includes(Routes.ShopHomePage) || locationPathname.includes(Routes.CanadaFrenchShop)) &&
    !isRouteAllowedForInvoiceOnly &&
    isAccountPathnameValid &&
    (isInvoiceOnlyUser || isShopLocaleInternational()) &&
    isAuthorisedUser
  ) {
    return locale === CorContentStackLanguages.FRENCH_CANADA ? (
      <Redirect to={getLocalizedShopUrl(Routes.CanadaFrenchPayments)} />
    ) : (
      <Redirect to={getLocalizedShopUrl(Routes.AccountPayments)} />
    );
  }

  if (
    isAuthorisedUser &&
    isPaymentRoute &&
    !isSuperAdminOrAdmin &&
    !(isInvoiceOnlyUser || isShopLocaleInternational())
  ) {
    return <Redirect to={getLocalizedShopUrl(Routes.ShopHomePage)} />;
  }

  if (!isInternal && isAuthorisedUser && isInternalUser && !isImpersonateModeOn) {
    return <Redirect to={Routes.DashboardPage} />;
  }

  if (path === Routes.GuestFrequentlyAskedQuestionsPage && isAuthorisedUser) {
    return <Redirect to={Routes.FrequentlyAskedQuestionsPage} />;
  }

  if (isAuthorisedUser && isHouseAccount && locationPathname.includes(Routes.AccountQuotesPage)) {
    return <Redirect to={getLocalizedShopUrl(Routes.ShopHomePage)} />;
  }

  const RouteComponent = isPrivate ? PrivateRoute : Route;

  const render = (props: RouteComponentProps<any>) => {
    const isPublic = !isPrivate && !isRestricted;
    let baseParams = cloneDeep(isPublic && !isAuthorisedUser ? unauthorizedContentstackParams : contentstackParams);
    const notFoundBaseParams =
      isPublic && !isAuthorisedUser
        ? { ...contentstackConfig.unauthorizedError404, ...(isInternationalSite(locale) && { locale: locale }) }
        : { ...contentstackConfig.error404, ...(isInternationalSite(locale) && { locale: locale }) };

    const { url, params } = props.match;

    if (hasDynamicContentstackPageUrl && baseParams && !baseParams.url) {
      baseParams.url = isInternationalSite(locale) ? getContentStackURL(url, params) : url;
    }

    if (
      hasDynamicContentstackPageUrl &&
      !(locationPathname.includes(Routes.ShopHomePage) || locationPathname.includes(Routes.CanadaFrenchShop))
    ) {
      // n.b. this assumes a maximum of one dynamic segment per contentstack page-backed url
      baseParams = {
        ...baseParams,
        url: isInternationalSite(locale) ? getContentStackURL(url, params) : url,
        ...(isInternationalSite(locale) && { locale: locale }),
      } as IContentstackParams;
    }

    if (
      hasDynamicContentstackPageUrl &&
      (locationPathname.includes(Routes.ShopHomePage) || locationPathname.includes(Routes.CanadaFrenchShop)) &&
      allowedShopLocales.includes(locale)
    ) {
      baseParams = {
        ...baseParams,
        ...(isInternationalSite(locale) && { locale: locale }),
      } as IContentstackParams;
    }

    const contentstack = baseParams;
    const contentstackNotFound = notFoundBaseParams;
    const Layout = getAvailableLayoutComponent(layout, unauthorizedLayout, isPublic, isAuthorisedUser);
    const LayoutNotFound = getAvailableLayoutComponent(Layouts.Default, Layouts.Auth, isPublic, isAuthorisedUser);

    sendPageView(locationPathname);

    if (isLivePreview) {
      return (
        <GlobalModalState>
          <ContentstackProvider contentstackParams={contentstack} contentstackNotFoundParams={contentstackNotFound}>
            <LivePreviewRoute>
              <StrategyComponent Component={Component} routes={routes} {...props} />
            </LivePreviewRoute>
          </ContentstackProvider>
        </GlobalModalState>
      );
    }

    return (
      <GlobalModalState>
        <ContentstackProvider
          contentstackParams={contentstack}
          contentstackNotFoundParams={contentstackNotFound}
          additionalContentTypeToFetch={additionalContentTypeToFetch}
        >
          <StickySectionsHeightsProvider>
            <LayoutStrategyComponent Layout={Layout} LayoutNotFound={LayoutNotFound}>
              <DocumentHead />
              <StrategyComponent Component={Component} routes={routes} {...props} />
            </LayoutStrategyComponent>
            <GlobalModal />
          </StickySectionsHeightsProvider>
        </ContentstackProvider>
      </GlobalModalState>
    );
  };
  return (
    <ErrorBoundary>
      <RouteComponent path={path} exact={exact} render={render} />
    </ErrorBoundary>
  );
};
