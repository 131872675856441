import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { addSaveForLaterItems, addSaveForLaterItemsActionConstants } from 'store/save-for-later/actions';
import { IAction } from 'types/actions';

export interface IAddSaveForLaterItemsPayload {
  sku: string;
  quantity: number;
  sendGA?: boolean;
  onSuccessCallBack?: () => void;
  onFailCallBack?: (errorMessageId: string) => void;
}

export interface IAddSaveForLaterItemsRequestBody {
  sku: string;
  quantity: number;
}

export function* addSaveForLaterOnSuccessSagaHandler({ payload }: IAction<IAddSaveForLaterItemsPayload>) {
  yield take(addSaveForLaterItemsActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* addSaveForLaterOnFailSagaHandler({ payload }: IAction<IAddSaveForLaterItemsPayload>) {
  const { error } = yield take(addSaveForLaterItemsActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack) {
    payload.onFailCallBack(error.errorMessageId);
  }
}

export function* addSaveForLaterHandler({ payload }: IAction<IAddSaveForLaterItemsPayload>) {
  yield put(
    doRequest<IAddSaveForLaterItemsRequestBody>(addSaveForLaterItems, Endpoints.SAVE_FOR_LATER, 'post', payload)
  );
}

export default function* addSaveForLaterSaga() {
  yield takeLatest(addSaveForLaterItemsActionConstants[Actions.REQUEST], addSaveForLaterOnSuccessSagaHandler);
  yield takeLatest(addSaveForLaterItemsActionConstants[Actions.REQUEST], addSaveForLaterOnFailSagaHandler);
  yield takeLatest(addSaveForLaterItemsActionConstants[Actions.REQUEST], addSaveForLaterHandler);
}
