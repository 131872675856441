enum similarItems {
  GET_SIMILAR_ITEMS = 'GET_SIMILAR_ITEMS',
  GET_REQUEST_QUOTE_SIMILAR_ITEMS = 'GET_REQUEST_QUOTE_SIMILAR_ITEMS',
  CLEAR_SIMILAR_ITEMS = 'CLEAR_SIMILAR_ITEMS',
  CLEAR_REQUEST_QUOTE_SIMILAR_ITEMS = 'CLEAR_REQUEST_QUOTE_SIMILAR_ITEMS',
  SET_LAST_REQUESTED_SKU = 'SET_LAST_REQUESTED_SKU',
  GET_SIMILAR_ITEMS_SKU_SUBTOTAL = 'GET_SIMILAR_ITEMS_SKU_SUBTOTAL',
}

export default similarItems;
