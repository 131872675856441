import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { IPaymentMethodsState } from './reducers';

export const selectPaymentMethodsEntity = (state: IState): IPaymentMethodsState => state.app.paymentMethods;

export const selectCreditCardsData = createSelector(
  selectPaymentMethodsEntity,
  (paymentMethodsEntity) => paymentMethodsEntity.creditCards
);

export const selectBankAccountsData = createSelector(
  selectPaymentMethodsEntity,
  (paymentMethodsEntity) => paymentMethodsEntity.bankAccounts
);

export const selectAddBankAccountForm = createSelector(
  selectPaymentMethodsEntity,
  (paymentMethodsEntity) => paymentMethodsEntity.addBankAccountForm
);

export const selectAddBankAccountFormEftPad = createSelector(
  selectPaymentMethodsEntity,
  (paymentMethodsEntity) => paymentMethodsEntity.addBankAccountFormEftPad
);

export const selectAddBankAccountFormServerError = createSelector(
  selectAddBankAccountForm,
  (paymentMethodsEntity) => paymentMethodsEntity.serverError
);

export const selectBankAccountFormIsSubmitting = createSelector(
  selectAddBankAccountForm,
  (selectAddBankAccountForm) => selectAddBankAccountForm.isSubmitting
);

export const selectAddBankAccountFormEftPadServerError = createSelector(
  selectAddBankAccountFormEftPad,
  (addBankAccountFormEftPad) => addBankAccountFormEftPad.serverError
);

export const selectBankAccountFormEftPadIsSubmitting = createSelector(
  selectAddBankAccountFormEftPad,
  (selectAddBankAccountFormEftPad) => selectAddBankAccountFormEftPad.isSubmitting
);

export const selectAddCreditCardForm = createSelector(
  selectPaymentMethodsEntity,
  (paymentMethodsEntity) => paymentMethodsEntity.addCreditCardForm
);

export const selectAddCreditCardFormServerError = createSelector(
  selectAddCreditCardForm,
  (addCreditCardForm) => addCreditCardForm.serverError
);

export const selectAddCreditCardFormIsSubmitting = createSelector(
  selectAddCreditCardForm,
  (addCreditCardForm) => addCreditCardForm.isSubmitting
);

export const selectAddCreditCardFormIsDirty = createSelector(
  selectAddCreditCardForm,
  (addCreditCardForm) => addCreditCardForm.isDirty
);

export const selectPaymentMethodLoading = createSelector(selectPaymentMethodsEntity, ({ loading }) => loading);
export const selectPaymentMethodsLoading = createSelector(
  selectPaymentMethodsEntity,
  ({ paymentMethodsLoading }) => paymentMethodsLoading
);

export const selectAutopay = createSelector(selectPaymentMethodsEntity, ({ autopay }) => autopay);

export const selectAutopayError = createSelector(selectAutopay, ({ error }) => error);

export const selectIsEnabledInSap = createSelector(selectAutopay, ({ isEnabledInSap }) => isEnabledInSap);
