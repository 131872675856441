import { ICSCorImage, ICSCorVideo } from 'corporate-interfaces';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { corContentstackVideoLinkResolver } from 'corporate-utils/cor-contentstack-video-link-resolver';
import { FC } from 'react';
import { CorHeroCarousel } from 'corporate-components/cor-hero/components/cor-hero-carousel';
import {
  CorHeroContainer,
  HeroOverlay,
  HeroOverlayColor,
} from 'corporate-components/cor-hero/components/cor-hero-container';
import {
  CorHeroContent,
  HeroContentVariants,
  HeroContentAlignment,
} from 'corporate-components/cor-hero/components/cor-hero-content';
import { ContentstackContext } from 'context/contentstack';
import { useContent } from 'hooks/use-content';
import { useZoomLevel } from 'hooks/use-zoom-level';

import './cor-hero.scss';

enum BannerSizes {
  XLarge = 'xlarge',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Inset = 'inset',
}

enum ContentAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

enum TextColor {
  Light = 'light',
  Dark = 'dark',
}

export interface ICSHeroPresentationGroup {
  text_area_overlay: boolean;
  hero_size: 'Large' | 'Medium' | 'Small' | 'Inset';
  content_horizontal_alignment: 'Left' | 'Center' | 'Right';
  include_content_card: boolean;
  text_color: 'Light' | 'Dark';
}

export interface ICSHeroButton {
  button: {
    href: string;
    title: string;
  };
  button_style: string;
  open_in_a_new_tab: boolean;
  button_row: boolean;
  brand_folder_asset: any;
}

export interface ICSHeroBackgroundVideo extends ICSCorVideo {}

export interface ICSHeroBackgroundImage extends ICSCorImage {}

interface ICSHeroInstance {
  button: ICSHeroButton[];
  background_image: ICSHeroBackgroundImage;
  background_video: ICSHeroBackgroundVideo;
  presentation_group: ICSHeroPresentationGroup;
  description: string;
  headline: string;
}

interface ICorHeroProps {
  contantStackPath?: string;
}

const RenderContainer: FC<{
  instance: ICSHeroInstance;
  isCarousel?: boolean;
}> = ({ instance, isCarousel }) => {
  const { presentation_group, background_image, background_video } = instance;
  const {
    hero_size,
    include_content_card,
    content_horizontal_alignment,
    text_color,
    text_area_overlay,
  } = presentation_group;
  const imageAsset = corContentstackImageLinkResolver(background_image);
  const videoAsset = corContentstackVideoLinkResolver({ ...background_video, fallbackImage: imageAsset?.url });
  const zoomLevel = useZoomLevel();
  const carouselBannerSize = zoomLevel <= 50 ? BannerSizes['XLarge'] : BannerSizes['Large'];
  const asset = videoAsset?.isEmpty ? imageAsset : videoAsset;
  const size = isCarousel ? carouselBannerSize : BannerSizes[hero_size];
  const alignment: HeroContentAlignment =
    size === 'inset' ? 'center' : ContentAlignment[content_horizontal_alignment] || ContentAlignment['Left'];
  const contentVariant: HeroContentVariants = isCarousel
    ? 'carousel'
    : include_content_card
    ? 'card'
    : text_area_overlay
    ? 'overlay'
    : undefined;

  const isNotCardCarousel = contentVariant !== 'card' && contentVariant !== 'carousel';

  const overlay: HeroOverlay = text_area_overlay && isNotCardCarousel ? alignment : undefined;
  const overlayColor: HeroOverlayColor =
    overlay && TextColor[text_color] === 'light' ? 'dark' : TextColor[text_color] === 'dark' ? 'light' : undefined;
  const contentBackgroundColor = isNotCardCarousel ? overlayColor : undefined;

  return (
    <ContentstackContext.Provider value={instance}>
      <CorHeroContainer
        asset={asset || undefined}
        size={size}
        overlay={overlay}
        overlayColor={overlayColor}
        isInset={size === 'inset'}
      >
        <CorHeroContent
          alignment={alignment}
          variant={contentVariant}
          textColor={TextColor[text_color]}
          contentBackgroundColor={contentBackgroundColor}
          isInset={size === 'inset'}
        />
      </CorHeroContainer>
    </ContentstackContext.Provider>
  );
};

export const CorHero: FC<ICorHeroProps> = ({ contantStackPath }) => {
  const { getContentByKey } = useContent();
  const currentContantStackPath = contantStackPath || 'hero.hero[0]';
  const data: any = getContentByKey(currentContantStackPath, '');
  const contentTotal = data?.hero_content?.length || 0;

  if (!data || !contentTotal) {
    return null;
  }

  return (
    <div className={'cor-hero'}>
      {contentTotal > 1 ? (
        <CorHeroCarousel>
          {data?.hero_content.map((instance, index) => (
            <div key={index}>
              <RenderContainer instance={instance} isCarousel={true} />
            </div>
          ))}
        </CorHeroCarousel>
      ) : (
        <RenderContainer instance={data.hero_content[0]} />
      )}
    </div>
  );
};
