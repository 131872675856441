import { createSelector } from 'reselect';
import { DashboardQueryTypes } from 'constants/dashboard-query-types.enum';
import { IState } from '../root-reducer';
import { IDashboardListingState, IDashboardState } from './reducer';

export const getDashboardEntity = (state: IState): IDashboardState => state.app.dashboard;

export const getDashboardListingEntity = createSelector(
  getDashboardEntity,
  ({ dashboardListing }): IDashboardListingState => dashboardListing
);
export const getCsrCustomerForms = createSelector(getDashboardEntity, ({ csrCustomerForms }) => csrCustomerForms);

export const selectLocationsByName = createSelector(
  getDashboardListingEntity,
  ({ locationsByName }) => locationsByName
);
export const selectLocationsByRecentOrders = createSelector(
  getDashboardListingEntity,
  ({ locationsByRecentOrders }) => locationsByRecentOrders
);
export const selectLocationsByDayOfWeek = createSelector(
  getDashboardListingEntity,
  ({ locationsByDayOfWeek }) => locationsByDayOfWeek
);
export const selectMsrPaginationCurrent = createSelector(selectLocationsByName, ({ offset, limit }) => offset / limit);
export const selectMsrPaginationTotal = createSelector(selectLocationsByName, ({ total, limit }) =>
  Math.ceil(total / limit)
);

export const selectMsrList = createSelector(getDashboardListingEntity, ({ msrList }) => msrList);

export const selectLocationsByAccountNumber = createSelector(
  getDashboardListingEntity,
  ({ locationsByAccountNumber }) => locationsByAccountNumber
);
export const selectLocationsByAccountNumberCurrent = createSelector(
  selectLocationsByAccountNumber,
  ({ offset, limit }) => offset / limit
);
export const selectLocationsByAccountNumberPaginationTotal = createSelector(
  selectLocationsByAccountNumber,
  ({ total, limit }) => Math.ceil(total / limit)
);

export const selectLocationsByMsr = createSelector(
  getDashboardListingEntity,
  ({ locationsByMsrName }) => locationsByMsrName
);
export const selectLocationsByMsrCurrent = createSelector(selectLocationsByMsr, ({ offset, limit }) => offset / limit);
export const selectLocationsByMsrPaginationTotal = createSelector(selectLocationsByMsr, ({ total, limit }) =>
  Math.ceil(total / limit)
);

export const selectIsAccountNumberSearch = createSelector(
  getDashboardListingEntity,
  ({ isAccountNumberSearch }) => isAccountNumberSearch
);

export const selectActiveMsmView = createSelector(getDashboardListingEntity, ({ activeMsmView }) => {
  return activeMsmView;
});

export const selectCsrCustomerEmailFormIsSubmiting = createSelector(
  getCsrCustomerForms,
  ({ csrCustomerEmailForm }) => csrCustomerEmailForm.isSubmitting
);

export const selectCsrCustomerEmailFormServerErrorId = createSelector(
  getCsrCustomerForms,
  ({ csrCustomerEmailForm }) => csrCustomerEmailForm.serverErrorId
);

const locationDataToBeReturned = (location, accountId) =>
  (location?.location.superAdmin.id === accountId && location?.location.superAdmin) ||
  location?.location.admins.find((account) => account.id === accountId) ||
  location?.location.users.find((account) => account.id === accountId);

export const selectTsrAccountAtLocation = (accountId: string, locationId: string, isAccountNumberSearch: boolean) =>
  createSelector(getDashboardListingEntity, ({ locationsByAccountNumber, locationsByMsrName }) => {
    const location = (isAccountNumberSearch ? locationsByAccountNumber : locationsByMsrName).elementsByName.find(
      ({ location }) => {
        return location.locationId === locationId;
      }
    );

    return locationDataToBeReturned(location, accountId);
  });

const findLocation = (locationsList, locationId) =>
  locationsList.elementsByName.find(({ location }) => {
    return location.locationId === locationId;
  });

export const selectCsrAccountWithAssignedAccountsAtLocation = (
  accountId: string,
  locationId: string,
  currentSortingOptionCsrWithAssignedAccountsView: number
) =>
  createSelector(
    getDashboardListingEntity,
    ({ csrLocationsWithAssignedAccounts: { locationsByName, locationsByDayOfWeek, locationsByRecentOrders } }) => {
      switch (currentSortingOptionCsrWithAssignedAccountsView) {
        case DashboardQueryTypes.LocationsByName: {
          const location = findLocation(locationsByName, locationId);
          return locationDataToBeReturned(location, accountId);
        }

        case DashboardQueryTypes.LocationsByOrderDate: {
          const locations = locationsByDayOfWeek.elementsByOrderDueDate.find((locations) => {
            return findLocation(locations, locationId);
          });

          return locations?.elementsByName
            .map((location) => locationDataToBeReturned(location, accountId))
            .filter(Boolean);
        }

        case DashboardQueryTypes.RecentOrdersByDate: {
          const locations = locationsByRecentOrders.find((locations) => {
            return findLocation(locations, locationId);
          });
          return locations?.elementsByName
            .map((location) => locationDataToBeReturned(location, accountId))
            .filter(Boolean);
        }
      }
    }
  );

export const selectLocationsForMsm = createSelector(
  getDashboardListingEntity,
  ({ locationsForMsm }) => locationsForMsm
);

export const selectHasUnsavedChanges = createSelector(
  getCsrCustomerForms,
  ({ hasUnsavedChanges }) => hasUnsavedChanges
);

export const selectLocationsByNameWithAssignedAccounts = createSelector(
  getDashboardListingEntity,
  ({ csrLocationsWithAssignedAccounts }) => csrLocationsWithAssignedAccounts.locationsByName
);

export const selectLocationsByDayOfWeekWithAssignedAccounts = createSelector(
  getDashboardListingEntity,
  ({ csrLocationsWithAssignedAccounts }) => csrLocationsWithAssignedAccounts.locationsByDayOfWeek
);

export const selectLocationsByRecentOrdersWithAssignedAccounts = createSelector(
  getDashboardListingEntity,
  ({ csrLocationsWithAssignedAccounts }) => csrLocationsWithAssignedAccounts.locationsByRecentOrders
);

export const selectCsrWithAssignedAccountsPaginationCurrent = createSelector(
  selectLocationsByNameWithAssignedAccounts,
  ({ offset, limit }) => offset / limit
);
export const selectCsrWithAssignedAccountsPaginationTotal = createSelector(
  selectLocationsByNameWithAssignedAccounts,
  ({ total, limit }) => Math.ceil(total / limit)
);

export const selectActiveCsrWithAssignedAccountsView = createSelector(
  getDashboardListingEntity,
  ({ activeCsrWithAssignedAccountsView }) => activeCsrWithAssignedAccountsView
);

export const selectCurrentSortingOptionCsrWithAssignedAccountsView = createSelector(
  getDashboardListingEntity,
  ({ currentSortingOptionCsrWithAssignedAccounts }) => currentSortingOptionCsrWithAssignedAccounts
);

export const selectImpersonationStoppedOnce = createSelector(
  getDashboardEntity,
  ({ impersonationStoppedOnce }) => impersonationStoppedOnce
);

export const selectLocationsForCsr = createSelector(
  getDashboardListingEntity,
  ({ locationsForCsr }) => locationsForCsr
);

export const selectLocationsByNameForCsrAllCustomers = createSelector(
  getDashboardListingEntity,
  ({ locationsForCsr }) => locationsForCsr.locationsByName
);

export const selectLocationsByDayOfWeekForCsrAllCustomers = createSelector(
  getDashboardListingEntity,
  ({ locationsForCsr }) => locationsForCsr.locationsByDayOfWeek
);

export const selectLocationsByRecentOrdersForCsrAllCustomers = createSelector(
  getDashboardListingEntity,
  ({ locationsForCsr }) => locationsForCsr.locationsByRecentOrders
);

export const selectCsrForCsrAllCustomersPaginationCurrent = createSelector(
  selectLocationsByNameForCsrAllCustomers,
  ({ offset, limit }) => offset / limit
);
export const selectForCsrAllCustomersPaginationTotal = createSelector(
  selectLocationsByNameForCsrAllCustomers,
  ({ total, limit }) => Math.ceil(total / limit)
);

export const selectAllCustomersSearchValues = createSelector(
  getDashboardListingEntity,
  ({ allCustomersSearchValues }) => allCustomersSearchValues
);

export const selectCsrAllCustomersViewUserAtLocation = (
  accountId: string,
  locationId: string,
  currentSortingOptionCsrWithAssignedAccountsView: number = 1
) =>
  createSelector(
    getDashboardListingEntity,
    ({ locationsForCsr: { locationsByName, locationsByDayOfWeek, locationsByRecentOrders } }) => {
      switch (currentSortingOptionCsrWithAssignedAccountsView) {
        case DashboardQueryTypes.LocationsByName: {
          const location = findLocation(locationsByName, locationId);
          return locationDataToBeReturned(location, accountId);
        }

        case DashboardQueryTypes.LocationsByOrderDate: {
          const locations = locationsByDayOfWeek.elementsByOrderDueDate.find((locations) => {
            return findLocation(locations, locationId);
          });

          return locations?.elementsByName
            .map((location) => locationDataToBeReturned(location, accountId))
            .filter(Boolean)[0];
        }

        case DashboardQueryTypes.RecentOrdersByDate: {
          const locations = locationsByRecentOrders.find((locations) => {
            return findLocation(locations, locationId);
          });
          return locations?.elementsByName
            .map((location) => locationDataToBeReturned(location, accountId))
            .filter(Boolean)[0];
        }
      }
    }
  );
