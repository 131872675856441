import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { LineItemImage } from 'components/line-item-image';
import { ProductIndicators } from 'components/product-indicators';
import { ProductStatusLabels } from 'components/product-status-labels';
import { QuantityControl } from 'components/quantity-control';
import { selectIsImpersonateModeOn, selectIsTsrUser } from 'store/auth/selectors';
import { addProductToCart } from 'store/shopping-cart/actions';
import { IAddProductToCartPayload } from 'store/shopping-cart/sagas/add-product-to-cart';
import { ContentstackText } from 'components/contentstack';
import { stripLeadingZeros } from 'utils/string-format';
import { formatCurrency } from 'utils/currency-format';
import { IProductItem } from 'types/product';
import {
  useDiscontinuedItemsWithReplacementsModal,
  useHideGlobalModalHandler,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';
import { BuyItAgainItemButtons } from 'features/order-updates/components/buy-it-again/buy-it-again-item/components/buy-it-again-item-item-buttons';
import { ProductNewArrivalBadge } from 'components/product-new-arrival-badge';
import { clearBuyItAgainSkuSubtotal, getBuyItAgainSkuSubtotal, setLastRequestSku } from 'store/home/actions';
import { selectLastRequestedSku } from 'store/home/selectors';

import './buy-it-again-item.scss';

export interface IBuyItAgainItemProps {
  lineItem: IProductItem;
  onRequestQuoteCtaClick?: (skuKey: string, isOpenedWithinQuickShop: boolean) => void;
}

export const BuyItAgainItem: React.FC<IBuyItAgainItemProps> = ({ lineItem, onRequestQuoteCtaClick }) => {
  const {
    color,
    flavor,
    brand,
    newArrival,
    name,
    price,
    number,
    isDiscontinued,
    isPhasingOut,
    imageUrl,
    isSpecial,
    replacementItems,
    maximumQuantity,
    totalPrice,
  } = lineItem;

  const showRequestQuoteSuccessModal = useRequestQuoteSuccessMessageModal();
  const hideGlobalModal = useHideGlobalModalHandler();
  const showRequestQuoteModal = useRequestQuoteModal({
    skuKey: number,
    requestQuoteSuccessCallBack: showRequestQuoteSuccessModal,
    requestQuoteFailCallBack: hideGlobalModal,
  });

  const imageDesktopSize = { width: 48, height: 48 };
  const lineItemImageProps = {
    title: 'name',
    desktopSize: imageDesktopSize,
    src: imageUrl,
    renderIndicator: () => <ProductIndicators size="medium" />,
  };

  const dispatch = useDispatch();

  const quantityInputRef = useRef<HTMLInputElement>(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [, setQuantityState] = useState(1);
  const [buyItAgainTotalPrice, setBuyItAgainTotalPrice] = useState({
    amount: price?.amount,
    currencySymbol: price?.currencySymbol,
  });
  const lastRequestSku = useSelector(selectLastRequestedSku);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isTsrUser = useSelector(selectIsTsrUser);
  const isDiscontinuedWithReplacement = isDiscontinued && !isEmpty(replacementItems);

  const contentstackPath = 'page_content.buy_it_again[0]';

  useEffect(() => {
    lastRequestSku === number &&
      totalPrice &&
      setBuyItAgainTotalPrice({ amount: totalPrice.amount, currencySymbol: totalPrice.currencySymbol });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  useEffect(() => {
    return () => {
      dispatch(clearBuyItAgainSkuSubtotal(number));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const itemHasOneStatusLabel = () => {
    const itemStatuses = [isSpecial, isDiscontinued, isPhasingOut].filter((item) => Boolean(item));
    return itemStatuses.length === 1;
  };

  const addToCart = () => {
    if (quantityInputRef.current) {
      dispatch(
        addProductToCart.request<IAddProductToCartPayload>({
          quantity: Number(quantityInputRef.current.value),
          sku: number,
        })
      );
    }
  };

  const setQuantity = (quantity?: number) => {
    if (quantity && !isSpecial) {
      dispatch(setLastRequestSku(number));
      setQuantityState(quantity);
      dispatch(getBuyItAgainSkuSubtotal.request({ sku: number, amount: quantity }));
    }
  };

  const onAddToCartClick = () => {
    setIsButtonActive(true);
    addToCart();
    setButtonDisabled(true);
    setTimeout(() => {
      setIsButtonActive(false);
      setButtonDisabled(false);
    }, 1000);
  };

  const originalToReplacementSkusMap = useMemo(() => {
    return { [number]: replacementItems[0] };
  }, [number, replacementItems]);

  const showDiscontinuedItemsWithReplacementsModal = useDiscontinuedItemsWithReplacementsModal({
    messageType: 'error',
    messageId: 'MSG021a',
    openPreviousModal: () => showDiscontinuedItemsWithReplacementsModal(),
    originalToReplacementItemsMap: originalToReplacementSkusMap,
  });

  return (
    <div
      className={classNames('buy-it-again-item', {
        'buy-it-again-item--single-status-label': itemHasOneStatusLabel(),
      })}
    >
      <div className="buy-it-again-item__content">
        <LineItemImage {...lineItemImageProps} />
        <div className="buy-it-again-item__line-item-info">
          <div className="buy-it-again-item__info">
            <div className="buy-it-again-item__sku-info">
              <div className="buy-it-again-item__brand">{brand}</div>
              <div className="buy-it-again-item__name">
                {newArrival && <ProductNewArrivalBadge contentKey={`${contentstackPath}.new_arrival_badge`} />}
                <span className="buy-it-again-item__name">{name}</span>
              </div>
            </div>
            {!isSpecial && !isDiscontinued && !isDiscontinuedWithReplacement && (
              <div className="buy-it-again-item__price-block">
                {price && (
                  <div className="buy-it-again-item__price">
                    <p className="buy-it-again-item__price-label">
                      <ContentstackText contentKey={`${contentstackPath}.price_label`} />
                    </p>
                    <p className="buy-it-again-item__price-value">{formatCurrency(price)}</p>
                  </div>
                )}
                <div className="buy-it-again-item__price-quantity">
                  <p className="buy-it-again-item__price-quantity-label">
                    <ContentstackText contentKey={`${contentstackPath}.quantity_label`} />
                  </p>
                  <QuantityControl
                    quantity={1}
                    contentStackPath={contentstackPath}
                    inputRef={quantityInputRef}
                    disabled={isSpecial}
                    onDecreaseQuantityHandler={setQuantity}
                    onIncreaseQuantityHandler={setQuantity}
                    setCurrentQuantity={setQuantity}
                  />
                </div>
                {price && (
                  <div className="buy-it-again-item__total-price buy-it-again-item__price">
                    <p className="buy-it-again-item__total-price-label buy-it-again-item__price-label">
                      <ContentstackText contentKey={`${contentstackPath}.total_label`} />
                    </p>
                    <div className="buy-it-again-item__total-price-value buy-it-again-item__price-value">
                      {formatCurrency(buyItAgainTotalPrice)}
                    </div>
                  </div>
                )}
              </div>
            )}
            {(!isImpersonateModeOn || (isImpersonateModeOn && !isTsrUser)) && (
              <div className="buy-it-again-item__buttons">
                <BuyItAgainItemButtons
                  contentstackPath={contentstackPath}
                  sku={number}
                  isSpecialOrder={Boolean(isSpecial)}
                  isDiscontinuedWithReplacement={Boolean(isDiscontinuedWithReplacement)}
                  openDiscontinuedWithReplacementModal={showDiscontinuedItemsWithReplacementsModal}
                  active={isButtonActive}
                  disabled={buttonDisabled || maximumQuantity === 0}
                  onAddToCartClick={onAddToCartClick}
                  onRequestQuoteCtaClick={() => {
                    if (onRequestQuoteCtaClick) {
                      onRequestQuoteCtaClick(number, true);
                      return;
                    }
                    showRequestQuoteModal();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="buy-it-again-item__sku-info-characteristics">
        {(isSpecial || isDiscontinued || isPhasingOut) && (
          <ProductStatusLabels
            size="small"
            isSpecialOrder={isSpecial}
            isDiscontinued={isDiscontinued}
            isPhasingOut={isPhasingOut && !isSpecial}
          />
        )}
        <div className="buy-it-again-item__sku-info-item buy-it-again-item__item-number">
          <span>
            <ContentstackText contentKey={`${contentstackPath}.item_number_label`} />
          </span>
          {stripLeadingZeros(number)}
        </div>
        {color && (
          <div className="buy-it-again-item__sku-info-item">
            <span>
              <ContentstackText contentKey={`${contentstackPath}.color_label`} />
            </span>
            {color}
          </div>
        )}
        {flavor && (
          <div className="buy-it-again-item__sku-info-item">
            <span>
              <ContentstackText contentKey={`${contentstackPath}.flavor_label`} />
            </span>
            {flavor}
          </div>
        )}
      </div>
    </div>
  );
};
