import { action } from 'typesafe-actions';
import { IUserPersonalInformation } from 'types/user-info';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import { DashboardQueryTypes } from 'constants/dashboard-query-types.enum';
import types from './action-types';

export const getLocationsByNameConstants = createConstants(types.GET_LOCATIONS_BY_NAME);
export const getLocationsByDayOfWeekConstants = createConstants(types.GET_LOCATIONS_BY_DAY_OF_WEEK);
export const getLocationsByRecentOrdersConstants = createConstants(types.GET_LOCATIONS_BY_RECENT_ORDERS);
export const getLocationsByAccountNumberConstants = createConstants(types.GET_LOCATIONS_BY_ACCOUNT_NUMBER);
export const getLocationsForMsrConstants = createConstants(types.GET_LOCATIONS_FOR_MSR);
export const getMsrListByNameConstants = createConstants(types.GET_MSR_LIST_BY_NAME);
export const getMsrListByNameForMsmConstants = createConstants(types.GET_MSR_LIST_BY_NAME_MSM);
export const getLocationsForMsmConstants = createConstants(types.GET_LOCATIONS_FOR_MSM);
export const getLocationsByMsrIdForMsmConstants = createConstants(types.GET_LOCATIONS_BY_MSR_ID_MSM);
export const getMsrLocationsCartStatusAndOrdersForMsmConstants = createConstants(
  types.GET_MSR_LOCATIONS_CART_STATUS_AND_ORDERS_FOR_MSM
);
export const changeCustomerEmailConstants = createConstants(types.CHANGE_CSR_CUSTOMER_EMAIL);
export const changeCustomerPasswordConstants = createConstants(types.CHANGE_CSR_CUSTOMER_PASSWORD);
export const unlockCsrCustomerAccountConstants = createConstants(types.UNLOCK_CSR_CUSTOMER_ACCOUNT);
export const getCsrLocationsWithAssignedAccountsByNameConstants = createConstants(
  types.GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_NAME
);
export const getCsrLocationsWithAssignedAccountsByDayOfWeekConstants = createConstants(
  types.GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_DAY_OF_WEEK
);
export const getCsrLocationsWithAssignedAccountsByRecentOrdersConstants = createConstants(
  types.GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_RECENT_ORDERS
);
export const getLocationsByNameForCsrConstants = createConstants(types.GET_LOCATIONS_BY_NAME_FOR_CSR);
export const getLocationsByDayOfWeekForCsrConstants = createConstants(types.GET_LOCATIONS_BY_DAY_OF_WEEK_FOR_CSR);
export const getLocationsByRecentOrdersForCsrConstants = createConstants(types.GET_LOCATIONS_BY_RECENT_ORDERS_FOR_CSR);

export const getLocationsByName = createRequestActions(getLocationsByNameConstants);
export const getLocationsByDayOfWeek = createRequestActions(getLocationsByDayOfWeekConstants);
export const getLocationsByRecentOrders = createRequestActions(getLocationsByRecentOrdersConstants);
export const getLocationsByAccountNumber = createRequestActions(getLocationsByAccountNumberConstants);
export const getLocationsForMsr = createRequestActions(getLocationsForMsrConstants);
export const getMsrListByName = createRequestActions(getMsrListByNameConstants);
export const getMsrListByNameForMsm = createRequestActions(getMsrListByNameForMsmConstants);
export const getLocationsForMsm = createRequestActions(getLocationsForMsmConstants);
export const getLocationsByMsrIdForMsm = createRequestActions(getLocationsByMsrIdForMsmConstants);
export const getMsrLocationsCartStatusAndOrdersForMsm = createRequestActions(
  getMsrLocationsCartStatusAndOrdersForMsmConstants
);
export const changeCustomerEmail = createRequestActions(changeCustomerEmailConstants);
export const changeCustomerPassword = createRequestActions(changeCustomerPasswordConstants);
export const unlockCsrCustomerAccount = createRequestActions(unlockCsrCustomerAccountConstants);
export const getCsrLocationsWithAssignedAccountsByName = createRequestActions(
  getCsrLocationsWithAssignedAccountsByNameConstants
);
export const getCsrLocationsWithAssignedAccountsByDayOfWeek = createRequestActions(
  getCsrLocationsWithAssignedAccountsByDayOfWeekConstants
);
export const getCsrLocationsWithAssignedAccountsByRecentOrders = createRequestActions(
  getCsrLocationsWithAssignedAccountsByRecentOrdersConstants
);

export const getLocationsByNameForCsr = createRequestActions(getLocationsByNameForCsrConstants);
export const getLocationsByDayOfWeekForCsr = createRequestActions(getLocationsByDayOfWeekForCsrConstants);
export const getLocationsByRecentOrdersForCsr = createRequestActions(getLocationsByRecentOrdersForCsrConstants);

export const changeMsrPaginationCurrent = (current: number) =>
  action(types.DASHBOARD_MSR_PAGINATION_CHANGE, {
    current,
  });

export const changeRecentOrdersPaginationCurrent = (current: number) =>
  action(types.LOCATIONS_BY_RECENT_ORDERS_PAGINATION_CHANGE, {
    current,
  });

export const changeLocationsByMsrPaginationCurrent = (current: number) =>
  action(types.LOCATIONS_BY_MSR_PAGINATION_CHANGE, {
    current,
  });

export const changeLocationsByAccountNumberPaginationCurrent = (current: number) =>
  action(types.LOCATIONS_BY_ACCOUNT_NUMBER_PAGINATION_CHANGE, {
    current,
  });

export const clearCsrLocations = () => action(types.CLEAR_CSR_LOCATIONS, {});

export const setActiveMsmView = (activeMsmView: boolean) => action(types.SET_ACTIVE_MSM_VIEW, { activeMsmView });

export const setActiveCsrWithAssignedAccountsView = (activeCsrWithAssignedAccountsView: string) =>
  action(types.SET_ACTIVE_CSR_WITH_ASSIGNED_ACCOUNTS_VIEW, { activeCsrWithAssignedAccountsView });

export const setCurrentSortingOptionCsrWithAssignedAccountsView = (
  currentSortingOptionCsrWithAssignedAccounts: number
) =>
  action(types.SET_CURRENT_SORTING_OPTION_CSR_WITH_ASSIGNED_ACCOUNTS_VIEW, {
    currentSortingOptionCsrWithAssignedAccounts,
  });

export const clearCsrFormServerErrors = () => action(types.CLEAR_CSR_FORM_SERVER_ERRORS, {});

export const confirmCsrAccountUpdate = (
  locationId: string,
  accountId: string,
  isAccountNumberSearch: boolean,
  accountUpdates: Partial<IUserPersonalInformation>
) =>
  action(types.CONFIRM_CSR_ACCOUNT_UPDATE, {
    locationId,
    accountId,
    isAccountNumberSearch,
    accountUpdates,
  });

export const confirmCsrAccountUpdateByLocation = (
  locationId: string,
  accountId: string,
  accountUpdates: Partial<IUserPersonalInformation>,
  grouping?: DashboardQueryTypes
) =>
  action(types.CONFIRM_CSR_ACCOUNT_UPDATE_BY_LOCATION, {
    locationId,
    accountId,
    accountUpdates,
    grouping,
  });

export const updateAccountForAllLoadedLocations = (
  accountId: string,
  isAccountNumberSearch: boolean,
  accountUpdates: Partial<IUserPersonalInformation>
) => action(types.UPDATE_ACCOUNT_FOR_ALL_LOADED_LOCATIONS, { accountId, isAccountNumberSearch, accountUpdates });

export const setHasUnsavedChanges = (hasUnsavedChanges: boolean) =>
  action(types.SET_HAS_UNSAVED_CHANGES, {
    hasUnsavedChanges,
  });

export const changeCsrWithAssignedAccountsPaginationCurrent = (current: number) =>
  action(types.CSR_WITH_ASSIGNED_ACCOUNTS_PAGINATION_CHANGE, {
    current,
  });

export const changeCsrPaginationCurrent = (current: number) =>
  action(types.CSR_PAGINATION_CHANGE, {
    current,
  });

export const setAllCustomersSearchValues = (searchText: string, msrId) =>
  action(types.SET_ALL_CUSTOMERS_SEARCH_VALUES, {
    searchText,
    msrId,
  });
