import { takeLatest, select, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { deleteCartItem, deleteCartItemActionConstants, resetPromotion } from 'store/shopping-cart/actions';
import { IAction } from 'types/actions';
import { selectCartInfo } from 'store/shopping-cart/selectors';

export interface IDeleteCartItemPayload {
  skuNum: string;
  onSuccessCallback?: () => void;
}

export function* deleteCartItemSagaHandler({ payload }: IAction<IDeleteCartItemPayload>) {
  yield put(doRequest(deleteCartItem, `${Endpoints.CART}/${payload.skuNum}`, 'delete'));
}

export function* deleteCartItemSuccessHandlerSagaHandler({ payload }: IAction<IDeleteCartItemPayload>) {
  yield take(deleteCartItemActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export function* removeAppliedCouponOnEmptyShoppingCart() {
  const cartInfo = yield select(selectCartInfo);

  if (!cartInfo.totalItemsCounter) {
    yield put(resetPromotion());
  }
}

export default function* deleteCartItemSaga() {
  yield takeLatest(deleteCartItemActionConstants[Actions.REQUEST], deleteCartItemSagaHandler);
  yield takeLatest(deleteCartItemActionConstants[Actions.REQUEST], deleteCartItemSuccessHandlerSagaHandler);
  yield takeLatest(deleteCartItemActionConstants[Actions.SUCCESS], removeAppliedCouponOnEmptyShoppingCart);
}
