import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getSkuReplacementsActionConstants = createConstants(types.GET_SKU_REPLACEMENTS);
export const getProductReplacementSkuSubtotalConstants = createConstants(types.GET_PRODUCT_REPLACEMENT_SKU_SUBTOTAL);
export const getDiscontinuedItemsReplacementsActionConstants = createConstants(
  types.GET_DISCONTINUED_ITEMS_REPLACEMENTS
);

export const getSkuReplacements = createRequestActions(getSkuReplacementsActionConstants);
export const getDiscontinuedItemsReplacements = createRequestActions(getDiscontinuedItemsReplacementsActionConstants);
export const getProductReplacementSkuSubtotal = createRequestActions(getProductReplacementSkuSubtotalConstants);

export const clearSkuReplacements = () => action(types.CLEAR_SKU_REPLACEMENTS);
export const clearSkuReplacementsSubtotal = (skuNumber: string) =>
  action(types.CLEAR_ITEM_REPLACEMENTS_SUBTOTAL, { skuNumber });
export const clearDiscontinuedItemsReplacements = () => action(types.CLEAR_DISCONTINUED_ITEMS_REPLACEMENTS);
export const setLastRequestProductReplacmentSku = (sku: string) => action(types.SET_LAST_REQUESTED_SKU, sku);
