import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getSaveForLaterItems, getSaveForLaterItemsActionConstants } from 'store/save-for-later/actions';
import { IAction } from 'types/actions';

export interface IGetSaveForLaterItemsPayload {
  offset: number;
  limit: number;
}

export function* getSaveForLaterItemsHandler({ payload: { offset, limit } }: IAction<IGetSaveForLaterItemsPayload>) {
  const url = `${Endpoints.SAVE_FOR_LATER}?limit=${limit}&offset=${offset}`;
  yield put(doRequest(getSaveForLaterItems, url, 'get'));
}

export default function* getSaveForLaterItemsSaga() {
  yield takeLatest(getSaveForLaterItemsActionConstants[Actions.REQUEST], getSaveForLaterItemsHandler);
}
