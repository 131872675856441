import { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CorButton } from 'components/cor-button';
import { StickyView } from 'components/sticky-view';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { impersonationStop } from 'store/auth/actions';
import { selectImpersonateInfo } from 'store/auth/selectors';
import { stripLeadingZeros } from 'utils/string-format';
import { useStickyOffsets } from 'hooks/use-sticky-offsets';
import { useContent } from 'hooks/use-content';
import { StickySectionsKeys } from 'context/sticky-sections-heights-context';

import './impersonation-mode-header.scss';

export const ImpersonationModeHeader: FC = () => {
  const dispatch = useDispatch();
  const impersonateInfo = useSelector(selectImpersonateInfo);
  const handleEndImpersonationClick = () => dispatch(impersonationStop.request());
  const { getContentByKey } = useContent();
  const content = getContentByKey('common[0].impersonation[0].header_message', '');

  const ref = useRef<HTMLDivElement>(null);
  const { offset } = useStickyOffsets({
    ref,
    sectionKey: StickySectionsKeys.impersonationHeader,
    updateHeightDependency: content,
  });

  return (
    <StickyView offsetTop={offset}>
      <div className="impersonation-mode-header" ref={ref}>
        <div className="grid-container">
          <div className="impersonation-mode-header__inner">
            <div className="impersonation-mode-header__info">
              <ContentstackText contentKey="common[0].impersonation[0].header_message" />{' '}
              <strong>
                {impersonateInfo?.firstName} {impersonateInfo?.lastName}
                <ContentstackText
                  contentKey="common[0].impersonation[0].impersonated_user_account_id_text"
                  interpolateParams={{
                    accountNumber: stripLeadingZeros(String(impersonateInfo?.accountNumber)),
                  }}
                />
              </strong>
            </div>
            <CorButton
              color="link-2"
              className="impersonation-mode-header__end-impersonation-button"
              onClick={handleEndImpersonationClick}
            >
              <ContentstackText contentKey="common[0].impersonation[0].end_impersonation_button_label" />
            </CorButton>
          </div>
        </div>
      </div>
    </StickyView>
  );
};
