import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/similar-items/actions';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import { Actions } from 'constants/actions.enum';
import types from 'store/similar-items/action-types';
import { ISkuInformation } from 'types/order-details';
import { ILineItem } from 'types/line-item';

export interface ISimilarItemsState {
  loading: boolean;
  similarItemsData: ISkuInformation[];
  requestQuoteSimilarItemsLoading: boolean;
  requestQuoteSimilarItemsData: ISkuInformation[];
  lastRequestedSku: string;
}

export const similarItemsInitialState: ISimilarItemsState = {
  loading: false,
  similarItemsData: [],
  requestQuoteSimilarItemsLoading: false,
  requestQuoteSimilarItemsData: [],
  lastRequestedSku: '',
};

const skuReplacements = createReducer<ISimilarItemsState>(
  {
    [actions.getSimilarItemsConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
      similarItemsData: [],
    }),
    [actions.getSimilarItemsConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      loading: false,
      similarItemsData: payload,
    }),
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      similarItemsData: state.similarItemsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
      requestQuoteSimilarItemsData: state.requestQuoteSimilarItemsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
    }),
    [actions.getSimilarItemsConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
      similarItemsData: [],
    }),
    [actions.getRequestQuoteSimilarItemsConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      requestQuoteSimilarItemsLoading: true,
      requestQuoteSimilarItemsData: [],
    }),
    [actions.getRequestQuoteSimilarItemsConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      requestQuoteSimilarItemsLoading: false,
      requestQuoteSimilarItemsData: payload,
    }),
    [actions.getRequestQuoteSimilarItemsConstants[Actions.FAIL]]: (state) => ({
      ...state,
      requestQuoteSimilarItemsLoading: false,
      requestQuoteSimilarItemsData: [],
    }),
    [actions.getSimilarItemsSkuSubtotalConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getSimilarItemsSkuSubtotalConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      loading: false,
      similarItemsData: state?.similarItemsData?.map((item: ISkuInformation) => {
        return {
          ...item,
          totalPrice:
            item.sku === state.lastRequestedSku
              ? {
                  ...item.totalPrice,
                  amount: payload.amount,
                  currencySymbol: payload.currencySymbol,
                }
              : {
                  ...item.totalPrice,
                  amount: item.price?.amount,
                  currencySymbol: item.price?.currencySymbol,
                },
        };
      }),
    }),
    [types.SET_LAST_REQUESTED_SKU]: (state, { payload }) => ({
      ...state,
      lastRequestedSku: payload,
    }),
    [types.CLEAR_SIMILAR_ITEMS]: (state) => ({ ...state, similarItemsData: [] }),
    [types.CLEAR_REQUEST_QUOTE_SIMILAR_ITEMS]: (state) => ({ ...state, requestQuoteSimilarItemsData: [] }),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) => ({
      ...state,
      similarItemsData: state.similarItemsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
      requestQuoteSimilarItemsData: state.requestQuoteSimilarItemsData.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
    }),
  },
  similarItemsInitialState
);

export default skuReplacements;
