import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContent } from 'hooks/use-content';
import { ProductsCarousel } from 'components/products-carousel';
import { getRecentlyViewedItems } from 'store/home/actions';
import { selectRecentlyViewedItems } from 'store/home/selectors';

export const RecentlyViewedItemsCarousel: FC = () => {
  const dispatch = useDispatch();
  const { getContentByKey } = useContent();
  const recentlyViewedCarouselProducts = useSelector(selectRecentlyViewedItems);

  useEffect(() => {
    dispatch(getRecentlyViewedItems.request());
  }, [dispatch]);

  return (
    <ProductsCarousel
      className="recently-viewed-items-carousel"
      title={getContentByKey<string>('page_content.recently_viewed_products_carousel_title', '')}
      products={recentlyViewedCarouselProducts}
      usageContext="recently-viewed-items"
    />
  );
};
