import { push, replace } from 'redux-first-history';
import { history } from 'store';
import { takeLatest, put, select } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { Routes } from 'constants/routes.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { updatePasswordFromTemporary, updatePasswordFromTemporaryActionConstants } from 'store/account/actions';
import { allowedShopLocales } from 'constants/shop-locales';
import { selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { getLocalizedShopUrl } from 'utils/get-localized-shop-url';
import { CorContentStackLanguages } from 'constants/cor-locale.enum';

export interface IUpdatePasswordPayload extends IUpdatePasswordFromTemporaryPayload {
  internalId: string;
  locationsCount: number;
}

interface IUpdatePasswordFromTemporaryPayload {
  password: string;
  confirmPassword: string;
}

let isMultiLocation = false;

export function* updatePasswordFromTemporaryHandler({
  payload: { internalId, locationsCount, ...requestData },
}: IAction<IUpdatePasswordPayload>) {
  isMultiLocation = locationsCount > 1;
  yield put(
    doRequest<IUpdatePasswordFromTemporaryPayload>(
      updatePasswordFromTemporary,
      `${Endpoints.UPDATE_PASSWORD_FROM_TEMPORARY}/${internalId}`,
      'put',
      requestData
    )
  );
}

export function* updatePasswordFromTemporarySuccessHandler() {
  const locale = localStorage.getItem('locale');
  const isInvoiceOnly = yield select(selectIsInvoiceOnlyCustomer);

  const isInternationalLocale = locale ? allowedShopLocales.includes(JSON.parse(locale)) : false;
  const isCanadaFrenchLocale = locale?.includes(CorContentStackLanguages.FRENCH_CANADA);

  if (isMultiLocation && !isInvoiceOnly && !isInternationalLocale) {
    yield put(push({ pathname: Routes.SelectLocationPage, state: history.location.state }));
  } else {
    if (isInvoiceOnly || isInternationalLocale) {
      const routeToRedirect = isCanadaFrenchLocale ? Routes.CanadaFrenchPayments : Routes.AccountPayments;
      yield put(replace(getLocalizedShopUrl(routeToRedirect)));
    } else {
      yield put(replace(Routes.ShopHomePage));
    }
  }
}

export default function* updatePasswordFromTemporarySaga() {
  yield takeLatest(updatePasswordFromTemporaryActionConstants[Actions.REQUEST], updatePasswordFromTemporaryHandler);
  yield takeLatest(
    updatePasswordFromTemporaryActionConstants[Actions.SUCCESS],
    updatePasswordFromTemporarySuccessHandler
  );
}
