import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import {
  getLocationsByNameForCsr,
  getLocationsByNameForCsrConstants,
  setAllCustomersSearchValues,
} from 'store/dashboard/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IMsrDashboardPayload {
  searchText?: string;
  employeeId?: string;
  limit: number;
  offset: number;
}

export function* getLocationsByNameHandler({
  payload: { searchText = '', employeeId = '', limit, offset },
}: IAction<IMsrDashboardPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);

  yield put(
    doRequest(
      getLocationsByNameForCsr,
      `${Endpoints.CSR_DASHBOARD_LOCATIONS_BY_NAME}?searchText=${searchQuery}&employeeId=${employeeId}&limit=${limit}&offset=${offset}`,
      'get'
    )
  );

  if (offset === 0) {
    yield put(setAllCustomersSearchValues(searchText, employeeId));
  }
}

export default function* getLocationsByNameSaga() {
  yield takeLatest(getLocationsByNameForCsrConstants[Actions.REQUEST], getLocationsByNameHandler);
}
