/* eslint-disable @typescript-eslint/quotes */
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const timeZoneMap = {
  CT: 'America/Chicago',
  PT: 'America/Los_Angeles',
  ET: 'America/New_York',
  HT: 'Pacific/Honolulu',
  AKT: 'America/Anchorage',
  MT: 'America/Denver',
  AT: 'America/Puerto_Rico',
  NT: 'America/St_Johns',
};

export const formatTimestamp = (timestamp?: string, timeZone?: string) => {
  if (!timestamp || !timeZone) {
    return;
  }

  const date = new Date(timestamp);
  const zonedDate = utcToZonedTime(date, timeZoneMap[timeZone]);
  return `${format(zonedDate, "MMM dd, yyyy 'at' hh:mmaaa")} ${timeZone}`;
};
