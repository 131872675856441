import './cor-product-tile.scss';

import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CorTypography } from 'corporate-ui';
import { CorProductTileStatus } from 'corporate-components/cor-product-list/components/cor-product-tile-status';
import { Routes } from 'constants/routes.enum';
import { stripLeadingZeros } from 'utils/string-format';
import { ReactComponent as IconCircleCheck } from 'assets/images/icons/icon-circle-check.svg';
import { ReactComponent as IconCircleUncheck } from 'assets/images/icons/icon-circle-uncheck.svg';
import { ProductImage } from 'components/product-image';
import { ContentstackText } from 'components/contentstack';
import { ProductNewArrivalBadge } from 'components/product-new-arrival-badge';
import { ProductStatusLabels } from 'components/product-status-labels';

export interface ICorProductTileProps {
  contactRepLabel: string;
  brand: string;
  sku: string;
  productKey: string;
  isSpecialOrder: boolean;
  substitutionSku: string[];
  similarItems: string[];
  name: string;
  packing: string;
  imageUrl: string;
  price: {
    amount?: string;
    currencySymbol?: string;
  };
  isDiscontinued: boolean;
  isPhasingOut: boolean;
  isOutOfStock: boolean;
  isInactive?: boolean;
  newArrival: boolean;
  clickHandler?: () => void;
  isSelected?: boolean;
  isUserLocationDeleted?: boolean;
  isKeyAccountUser?: boolean;
}

export const CorProductTile: FC<ICorProductTileProps> = (props) => {
  const {
    brand,
    name,
    isSelected,
    productKey,
    clickHandler,
    imageUrl,
    isDiscontinued,
    isPhasingOut,
    isInactive,
    sku,
    isSpecialOrder,
    isUserLocationDeleted,
    newArrival,
  } = props;
  const isDisabled = isDiscontinued || isInactive;
  const productTileClassModifiers = classNames('cor-product-tile', {
    'is-inactive': isDisabled,
    'is-special-order': isSpecialOrder,
  });

  const productStatuses = [isDiscontinued, isSpecialOrder, isPhasingOut].filter(Boolean);
  const productHasMultipleStatuses = productStatuses.length > 1;
  const productStatusLabelsClassnames = classNames('cor-product-tile__statuses-wrapper', {
    'cor-product-tile__statuses-wrapper--multiple-statuses': productHasMultipleStatuses,
  });

  const renderTileImage = () => {
    return (
      <div className="cor-product-tile__image'">
        <ProductImage
          src={imageUrl}
          altText={name}
          desktopSize={{
            width: 462,
            height: 458,
          }}
          withOverlay={isDisabled}
        />
        {(isDiscontinued || isSpecialOrder || isPhasingOut) && (
          <div className={productStatusLabelsClassnames}>
            <ProductStatusLabels
              isDiscontinued={isDiscontinued}
              isSpecialOrder={isSpecialOrder}
              isPhasingOut={isPhasingOut}
              contentstackPath="common[0].shared_recipe_detail_page[0]"
            />
          </div>
        )}
      </div>
    );
  };

  const renderTileDescription = () => {
    return (
      <>
        <CorTypography component={'div'} className={'cor-product-tile__brand'} color={'brown'}>
          {brand}
        </CorTypography>
        <CorTypography
          component={'div'}
          className={'cor-product-tile__name'}
          color={isInactive ? 'mid-gray' : 'dark-gray'}
          variant={'h4'}
        >
          {newArrival && (
            <ProductNewArrivalBadge contentKey="common[0].shared_recipe_detail_page[0].new_arrival_badge" />
          )}
          {name}
        </CorTypography>
        <CorTypography
          component={'div'}
          className={classNames('cor-product-tile__sku-no', {
            'cor-product-tile__sku-no-with-status-label':
              isDiscontinued || isInactive || isSpecialOrder || isPhasingOut,
          })}
          color={'dark-gray'}
          variant={'h4'}
        >
          <ContentstackText contentKey={'common[0].shared_recipe_detail_page[0].number_label'} />
          {stripLeadingZeros(sku)}
        </CorTypography>
      </>
    );
  };

  return (
    <div className={classNames(productTileClassModifiers)}>
      <div className={'cor-product-tile__container'}>
        <div className={classNames('cor-product-tile__graphic', { 'cor-product-tile__graphic--inactive': isInactive })}>
          {!isDisabled && !isUserLocationDeleted && (
            <div className={'cor-product-tile__check-badge'} onClick={() => clickHandler && clickHandler()}>
              {isSelected ? <IconCircleCheck /> : <IconCircleUncheck />}
            </div>
          )}
          {!isDisabled && isUserLocationDeleted && (
            <div className={'cor-product-tile__check-badge--is-deleted-location'}>
              <IconCircleUncheck />
            </div>
          )}
          {!isInactive ? (
            <Link className="product-card__link" to={`${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku}`}>
              {renderTileImage()}
            </Link>
          ) : (
            renderTileImage()
          )}
        </div>
        {!isInactive ? (
          <Link className="product-card__link" to={`${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku}`}>
            {renderTileDescription()}
          </Link>
        ) : (
          renderTileDescription()
        )}

        <CorProductTileStatus {...props} />
      </div>
    </div>
  );
};
