import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getLocationsByDayOfWeekForCsrConstants, getLocationsByDayOfWeekForCsr } from 'store/dashboard/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';

export interface IGetLocationsByDayOfWeekPayload {
  searchText?: string;
  employeeId?: string;
}

export function* getLocationsByDayOfWeekHandler({
  payload: { searchText = '', employeeId = '' },
}: IAction<IGetLocationsByDayOfWeekPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText?.trim());

  yield put(
    doRequest(
      getLocationsByDayOfWeekForCsr,
      `${Endpoints.CSR_DASHBOARD_LOCATIONS_BY_DAY_OF_WEEK}?searchText=${searchQuery}&employeeId=${employeeId}`,
      'get'
    )
  );
}

export default function* getLocationsByDayOfWeekSaga() {
  yield takeLatest(getLocationsByDayOfWeekForCsrConstants[Actions.REQUEST], getLocationsByDayOfWeekHandler);
}
