import classNames from 'classnames';
import { HeroOverlayColor } from 'corporate-components/cor-hero/components/cor-hero-container';
import { CorColorsType } from 'corporate-types/colors.type';
import { CorTypography } from 'corporate-ui';
import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash-es';
import { useSwiper } from 'swiper/react';
import { CorContentstackHtml } from 'corporate-components/cor-contentstack/cor-contentstack-html';
import { corContentstackAssetLinkResolver } from 'corporate-utils/cor-contentstack-asset-link-resolver';
import { CorButtonLink } from 'components/cor-button-link';
import { useContent } from 'hooks/use-content';
import { ContentstackText } from 'components/contentstack';
import { ICSUtilityNavButton } from 'components/layout/cor-header/cor-header.interface';

import './cor-hero-content.scss';

export type HeroContentVariants = 'card' | 'overlay' | 'carousel' | 'inset' | undefined;

export type HeroContentAlignment = 'left' | 'center' | 'right' | undefined;

export type TextColor = 'light' | 'dark';

export interface IHeroContentProps {
  alignment: HeroContentAlignment;
  textColor: TextColor;
  variant?: HeroContentVariants;
  contentBackgroundColor?: HeroOverlayColor;
  buttons?: [];
  isInset?: boolean;
}

export const CorHeroContent: FC<IHeroContentProps> = ({
  variant,
  alignment,
  textColor,
  contentBackgroundColor,
  isInset,
}) => {
  const [paginationElements, setPaginationElements] = useState<HTMLElement[]>([]);
  const { getContentByKey } = useContent();
  const swiper = useSwiper();
  const variantClassModifier = variant ? `cor-hero-content--${variant}` : null;
  const alignmentClassModifier = `cor-hero-content--${alignment}`;
  const overlayColorClassModifier =
    contentBackgroundColor && variant === 'overlay' ? `cor-hero-content--overlay-${contentBackgroundColor}` : null;
  const isNotCardOrCarousel = variant !== 'card' && variant !== 'carousel';
  const textColorClassModifier = isNotCardOrCarousel ? `cor-hero-content--${textColor}` : null;
  const headingColorValue: CorColorsType = isNotCardOrCarousel
    ? textColor === 'dark'
      ? 'dark-gray'
      : 'white'
    : 'dark-gray';

  const descriptionColorValue: CorColorsType = isNotCardOrCarousel
    ? textColor === 'dark'
      ? 'mid-gray'
      : 'white'
    : 'mid-gray';

  const buttons: ICSUtilityNavButton[] = getContentByKey('button', []);
  const hasCarousel = swiper && variant === 'carousel';

  useEffect(() => {
    if (swiper) {
      setPaginationElements(() => Array.from(swiper.pagination.bullets) as HTMLElement[]);
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.on('paginationUpdate', (swiper) => {
        setPaginationElements(() => Array.from(swiper.pagination.bullets) as HTMLElement[]);
      });
    }
  }, [swiper]);

  return (
    <div
      data-testid={'cor-hero-content'}
      className={classNames(
        'cor-hero-content',
        variantClassModifier,
        alignmentClassModifier,
        textColorClassModifier,
        overlayColorClassModifier,
        { 'cor-hero-content--inset': isInset }
      )}
    >
      <div className={classNames('cor-hero-content__arrows', { 'is-visible': hasCarousel })}>
        <button className={'cor-hero-content__arrow cor-hero-content__arrow-prev'} onClick={() => swiper.slidePrev()}>
          <span className={'cor-hero-content__arrow-label'}>Previous Slide</span>
          <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1L2 7L8 13" stroke="#E03C32" strokeWidth="2" />
          </svg>
        </button>
        <button className={'cor-hero-content__arrow cor-hero-content__arrow-next'} onClick={() => swiper.slideNext()}>
          <span className={'cor-hero-content__arrow-label'}>Next Slide</span>
          <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L7 7L1 1" stroke="#E03C32" strokeWidth="2" />
          </svg>
        </button>
      </div>

      <CorTypography className={'cor-hero-content__headline'} color={headingColorValue} variant={isInset ? 'h2' : 'h1'}>
        <ContentstackText contentKey={'headline'} />
      </CorTypography>

      <CorContentstackHtml
        className={classNames(
          `cor-hero-content__description cor-rich-text cor-text-color__${descriptionColorValue} extend-h2`
        )}
        contentKey={'description'}
      />
      <div className={'cor-hero-content__buttons'}>
        {buttons?.map(
          (
            { button, button_style, open_in_a_new_tab, button_icon = [], button_alignment, ...buttonProps }: any,
            index: number
          ) => {
            const asset = corContentstackAssetLinkResolver(button)?.url;
            return (
              <CorButtonLink
                color={(button_style || 'primary').toLowerCase()}
                href={asset ?? button?.href}
                target={open_in_a_new_tab ? '_blank' : '_self'}
                iconUrl={get(button_icon, '[0].cdn_url')}
                iconPosition={button_alignment}
                key={`btn-link-${index}`}
                asset={asset}
              >
                {button.title}
              </CorButtonLink>
            );
          }
        )}
      </div>
      {paginationElements?.length > 0 && (
        <div className={'cor-hero-content__pagination'}>
          {paginationElements?.map((bullet, index) => (
            <span
              key={index}
              className={classNames('cor-hero-content__bullet', {
                'is-active': bullet.classList.contains('swiper-pagination-bullet-active'),
              })}
            />
          ))}
        </div>
      )}
    </div>
  );
};
