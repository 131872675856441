import { takeLatest, put } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { getSimilarItemsSkuSubtotal, getSimilarItemsSkuSubtotalConstants } from 'store/similar-items/actions';

export interface IGetSimilarItemsSkuSubtotalPayload {
  sku: string;
  amount?: number;
}

export function* getSimilarItemsSkuSubtotalHandler({
  payload: { sku, amount },
}: IAction<IGetSimilarItemsSkuSubtotalPayload>) {
  const url = `${Endpoints.SKU_SUBTOTAL_PRICE}/${sku}?amount=${amount || ''}`;
  yield put(doRequest(getSimilarItemsSkuSubtotal, url, 'get'));
}

export default function* getSimilarItemsSkuSubtotalSaga() {
  yield takeLatest(getSimilarItemsSkuSubtotalConstants[Actions.REQUEST], getSimilarItemsSkuSubtotalHandler);
}
