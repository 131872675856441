import { push } from 'redux-first-history';
import { AnyAction } from 'redux';
import { takeLatest, put, select } from 'redux-saga/effects';
import { Routes } from 'constants/routes.enum';
import { Endpoints } from 'constants/endpoints.enum';
import config from 'config/app';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { validateCart, validateCartActionConstants } from 'store/checkout/actions';
import { selectIsInternalUser } from 'store/auth/selectors';
import { selectCheckoutInfo } from 'store/checkout/selectors';
import { CheckoutSteps } from 'types/checkout';
import { sendCheckoutStep } from 'utils/analytics';
import { resetPromotion } from 'store/shopping-cart/actions';

let isInternalUser: boolean;

export function* validateCartSagaHandler() {
  isInternalUser = yield select(selectIsInternalUser);
  yield put(doRequest(validateCart, Endpoints.CART_CHECKOUT, 'post'));
}

export function* validateCartFailSagaHandler({ error: { status } }: AnyAction) {
  yield put(resetPromotion());

  if (![401, 403].includes(status)) {
    yield put(push(Routes.ShoppingCartPage));
    return;
  }

  if (status === 400) {
    yield put(resetPromotion());
  }

  if (!isInternalUser) {
    setTimeout(() => {
      window.location.href = `${config.apiUrl}${Endpoints.LOGOUT}`;
    }, 1000);
  } else {
    yield put(push(Routes.ShoppingCartPage));
  }
}

export function* validateCartSuccessSagaHandler() {
  const checkoutInfo = yield select(selectCheckoutInfo);
  if (checkoutInfo.lineItems.length === 0) {
    yield put(push(Routes.ShoppingCartPage));
  } else sendCheckoutStep(CheckoutSteps.CHECKOUT, checkoutInfo.lineItems);
}

export default function* validateCartSaga() {
  yield takeLatest(validateCartActionConstants[Actions.REQUEST], validateCartSagaHandler);
  yield takeLatest(validateCartActionConstants[Actions.FAIL], validateCartFailSagaHandler);
  yield takeLatest(validateCartActionConstants[Actions.SUCCESS], validateCartSuccessSagaHandler);
}
