import qs from 'qs';
import { takeLatest, put } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { getInternalUserQuotes, getInternalUserQuotesActionConstants } from 'store/quotes/actions';
import { IAction } from 'types/actions';
import { excludeSpecialCharacters } from 'utils/string-format';
import { Roles } from 'constants/roles.enum';

export interface IGetQuotesPayload {
  withoutLoading?: boolean;
  limit: number;
  offset: number;
  sortDirection?: string;
  statuses?: string;
  searchText?: string;
  internalUserRole?: string;
  msrId?: string;
}

export function* getInternalUserQuotesSagaHandler({
  payload: { limit, offset, sortDirection, statuses, searchText, internalUserRole, msrId },
}: IAction<IGetQuotesPayload>) {
  const searchQuery = excludeSpecialCharacters(searchText);

  const isMsmOrMsrUser = [Roles.MSM, Roles.MSR].some((role) => role === internalUserRole);
  const quotesDashboardEndpoint =
    isMsmOrMsrUser && internalUserRole === Roles.MSM ? Endpoints.DASHBOARD_MSM_QUOTES : Endpoints.DASHBOARD_MSR_QUOTES;

  yield put(
    doRequest(
      getInternalUserQuotes,
      `${quotesDashboardEndpoint}?${qs.stringify(
        {
          limit,
          offset,
          sortDirection,
          statuses,
          searchText: searchQuery,
          msrId,
        },
        { skipNulls: true }
      )}`,
      'get'
    )
  );
}

export default function* getInternalUserQuotesSaga() {
  yield takeLatest(getInternalUserQuotesActionConstants[Actions.REQUEST], getInternalUserQuotesSagaHandler);
}
