import { FC, Children, ReactNode } from 'react';
import { get, template } from 'lodash-es';
import classNames from 'classnames';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { ContentstackImage } from 'components/contentstack';
import { IAnyObject } from 'types/generic-types';

import './general-warning.scss';

export interface INotificationProps {
  contentstackPath: string;
  messageType?: string;
  messageId?: string;
  interpolateParams?: IAnyObject;
  onCloseHandler?: () => void;
  className?: string;
  titleClassName?: string;
  children?: ReactNode;
  title?: string;
}

interface IMessage {
  message_id: string;
  message_content: string;
}

export const GeneralWarning: FC<INotificationProps> = ({
  contentstackPath,
  messageType,
  messageId,
  interpolateParams,
  onCloseHandler,
  children,
  className,
  titleClassName,
  title,
}) => {
  const titleClasses = classNames('grid-x general-warning__title-block', titleClassName);
  const content = useContentstackContent();
  const messages: IMessage[] = get(content, `${messageType}.0.messages`, []);
  const message = messages.find((message) => message.message_id === messageId);
  const compiled = template(message?.message_content);

  return (
    <div className={classNames('general-warning', className)}>
      <div className={titleClasses}>
        <div className="general-warning__warning-icon">
          <ContentstackImage contentKey={`${contentstackPath}.warning_icon`} />
        </div>
        {title ? (
          <div> {title} </div>
        ) : (
          <div
            className="general-warning__title"
            dangerouslySetInnerHTML={{
              __html: compiled(interpolateParams),
            }}
          />
        )}
      </div>
      {Children.map(children, (message: ReactNode) => {
        return message && <div className="general-warning__message">{message}</div>;
      })}
      {onCloseHandler && <button className="general-warning__close" onClick={onCloseHandler} aria-label="Close" />}
    </div>
  );
};
