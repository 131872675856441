import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { IItemsReplacementsState } from './reducer';

export const selectItemsReplacementsEntity = (state: IState): IItemsReplacementsState => state.app.itemsReplacements;

export const selectSkuReplacementsEntity = createSelector(
  selectItemsReplacementsEntity,
  ({ skuReplacements }) => skuReplacements
);

export const selectSkuReplacementsData = createSelector(
  selectSkuReplacementsEntity,
  ({ skuReplacementsData }) => skuReplacementsData
);

export const selectSkuReplacementsLoading = createSelector(selectSkuReplacementsEntity, ({ loading }) => loading);

export const selectDiscontinuedItemsWithReplacementsEntity = createSelector(
  selectItemsReplacementsEntity,
  ({ discontinuedItemsWithReplacements }) => discontinuedItemsWithReplacements
);

export const selectDiscontinuedItemsWithReplacements = createSelector(
  selectDiscontinuedItemsWithReplacementsEntity,
  ({ discontinuedItemsWithReplacements }) => discontinuedItemsWithReplacements
);

export const selectDiscontinuedItemsWithReplacementsLoading = createSelector(
  selectDiscontinuedItemsWithReplacementsEntity,
  ({ loading }) => loading
);

export const selectLastProductReplacementRequestedSku = createSelector(
  selectSkuReplacementsEntity,
  ({ lastRequestedSku }) => lastRequestedSku
);
