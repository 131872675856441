import { useState, useEffect } from 'react';

export const useZoomLevel = () => {
  const [zoomLevel, setZoomLevel] = useState<number>(window.devicePixelRatio * 100);

  useEffect(() => {
    const updateZoomLevel = () => {
      setZoomLevel(window.devicePixelRatio * 100);
    };
    window.addEventListener('resize', updateZoomLevel);

    return () => {
      window.removeEventListener('resize', updateZoomLevel);
    };
  }, []);

  return zoomLevel;
};
