import './cor-card-with-button.scss';

import { CorTypography } from 'corporate-ui';
import React from 'react';
import { CorContentstackImage, CorContentstackLink } from 'corporate-components/cor-contentstack';
import {
  ICorFeaturedContentCardVariant,
  ICorFeaturedContentEntry,
} from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { CorButtonLink } from 'components/cor-button-link';
import { useContent } from 'hooks/use-content';

const CorCardWithButton = ({ contentKeyBase }: ICorFeaturedContentCardVariant) => {
  const { getContentByKey } = useContent();
  const entry: ICorFeaturedContentEntry = getContentByKey(contentKeyBase, {});

  return (
    <div className="cor-featured-content__card-with-button cor-featured-content__card cor-featured-content__card--medium-size">
      <CorContentstackLink
        className="cor-featured-content__card-with-button__image__container cor-featured-content__card__image__container"
        contentKey={`${contentKeyBase}.pageUrl`}
        fallbackElement="div"
      >
        {!!entry?.image && (
          <CorContentstackImage
            contentKey={`${contentKeyBase}.image`}
            className="cor-featured-content__card-with-button__image cor-featured-content__card__image"
          />
        )}
      </CorContentstackLink>
      <div className="cor-featured-content__card-with-button__description">
        {!!entry?.title && (
          <CorContentstackLink
            contentKey={`${contentKeyBase}.pageUrl`}
            fallbackElement="h4"
            className="cor-featured-content__card-with-button__description__category"
            data-testid="cor-featured-content__card-with-button__title"
          >
            {entry?.title}
          </CorContentstackLink>
        )}

        {!!entry?.description && (
          <CorTypography
            variant="body-regular"
            color="mid-gray"
            className="cor-featured-content__card-with-button__description__content"
            data-testid="cor-featured-content__card-with-button__description"
          >
            {entry?.description}
          </CorTypography>
        )}

        <CorButtonLink color="primary" href={entry?.pageUrl?.href}>
          Read More
        </CorButtonLink>
      </div>
    </div>
  );
};

export default React.memo(CorCardWithButton);
