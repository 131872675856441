enum homePageActionTypes {
  GET_RECENT_ORDERS_INFO = 'GET_RECENT_ORDERS_INFO',
  GET_BUY_IT_AGAIN_PRODUCTS = 'GET_BUY_IT_AGAIN_PRODUCTS',
  GET_RECENTLY_VIEWED_ITEMS = 'GET_RECENTLY_VIEWED_ITEMS',
  CLEAR_HOME_TABS_DATA = 'CLEAR_HOME_TABS_DATA',
  GET_HOME_SHOPPING_LISTS = 'GET_HOME_SHOPPING_LISTS',
  GET_BUY_IT_AGAIN_SKU_SUBTOTAL = 'GET_BUY_IT_AGAIN_SKU_SUBTOTAL',
  SET_LAST_REQUEST_SKU = 'SET_LAST_REQUEST_SKU',
  CLEAR_BUY_IT_AGAIN_SUBTOTAL = 'CLEAR_BUY_IT_AGAIN_SUBTOTAL',
}

export default homePageActionTypes;
