import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { LineItemImage } from 'components/line-item-image';
import { ProductIndicators } from 'components/product-indicators';
import { ProductStatusLabels } from 'components/product-status-labels';
import { ISkuInformation } from 'types/order-details';
import { QuantityControl } from 'components/quantity-control';
import { selectIsImpersonateModeOn, selectIsTsrUser, selectIsUserLocationDeleted } from 'store/auth/selectors';
import { addProductToCart } from 'store/shopping-cart/actions';
import { IAddProductToCartPayload } from 'store/shopping-cart/sagas/add-product-to-cart';
import { ContentstackText } from 'components/contentstack';
import { stripLeadingZeros } from 'utils/string-format';
import { formatCurrency } from 'utils/currency-format';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { Routes } from 'constants/routes.enum';
import {
  useHideGlobalModalHandler,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';
import iconAddedToCart from 'assets/images/icons/icon-added-to-cart.svg';
import { AlternativeItemsLineItemButtons } from 'components/alternative-items-line-item/components/alternative-items-line-item-buttons';
import { ProductNewArrivalBadge } from 'components/product-new-arrival-badge';
import { getSimilarItemsSkuSubtotal, setLastRequestSku } from 'store/similar-items/actions';
import { selectSimilarItemLastRequestedSku } from 'store/similar-items/selectors';
import { selectLastProductReplacementRequestedSku } from 'store/items-replacements/selectors';
import {
  clearSkuReplacementsSubtotal,
  getProductReplacementSkuSubtotal,
  setLastRequestProductReplacmentSku,
} from 'store/items-replacements/actions';

import './alternative-items-line-item.scss';

export interface IAlternativeItemsLineItemProps {
  alternativeItem: ISkuInformation;
  onRequestQuoteCtaClick?: (skuKey: string, isOpenedWithinQuickShop: boolean) => void;
  onRequestSimilarItemQuote?: () => void;
  recipeDetailPageContentstackPath?: string;
  isInCart?: boolean;
  onAddYouMayAlsoNeedItemToCart?: (sku: string) => void;
  isProductReplacement?: boolean;
  isSimilarItem?: boolean;
  fromProductDetailsPage?: boolean;
}

export const AlternativeItemsLineItem: React.FC<IAlternativeItemsLineItemProps> = ({
  alternativeItem,
  onRequestQuoteCtaClick,
  onRequestSimilarItemQuote,
  recipeDetailPageContentstackPath,
  isInCart,
  onAddYouMayAlsoNeedItemToCart,
  isProductReplacement,
  isSimilarItem,
  fromProductDetailsPage = false,
}) => {
  const {
    color,
    flavor,
    newArrival,
    brand,
    name,
    price,
    sku,
    isDiscontinued,
    isPhasingOut,
    imageUrl,
    isSpecialOrder,
    maximumQuantity,
    minimumOrderQuantity,
    replacementItems,
    productKey,
    totalPrice,
  } = alternativeItem;
  const contentstackItemPath = recipeDetailPageContentstackPath
    ? recipeDetailPageContentstackPath
    : 'page_content.similar_items.alternative_items_line_item[0]';
  const showRequestQuoteSuccessModal = useRequestQuoteSuccessMessageModal();
  const hideGlobalModal = useHideGlobalModalHandler();
  const showRequestQuoteModal = useRequestQuoteModal({
    skuKey: sku,
    requestQuoteSuccessCallBack: showRequestQuoteSuccessModal,
    requestQuoteFailCallBack: hideGlobalModal,
  });

  const imageMobileSize = { width: 88, height: 88 };
  const imageDesktopSize = { width: 95, height: 95 };
  const lineItemImageProps = {
    title: 'name',
    mobileSize: imageMobileSize,
    desktopSize: imageDesktopSize,
    src: imageUrl,
    renderIndicator: () => <ProductIndicators size="medium" />,
  };

  const { isDesktop, isMobile } = useBreakpoint();
  const dispatch = useDispatch();

  const quantityInputRef = useRef<HTMLInputElement>(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [alternativeItemTotalPrice, setAlternativeItemTotalPrice] = useState({
    amount: price?.amount,
    currencySymbol: price?.currencySymbol,
  });

  const similarItemLastRequestedSku = useSelector(selectSimilarItemLastRequestedSku);
  const productReplacementLastRequestedSku = useSelector(selectLastProductReplacementRequestedSku);
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isTsrUser = useSelector(selectIsTsrUser);

  useEffect(() => {
    (similarItemLastRequestedSku === sku || productReplacementLastRequestedSku === sku) &&
      totalPrice &&
      setAlternativeItemTotalPrice({ amount: totalPrice.amount, currencySymbol: totalPrice.currencySymbol });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  const itemHasOneStatusLabel = () => {
    const itemStatuses = [isSpecialOrder, isDiscontinued, isPhasingOut].filter((item) => Boolean(item));
    return itemStatuses.length === 1;
  };
  const hideQuantityControl = maximumQuantity === 0 || (isDiscontinued && isEmpty(replacementItems));

  const alternativeItemsItemNumberClassname = classNames(
    'alternative-items-line-item__sku-info-item alternative-items-line-item__item-number',
    {
      'alternative-items-line-item__item-number--no-status': !isPhasingOut && !isDiscontinued && !isSpecialOrder,
    }
  );

  const addToCartSuccessCallback = () => {
    setAlternativeItemTotalPrice({
      amount: price?.amount,
      currencySymbol: price?.currencySymbol,
    });

    if (isProductReplacement) {
      dispatch(clearSkuReplacementsSubtotal(sku));
    }
  };

  const addToCart = () => {
    if (quantityInputRef.current) {
      dispatch(
        addProductToCart.request<IAddProductToCartPayload>({
          quantity: Number(quantityInputRef.current.value),
          sku,
          onSuccessCallBack: addToCartSuccessCallback,
        })
      );
    }
  };

  const onAddToCartClick = () => {
    setIsButtonActive(true);
    addToCart();
    setButtonDisabled(true);
    setTimeout(() => {
      setIsButtonActive(false);
      setButtonDisabled(false);
    }, 1000);

    onAddYouMayAlsoNeedItemToCart && onAddYouMayAlsoNeedItemToCart(sku);
  };

  const setQuantity = (quantity?: number) => {
    if (quantity && !isSpecialOrder) {
      if (isSimilarItem) {
        dispatch(setLastRequestSku(sku));
        dispatch(getSimilarItemsSkuSubtotal.request({ sku, amount: quantity }));
      }

      if (isProductReplacement) {
        dispatch(setLastRequestProductReplacmentSku(sku));
        dispatch(getProductReplacementSkuSubtotal.request({ sku, amount: quantity }));
      }
    }
  };

  const renderItemLabels = () => (
    <ProductStatusLabels
      size="small"
      isSpecialOrder={isSpecialOrder}
      isDiscontinued={isDiscontinued}
      isPhasingOut={isPhasingOut}
      contentstackPath={'common[0].product_characteristics.product_status_labels[0]'}
    />
  );

  const renderItemButtons = () => (
    <div className="alternative-items-line-item__buttons">
      {isDesktop && isInCart && <img className="icon-added-to-cart" src={iconAddedToCart} alt="added to cart" />}
      <AlternativeItemsLineItemButtons
        contentstackPath={contentstackItemPath}
        sku={sku}
        isSpecialOrder={Boolean(isSpecialOrder)}
        isDiscontinued={Boolean(isDiscontinued)}
        replacementItems={replacementItems}
        active={isButtonActive}
        disabled={buttonDisabled || maximumQuantity === 0}
        onAddToCartClick={onAddToCartClick}
        onRequestQuoteCtaClick={() => {
          // TODO: remove it after migration of QuickShopModal to global approach
          if (onRequestSimilarItemQuote) {
            onRequestSimilarItemQuote();
            showRequestQuoteModal();
            return;
          }
          if (onRequestQuoteCtaClick) {
            onRequestQuoteCtaClick(sku, true);
            return;
          }
          showRequestQuoteModal();
        }}
      />
      {isMobile && isInCart && <img className="icon-added-to-cart" src={iconAddedToCart} alt="added to cart" />}
    </div>
  );

  const renderPriceBlock = () => (
    <div
      className={classNames('alternative-items-line-item__price-block', {
        'alternative-items-line-item__price-block--with-total-price': fromProductDetailsPage,
      })}
    >
      {price && maximumQuantity !== 0 && (
        <div className="alternative-items-line-item__price">
          <p className="alternative-items-line-item__price-label">
            <ContentstackText contentKey={`${contentstackItemPath}.price_label`} />
          </p>
          <p className="alternative-items-line-item__price-value">{formatCurrency(price)}</p>
        </div>
      )}

      {!hideQuantityControl && (
        <div className="alternative-items-line-item__price-quantity">
          {fromProductDetailsPage && (
            <p className="alternative-items-line-item__price-quantity-label">
              <ContentstackText contentKey={`${contentstackItemPath}.quantity_label`} />
            </p>
          )}
          <QuantityControl
            quantity={1}
            minQuantity={minimumOrderQuantity || 1}
            maxQuantity={maximumQuantity}
            contentStackPath={contentstackItemPath}
            inputRef={quantityInputRef}
            disabled={isUserLocationDeleted}
            onDecreaseQuantityHandler={setQuantity}
            onIncreaseQuantityHandler={setQuantity}
            setCurrentQuantity={setQuantity}
            forceChange={buttonDisabled}
          />
        </div>
      )}
      {!!fromProductDetailsPage && price && maximumQuantity !== 0 && (
        <div className="alternative-items-line-item__total-price alternative-items-line-item__price">
          <p className="alternative-items-line-item__total-price-label alternative-items-line-item__price-label">
            <ContentstackText contentKey={`${contentstackItemPath}.total_price_label`} />
          </p>
          <p className="alternative-items-line-item__price-value alternative-items-line-item__total-price-value">
            {formatCurrency(alternativeItemTotalPrice)}
          </p>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classNames('alternative-items-line-item', {
        'alternative-items-line-item--single-status-label': itemHasOneStatusLabel(),
      })}
    >
      <div className="alternative-items-line-item__content">
        <Link
          className="alternative-items-line-item__link"
          to={`${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku}`}
        >
          <LineItemImage {...lineItemImageProps} />
        </Link>
        <div className="alternative-items-line-item__line-item-info">
          <div className="alternative-items-line-item__info">
            <div className="alternative-items-line-item__sku-info">
              <div className="alternative-items-line-item__brand">{brand}</div>
              <Link
                className="alternative-items-line-item__link"
                to={`${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku}`}
              >
                <div className="alternative-items-line-item__name">
                  {newArrival && <ProductNewArrivalBadge contentKey={`${contentstackItemPath}.new_arrival_badge`} />}
                  {name}
                </div>
              </Link>
            </div>
            {!isSpecialOrder && isDesktop && renderPriceBlock()}
            {(!isImpersonateModeOn || (isImpersonateModeOn && !isTsrUser)) && isDesktop && renderItemButtons()}
          </div>
          <div className="alternative-items-line-item__sku-info-characteristics">
            {(isSpecialOrder || isDiscontinued || isPhasingOut) && isDesktop && renderItemLabels()}
            <div className={alternativeItemsItemNumberClassname}>
              <span>
                <ContentstackText contentKey={`${contentstackItemPath}.item_label`} />
              </span>
              {stripLeadingZeros(sku)}
            </div>
            {color && (
              <div className="alternative-items-line-item__sku-info-item">
                <span>
                  <ContentstackText contentKey={`${contentstackItemPath}.color_label`} />
                </span>
                {color}
              </div>
            )}
            {flavor && (
              <div className="alternative-items-line-item__sku-info-item">
                <span>
                  <ContentstackText contentKey={`${contentstackItemPath}.flavor_label`} />
                </span>
                {flavor}
              </div>
            )}
          </div>
          {isMobile &&
            (isSpecialOrder || isDiscontinued || isPhasingOut) &&
            itemHasOneStatusLabel() &&
            renderItemLabels()}
        </div>
      </div>
      {isMobile && (isSpecialOrder || isDiscontinued || isPhasingOut) && !itemHasOneStatusLabel() && renderItemLabels()}
      {!isSpecialOrder && isMobile && renderPriceBlock()}
      {isMobile && renderItemButtons()}
    </div>
  );
};
