import { FC } from 'react';
import { size } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { DiscontinuedWithReplacementItem } from 'features/discontinued-items-with-replacements';
import { IOriginalToReplacement } from 'components/modals/all-items-added-to-cart-result-message-modal';

import './discontinued-with-replacement-slider.scss';

export interface IDiscontinuedWithReplacementSliderProps {
  originalToReplacementMap: IOriginalToReplacement[] | undefined;
  createRequestQuoteClickHandler: (sku: string) => () => void;
}

export const DiscontinuedWithReplacementSlider: FC<IDiscontinuedWithReplacementSliderProps> = ({
  originalToReplacementMap,
  createRequestQuoteClickHandler,
}) => {
  const contentstackPath = 'modals.0.discontinued_item_modal.0';

  return (
    <Swiper
      modules={[Pagination, Navigation]}
      pagination={{
        clickable: true,
      }}
      speed={500}
      className="discontinued-with-replacement-slider"
      breakpoints={{
        0: {
          allowTouchMove: true,
        },
        960: {
          allowTouchMove: false,
        },
      }}
      navigation={size(originalToReplacementMap) > 1}
      loop
    >
      {originalToReplacementMap?.map(({ original, replacement }) => (
        <SwiperSlide className="discontinued-with-replacement-slider__slide" key={`${original.sku}-${replacement.sku}`}>
          <DiscontinuedWithReplacementItem
            contentstackPath={contentstackPath}
            original={original}
            replacement={replacement}
            createRequestQuoteClickHandler={createRequestQuoteClickHandler}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
