import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useContent } from 'hooks/use-content';
import { IconWithHover } from 'components/icon-with-hover';
import iconPhone from 'assets/images/icons/icon-phone.svg';
import iconEmail from 'assets/images/icons/icon-email.svg';
import iconUserOutlined from 'assets/images/icons/icon-user-outlined.svg';
import 'corporate-components/cor-layout/cor-page-layout.scss';
import { selectSalesRepInformationEntity } from 'store/account/selectors';
import { CorExpandableSection } from 'components/cor-expandable-section';

import './cor-credit-rep.scss';

export interface ICorCreditRep {
  forceIsExpanded?: boolean;
  onExpandStatusChange?: (status: boolean) => void;
}

const LABELS_KEY = 'footer_labels[0].key_value_pair.value';

export const CorCreditRep: FC<ICorCreditRep> = ({ forceIsExpanded, onExpandStatusChange }) => {
  const { getLabelsByKey } = useContent();
  const { creditAnalystName, creditAnalystEmail, creditAnalystPhone } = useSelector(selectSalesRepInformationEntity);
  const labels = getLabelsByKey<Record<string, string>>(LABELS_KEY, {});

  const renderContent = () => (
    <ul className="info">
      {!!creditAnalystName?.trim() && (
        <li>
          <span className="icon-container">
            <IconWithHover icon={iconUserOutlined} hoverIcon={iconUserOutlined} alt="User Icon" />
          </span>{' '}
          <span className="rep-name">{creditAnalystName}</span>
        </li>
      )}
      {!!creditAnalystPhone && (
        <li>
          <span className="icon-container">
            <IconWithHover icon={iconPhone} hoverIcon={iconPhone} alt="Phone Icon" />
          </span>{' '}
          <a className="rep-phone" href={`tel:${creditAnalystPhone}`}>
            {creditAnalystPhone}
          </a>
        </li>
      )}
      {!!creditAnalystEmail && (
        <li>
          <span className="icon-container">
            <IconWithHover icon={iconEmail} hoverIcon={iconEmail} alt="Email Icon" />
          </span>{' '}
          <a className="rep-email" href={`mailto:${creditAnalystEmail}`}>
            {creditAnalystEmail}
          </a>
        </li>
      )}
    </ul>
  );

  return (
    <section className="cor-credit-rep">
      <CorExpandableSection
        withoutAnimation
        forceIsExpanded={forceIsExpanded}
        onExpandStatusChange={onExpandStatusChange}
        title={<h4 className="title">{labels?.credit_representative || 'YOUR CREDIT REPRESENTATIVE'}</h4>}
      >
        {renderContent()}
      </CorExpandableSection>
    </section>
  );
};
