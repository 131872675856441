import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import classNames from 'classnames';
import { useContent } from 'hooks/use-content';
import { AccountStatuses } from 'constants/account-statuses';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { TextField } from 'components/forms/text-field';
import { SubmitButton } from 'components/forms/submit-button';
import { ErrorNotification } from 'components/forms/error-notification';
import { CorButton } from 'components/cor-button';
import {
  confirmEmailValidator,
  createValidationSchema,
  filterFormikErrors,
  newEmailValidator,
  validateFormik,
} from 'utils/validation';
import { IChangeEmailPayload } from 'store/dashboard/sagas/csr/change-customer-email-address';
import {
  changeCustomerEmail,
  clearCsrFormServerErrors,
  confirmCsrAccountUpdate,
  confirmCsrAccountUpdateByLocation,
  setHasUnsavedChanges,
} from 'store/dashboard/actions';
import {
  selectCsrCustomerEmailFormIsSubmiting,
  selectCsrCustomerEmailFormServerErrorId,
  selectIsAccountNumberSearch,
} from 'store/dashboard/selectors';
import { selectVerificationEmailSent } from 'store/customer-registration/selectors';
import iconPaperPlane from 'assets/images/icons/icon-paper-plane.svg';
import { resendVerification, resetEmailSendStatus } from 'store/customer-registration/actions';
import { IResendVerificationPayload } from 'store/customer-registration/sagas/resend-verification';
import { ContentstackMessage } from 'components/contentstack/contentstack-message';
import { useHideGlobalModalHandler, useSuccessErrorMessageModal } from 'hooks/use-global-modal';
import iconCrossRed from 'assets/images/icons/icon-close-red.svg';
import iconSuccess from 'assets/images/icons/icon-success-indicator.svg';
import { ICsrFormProps } from 'pages/dashboard/components/customer-locations-item/components/customer-location-edit-section';

import './change-email-address-form.scss';

export interface ICsrChangeEmailFormValues {
  newEmail: string;
  newEmailConfirm: string;
}

const initialValues: ICsrChangeEmailFormValues = {
  newEmail: '',
  newEmailConfirm: '',
};

export const ChangeEmailAddressForm: FC<ICsrFormProps> = ({
  accountAtLocation,
  locationId,
  onCancel,
  reopenCsrEditUserInfoModal,
  setLocationInEditMode,
  grouping,
}) => {
  const contentstackEditModalPath = 'modals.0.customer_location_item_edit_modal.0';
  const contentstackPath = `${contentstackEditModalPath}.email_address_tab.csr_change_email_form.0`;
  const dispatch = useDispatch();
  const { getContentByKey, getMessageText } = useContent();

  const isAccountNumberSearch = useSelector(selectIsAccountNumberSearch);
  const isSubmitting = useSelector(selectCsrCustomerEmailFormIsSubmiting);
  const serverErrorId = useSelector(selectCsrCustomerEmailFormServerErrorId);
  const isVerificationEmailSent = useSelector(selectVerificationEmailSent);
  const hasServerError = serverErrorId !== '';
  const clientErrorMessage = getMessageText('error', 'MSG019');
  const leavingPageModalText = getContentByKey('common[0].leaving_page_modal.leaving_page_confirmation_modal_text', '');

  const [changeEmailActive, toggleChangeEmailActive] = useState(false);
  const [emailChangedSuccessfully, toggleEmailChangedSuccessfully] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const hideGlobalModal = useHideGlobalModalHandler();

  const showResendVerificationEmailSuccessMessageModal = useSuccessErrorMessageModal({
    type: 'success',
    messageId: 'MSG123',
    onClose: () => {
      hideGlobalModal();
      dispatch(resetEmailSendStatus());
      reopenCsrEditUserInfoModal && reopenCsrEditUserInfoModal();
    },
  });

  const ValidationSchema = createValidationSchema({
    newEmail: newEmailValidator(
      {
        required: getMessageText('error', 'MSG006'),
        wrongFormat: getMessageText('error', 'MSG007'),
      },
      'newEmailConfirm'
    ),
    newEmailConfirm: confirmEmailValidator(
      {
        required: getMessageText('error', 'MSG006'),
        wrongFormat: getMessageText('error', 'MSG007'),
        doNotMatch: getMessageText('error', 'MSG052'),
      },
      'newEmail'
    ),
  });

  const isSubmitButtonDisabled = (values: ICsrChangeEmailFormValues) =>
    Object.values(values).some((value) => value === '');

  const handleSubmit = (values: ICsrChangeEmailFormValues, { resetForm }: FormikHelpers<ICsrChangeEmailFormValues>) => {
    setSubmitted(true);
    dispatch(
      changeCustomerEmail.request<IChangeEmailPayload>({
        accountId: accountAtLocation.id,
        newEmail: values.newEmail,
        onSuccessCallback: () => {
          resetForm();
          dispatch(setHasUnsavedChanges(false));
          toggleChangeEmailActive(false);
          toggleEmailChangedSuccessfully(true);
          dispatch(
            confirmCsrAccountUpdate(locationId, accountAtLocation.id, isAccountNumberSearch, {
              email: values.newEmail,
              accountStatus: AccountStatuses.UNVERIFIED,
              isVerified: false,
            })
          );
          dispatch(
            confirmCsrAccountUpdateByLocation(
              locationId,
              accountAtLocation.id,
              {
                email: values.newEmail,
                accountStatus: AccountStatuses.UNVERIFIED,
                isVerified: false,
              },
              grouping
            )
          );
        },
      })
    );
  };

  const validateForm = (values: ICsrChangeEmailFormValues) => {
    validateFormik(values, ValidationSchema, setHasErrors);
  };

  const onSubmitClick = (values: ICsrChangeEmailFormValues) => () => {
    setSubmitted(true);
    validateForm(values);
  };

  const handleResendEmailVerification = () => {
    dispatch(
      resendVerification.request<IResendVerificationPayload>({ accountId: accountAtLocation.id })
    );
  };

  const clearErrors = useCallback(() => {
    setSubmitted(false);
    if (hasServerError) {
      dispatch(clearCsrFormServerErrors());
    }
  }, [dispatch, hasServerError]);

  const closeEditEmailSection = () => {
    toggleChangeEmailActive(false);
    setLocationInEditMode && setLocationInEditMode();
    reopenCsrEditUserInfoModal && reopenCsrEditUserInfoModal();
    dispatch(setHasUnsavedChanges(false));
  };

  useEffect(() => {
    return () => {
      if (hasServerError) {
        dispatch(clearCsrFormServerErrors());
      }
    };
  }, [dispatch, hasServerError]);

  useEffect(() => {
    toggleEmailChangedSuccessfully(false);
  }, [accountAtLocation.id]);

  useEffect(() => {
    if (isVerificationEmailSent) {
      showResendVerificationEmailSuccessMessageModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerificationEmailSent]);

  return (
    <div
      className={classNames('change-email-address-form', {
        'change-email-address-form--updated-successfully': emailChangedSuccessfully,
        'change-email-address-form--invalid': hasServerError,
      })}
    >
      <Formik
        validateOnChange={false}
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ values, setFieldValue, dirty, errors, setErrors, resetForm }) => (
          <>
            {((submitted && hasErrors) || hasServerError) && (
              <ErrorNotification
                message={clientErrorMessage}
                messageId={serverErrorId}
                interpolateParams={{ email: values.newEmail }}
              />
            )}
            <Form
              onChange={(e: SyntheticEvent) => {
                dispatch(setHasUnsavedChanges(true));
                clearErrors();
                setErrors(filterFormikErrors(errors, (e.target as HTMLInputElement).name));
              }}
            >
              <>
                <p className="change-email-address-form__current-email-label">
                  <ContentstackText contentKey={`${contentstackPath}.current_email_label`} />
                </p>
                <p className="change-email-address-form__current-email">{accountAtLocation.email}</p>
                {changeEmailActive ? (
                  <CorButton
                    className="change-email-address-form__change-email-button"
                    color="text"
                    onClick={() => {
                      if (dirty) {
                        const transitionIsConfirmed = window.confirm(leavingPageModalText);

                        if (transitionIsConfirmed) {
                          closeEditEmailSection();
                          resetForm();
                        }

                        return;
                      }
                      closeEditEmailSection();
                    }}
                  >
                    <img src={iconCrossRed} className="change-email-address-form__discard-icon" alt="cross icon" />
                    <ContentstackText contentKey={`${contentstackPath}.discard_changes_button_label`} />
                  </CorButton>
                ) : (
                  <CorButton
                    className="change-email-address-form__change-email-button"
                    color="text"
                    onClick={() => toggleChangeEmailActive(true)}
                  >
                    <ContentstackText contentKey={`${contentstackPath}.change_email_button_label`} />
                  </CorButton>
                )}
              </>
              {emailChangedSuccessfully && (
                <p className="change-email-address-form__success-message">
                  <img
                    src={iconSuccess}
                    className="change-email-address-form__success-message-icon"
                    alt="success icon"
                  />
                  <ContentstackMessage type="success" messageId="MSG054" />
                </p>
              )}
              {changeEmailActive && (
                <div className="change-email-address-form__fields grid-x">
                  <TextField
                    id="newEmail"
                    name="newEmail"
                    label={getContentByKey<string>(`${contentstackPath}.new_email_field_label`, '')}
                    onFocus={() => {
                      const { newEmail, ...restErrors } = errors;
                      if (newEmail) {
                        clearErrors();
                        setFieldValue('newEmail', '');
                        setErrors(restErrors);
                      }
                    }}
                  />
                  <TextField
                    id="newEmailConfirm"
                    name="newEmailConfirm"
                    label={getContentByKey<string>(`${contentstackPath}.confirm_new_email_field_label`, '')}
                    onFocus={() => {
                      const { newEmailConfirm, ...restErrors } = errors;
                      if (newEmailConfirm) {
                        clearErrors();
                        setFieldValue('newEmailConfirm', '');
                        setErrors(restErrors);
                      }
                    }}
                  />
                </div>
              )}
              <div className="change-email-address-form__spacer" />
              <div className="change-email-address-form__buttons grid-x align-end">
                <SubmitButton
                  isDisabled={isSubmitting || isSubmitButtonDisabled(values)}
                  className="medium-auto cell change-email-address-form__buttons-btn"
                  onClick={onSubmitClick(values)}
                >
                  <ContentstackText contentKey={`${contentstackEditModalPath}.save_changes_button_label`} />
                </SubmitButton>
                {accountAtLocation.accountStatus === AccountStatuses.UNVERIFIED && (
                  <CorButton
                    color="secondary"
                    className="cell medium-auto change-email-address-form__buttons-resend-email-btn change-email-address-form__buttons-btn"
                    onClick={handleResendEmailVerification}
                  >
                    <img src={iconPaperPlane} alt="paper plane" className="change-email-address-form__paper_plane" />
                    <ContentstackMessage type="tooltips" messageId="MSG122" />
                  </CorButton>
                )}
                <CorButton
                  color="secondary"
                  className="cell medium-auto change-email-address-form__buttons-btn"
                  onClick={onCancel}
                >
                  <ContentstackText contentKey={`${contentstackEditModalPath}.cancel_button_label`} />
                </CorButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
