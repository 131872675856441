import qs from 'qs';
import { takeLatest, put, select } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import {
  getCartInfo,
  getCartInfoActionConstants,
  setCartMessageViewedActionConstants,
} from 'store/shopping-cart/actions';
import { selectIsInvoiceOnlyCustomer, selectIsTsrUser } from 'store/auth/selectors';
import { IAction } from 'types/actions';
import { isShopLocaleInternational } from 'utils/is-shop-locale-international';

export interface IGetCartInfoPayload {
  fullValidation?: boolean;
}

export function* getCartInfoSagaHandler(args: IGetCartInfoPayload, { payload }: IAction<IGetCartInfoPayload>) {
  const isTsrUser = yield select(selectIsTsrUser);
  const isInvoiceOnlyUser = yield select(selectIsInvoiceOnlyCustomer);

  if (!isTsrUser && !isInvoiceOnlyUser && !isShopLocaleInternational()) {
    yield put(
      doRequest(
        getCartInfo,
        `${Endpoints.CART}?${qs.stringify(
          {
            'full-validation': payload?.fullValidation ?? args?.fullValidation ?? null,
          },
          { skipNulls: true }
        )}`,
        'get'
      )
    );
  }
}

export default function* getInfoSaga() {
  yield takeLatest(getCartInfoActionConstants[Actions.REQUEST], getCartInfoSagaHandler, {});
  yield takeLatest(setCartMessageViewedActionConstants[Actions.SUCCESS], getCartInfoSagaHandler, {
    fullValidation: true,
  });
}
