import { createReducer } from 'store/reducer-creator';
import { Actions } from 'constants/actions.enum';
import { IInvoiceItem } from 'types/invoices';
import * as actions from './actions';

export interface IPaymentDetails {
  accountHolderType: string;
  accountNumber: string;
  cardTypeOrBank: string;
  creditCardSurcharge: number;
  creditMemosApplied: number;
  datePaid: string;
  invoices: IInvoiceItem[];
  paidAmount: number;
  subtotal: number;
  transactionNumber: string;
  status: string;
  depositComment?: string;
  paidByAutoPay?: boolean;
}

export interface IPaymentTransactionDetailsState {
  paymentDetails: IPaymentDetails;
}

export const paymentDetailsInitialState: IPaymentDetails = {
  accountHolderType: '',
  accountNumber: '',
  cardTypeOrBank: '',
  creditCardSurcharge: 0,
  creditMemosApplied: 0,
  datePaid: '',
  invoices: [],
  paidAmount: 0,
  subtotal: 0,
  transactionNumber: '',
  status: '',
  paidByAutoPay: false,
};

export const paymentTransactionDetailsInitialState: IPaymentTransactionDetailsState = {
  paymentDetails: paymentDetailsInitialState,
};

const paymentDetailsReducer = createReducer<IPaymentTransactionDetailsState>(
  {
    [actions.getPaymentDetailsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      paymentDetails: { ...paymentDetailsInitialState },
    }),
    [actions.getPaymentDetailsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      paymentDetails: {
        ...payload,
        creditMemosApplied: payload.creditMemosApplied ?? 0,
        datePaid: payload.datePaid?.slice(0, 10) ?? '',
        creditCardSurcharge: payload?.creditCardSurcharge ?? 0,
      },
    }),
  },
  paymentTransactionDetailsInitialState
);

export default paymentDetailsReducer;
