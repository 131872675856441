import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import classNames from 'classnames';
import { AutoplayOptions } from 'swiper/types';
import { ICarouselProduct } from 'types/product';
import { SkuProductCard } from 'components/product-card/sku-product-card';
import { useBreakpoint } from 'hooks/use-breakpoint';
import {
  useHideGlobalModalHandler,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';

import './products-carousel.scss';

const DESKTOP_SLIDES_COUNT = 4;
const MOBILE_SLIDES_COUNT = 2;
const SINGLE_SLIDE_COUNT = 1;

interface IProductsCarousel {
  title?: string;
  subtitle?: string;
  products: ICarouselProduct[];
  id?: string;
  className?: string;
  usageContext?: string;
  autoplay?: boolean;
  autoplaySpeed?: number;
  contentStackPath?: string;
  minimumShowSlidesCount?: number;
  loop?: boolean;
  rewind?: boolean;
}

export const ProductsCarousel: FC<IProductsCarousel> = ({
  title,
  subtitle,
  products,
  id,
  className,
  usageContext,
  autoplay = false,
  autoplaySpeed = 2000,
  loop = true,
  rewind = false,
  contentStackPath,
  minimumShowSlidesCount,
}) => {
  const { isDesktop } = useBreakpoint();

  const showRequestQuoteSuccessModal = useRequestQuoteSuccessMessageModal();
  const hideGlobalModal = useHideGlobalModalHandler();

  const showRequestQuoteModal = useRequestQuoteModal({
    requestQuoteSuccessCallBack: () => showRequestQuoteSuccessModal(),
    requestQuoteFailCallBack: hideGlobalModal,
  });

  const autoplayConfig: AutoplayOptions = {
    delay: autoplaySpeed,
    disableOnInteraction: false,
  };

  const onRequestQuoteCtaClick = (skuKey: string) => () => showRequestQuoteModal({ skuKey });

  const showCarousel = minimumShowSlidesCount
    ? products.length >= minimumShowSlidesCount
    : isDesktop
    ? products.length >= DESKTOP_SLIDES_COUNT
    : products.length >= MOBILE_SLIDES_COUNT;

  return showCarousel ? (
    <div id={id} className={classNames('products-carousel', className)}>
      {title && (
        <header>
          <h2>{title}</h2>
          {subtitle && <h3>{subtitle}</h3>}
        </header>
      )}
      <Swiper
        className="products-carousel__slider"
        modules={[Pagination, Navigation, Autoplay]}
        pagination={{
          clickable: true,
        }}
        speed={500}
        breakpoints={{
          0: {
            slidesPerView: products.length >= MOBILE_SLIDES_COUNT ? MOBILE_SLIDES_COUNT : products.length,
            slidesPerGroup: MOBILE_SLIDES_COUNT,
            allowTouchMove: true,
          },
          960: {
            slidesPerView: products.length >= DESKTOP_SLIDES_COUNT ? DESKTOP_SLIDES_COUNT : products.length,
            slidesPerGroup: DESKTOP_SLIDES_COUNT,
          },
        }}
        autoplay={autoplay && autoplayConfig}
        navigation={products.length > DESKTOP_SLIDES_COUNT}
        loop={loop}
        rewind={rewind}
      >
        {products.map((product: ICarouselProduct) => (
          <SwiperSlide className="products-carousel__item-wrapper" key={product.sku}>
            <div
              className={classNames('products-carousel__item', {
                'products-carousel__item-single': isDesktop && products.length === SINGLE_SLIDE_COUNT,
              })}
            >
              <SkuProductCard
                {...product}
                overrideTooltipPosition={false}
                usageContext={usageContext}
                onRequestQuoteCtaClick={onRequestQuoteCtaClick(product.sku)}
                contentStackPath={contentStackPath}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};
