import './cor-expandable-section.scss';

import { FC, ReactNode, useCallback, useState, ReactElement, PropsWithChildren, useEffect } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { COR_NAV_ORANGE } from 'corporate-ui/cor-constants';
import { IIconWithHoverProps } from 'components/icon-with-hover';
import { IconMenuArrow } from 'components/icons/icon-menu-arrow';

export interface ICorExpandableSectionProps extends PropsWithChildren {
  title?: string | ReactNode;
  forceIsExpanded?: boolean;
  withoutAnimation?: boolean;
  className?: string;
  unmountOnExit?: boolean;
  expandableIcon?: ReactElement<IIconWithHoverProps>;
  onExpandStatusChange?: (status: boolean) => void;
}

export const CorExpandableSection: FC<ICorExpandableSectionProps> = ({
  title,
  forceIsExpanded = false,
  withoutAnimation = false,
  children,
  className = '',
  unmountOnExit = true,
  expandableIcon,
  onExpandStatusChange,
}) => {
  const [isOpened, setIsOpened] = useState(forceIsExpanded);

  const onTitleClick = useCallback(() => {
    setIsOpened(!isOpened);
    onExpandStatusChange?.(!isOpened);
  }, [isOpened, onExpandStatusChange]);

  const expandableSectionClassName = classNames('cor-expandable-section', {
    'cor-expandable-section--expanded': isOpened,
    'cor-expandable-section--without-animation': withoutAnimation,
    [className]: !!className,
  });

  useEffect(() => {
    setIsOpened(forceIsExpanded);
  }, [forceIsExpanded]);

  return (
    <div className={expandableSectionClassName}>
      <div className="cor-expandable-section__title" onClick={onTitleClick}>
        {title && <div data-testid="cor-expandable-section__title">{title}</div>}
        {expandableIcon ?? <IconMenuArrow dir={isOpened ? 'up' : 'right'} color={COR_NAV_ORANGE} width={6} />}
      </div>
      <CSSTransition
        in={isOpened}
        timeout={withoutAnimation ? 0 : 200}
        classNames="cor-expandable-section__inner"
        unmountOnExit={unmountOnExit}
      >
        <div className="cor-expandable-section__inner">{children}</div>
      </CSSTransition>
    </div>
  );
};
