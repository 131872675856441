import { all, spawn } from 'redux-saga/effects';
import getSkuReplacementsSaga from './get-sku-replacements';
import getDiscontinuedItemsReplacementsSaga from './get-discontinued-items-replacements';
import getProductReplacementSkuSubtotalSaga from './get-product-replacement-sku-subtotal';

export default function* skuReplacementsSagas() {
  yield all([
    spawn(getSkuReplacementsSaga),
    spawn(getDiscontinuedItemsReplacementsSaga),
    spawn(getProductReplacementSkuSubtotalSaga),
  ]);
}
