import { combineReducers } from 'redux';
import { Actions } from 'constants/actions.enum';
import {
  IAccountRetrievalInfo,
  ICustomerProfile,
  IFormWithServerErrors,
  IResendVerificationInfo,
} from 'store/customer-registration/actions';
import { createReducer } from 'store/reducer-creator';
import * as actions from './actions';
import types from './action-types';

export interface ICustomerRegistrationState {
  activeStep: number;
  accountRetrievalForm: IFormWithServerErrors;
  validateWithoutInvoiceForm: IFormWithServerErrors;
  accountRetrieval: IAccountRetrievalInfo;
  personalInformationForm: IFormWithServerErrors;
  customerProfile: ICustomerProfile | null;
  isVerifiedToken: boolean;
  preregisteredEmailFromToken: string | null;
  resendVerification: IResendVerificationInfo;
  invalidTokenError: string | null;
}

const formWithServerErrorsInitialState = {
  isSubmitting: false,
  serverErrorId: '',
  isDirty: false,
};

const accountRetrievalInfoInitialState = {
  userId: '',
  locations: [],
  salesOrg: '',
};

const resendVerificationInitialState = {
  accountId: '',
  isVerificationEmailSent: false,
  resendVerificationError: '',
};

export const customerRegistrationInitialState: ICustomerRegistrationState = {
  activeStep: 0,
  accountRetrievalForm: formWithServerErrorsInitialState,
  validateWithoutInvoiceForm: formWithServerErrorsInitialState,
  accountRetrieval: accountRetrievalInfoInitialState,
  personalInformationForm: formWithServerErrorsInitialState,
  customerProfile: null,
  isVerifiedToken: false,
  preregisteredEmailFromToken: null,
  resendVerification: resendVerificationInitialState,
  invalidTokenError: null,
};

const activeStep = createReducer<number>(
  {
    [types.SET_ACTIVE_STEP]: (state, { payload }) => payload.activeStep,
    [actions.submitAccountRetrievalActionConstants[Actions.SUCCESS]]: (state) => state + 1,
    [actions.validationWithCustomerNumberAndZipCodeActionConstants[Actions.SUCCESS]]: (state) => state + 1,
  },
  0
);

export const accountRetrievalForm = createReducer<IFormWithServerErrors>(
  {
    [actions.submitAccountRetrievalActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isSubmitting: true,
      serverErrorId: '',
    }),
    [types.SET_ACCOUNT_RETRIEVAL_FORM_DIRTY]: (state, { payload }) => ({
      ...state,
      isDirty: payload.isDirty,
    }),
    [actions.submitAccountRetrievalActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
    }),
    [types.CLEAR_SERVER_ERRORS]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
      errorMessages: [],
    }),
    [actions.submitAccountRetrievalActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: error.errorMessageId || '',
      errorMessages: error.errorMessages,
    }),
  },
  formWithServerErrorsInitialState
);

export const validateWithoutInvoiceForm = createReducer<IFormWithServerErrors>(
  {
    [actions.validationWithCustomerNumberAndZipCodeActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isSubmitting: true,
      serverErrorId: '',
    }),
    [actions.validationWithCustomerNumberAndZipCodeActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
    }),
    [actions.validationWithCustomerNumberAndZipCodeActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: error.errorMessageId || '',
    }),
    [types.SET_VALIDATE_WITHOUT_INVOICE_FORM_DIRTY]: (state, { payload }) => ({
      ...state,
      isDirty: payload.isDirty,
    }),
    [types.CLEAR_SERVER_ERRORS]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
    }),
  },
  formWithServerErrorsInitialState
);

const accountRetrieval = createReducer<IAccountRetrievalInfo>(
  {
    [actions.submitAccountRetrievalActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      userId: payload.userId,
      locations: payload.locations,
      salesOrg: payload.salesOrg,
    }),
    [actions.validationWithCustomerNumberAndZipCodeActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      userId: payload.userId,
      locations: payload.locations,
      salesOrg: payload.salesOrg,
    }),
  },
  accountRetrievalInfoInitialState
);

export const personalInformationForm = createReducer<IFormWithServerErrors>(
  {
    [actions.submitPersonalInformationActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isSubmitting: true,
      serverErrorId: '',
    }),
    [actions.submitPersonalInformationActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
    }),
    [types.SET_PERSONAL_INFO_FORM_DIRTY]: (state, { payload }) => ({
      ...state,
      isDirty: payload.isDirty,
    }),
    [types.CLEAR_SERVER_ERRORS]: (state) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: '',
    }),
    [actions.submitPersonalInformationActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      isSubmitting: false,
      serverErrorId: error.errorMessageId || '',
    }),
  },
  formWithServerErrorsInitialState
);

export const resendVerification = createReducer<IResendVerificationInfo>(
  {
    [actions.resendVerificationActionConstants[Actions.REQUEST]]: (state, { payload }) => ({
      ...state,
      accountId: payload.accountId,
    }),
    [actions.resendVerificationActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isVerificationEmailSent: true,
    }),
    [actions.resendEmailToVerifyAccountActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isVerificationEmailSent: true,
    }),
    [actions.resendEmailToVerifyAccountActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      isVerificationEmailSent: false,
      resendVerificationError: error.errorMessageId || '',
    }),
    [types.RESET_VERIFICATION_EMAIL_SEND_STATUS]: (state) => ({
      ...state,
      isVerificationEmailSent: false,
    }),
    [types.CLEAR_RESEND_VERIFICATION_ERROR]: (state) => ({
      ...state,
      isVerificationEmailSent: false,
      resendVerificationError: '',
    }),
  },
  resendVerificationInitialState
);

const customerProfile = createReducer<ICustomerProfile | null>(
  {
    [actions.submitPersonalInformationActionConstants[Actions.SUCCESS]]: (state, { payload }) => payload,
    [types.CLEAR_CUSTOMER_PROFILE]: () => null,
  },
  null
);

const isVerifiedToken = createReducer<boolean>(
  {
    [actions.verifyEmailByTokenActionConstants[Actions.SUCCESS]]: () => true,
    [actions.verifyEmailByTokenActionConstants[Actions.FAIL]]: () => false,
    [actions.submitAdditionalUserRegistrationActionConstants[Actions.SUCCESS]]: () => true,
    [actions.submitAdditionalUserRegistrationActionConstants[Actions.FAIL]]: () => false,
  },
  false
);

const preregisteredEmailFromToken = createReducer<string | null>(
  {
    [actions.validateRegistrationTokenActionConstants[Actions.SUCCESS]]: (state, { payload }) => payload.email || null,
    [actions.validateRegistrationTokenActionConstants[Actions.FAIL]]: () => null,
  },
  null
);

const invalidTokenError = createReducer<string | null>(
  {
    [types.SET_INVALID_TOKEN_ERROR]: (state, { payload }) => payload.error,
  },
  null
);

export default combineReducers({
  activeStep,
  accountRetrievalForm,
  validateWithoutInvoiceForm,
  accountRetrieval,
  personalInformationForm,
  customerProfile,
  isVerifiedToken,
  preregisteredEmailFromToken,
  resendVerification,
  invalidTokenError,
});
