import { all, spawn } from 'redux-saga/effects';
import getRecentOrdersInfoSaga from './get-recent-orders';
import getRecentlyViewedItemsSaga from './get-recently-viewed-items';
import getBuyItAgainProductsSaga from './get-buy-it-again-section-products';
import getHomeShoppingListsSaga from './get-home-shopping-lists';
import getBuyItAgainSkuSubtotalSaga from './get-buy-it-again-sku-subtotal';

export default function* homeSagas() {
  yield all([
    spawn(getRecentOrdersInfoSaga),
    spawn(getRecentlyViewedItemsSaga),
    spawn(getBuyItAgainProductsSaga),
    spawn(getHomeShoppingListsSaga),
    spawn(getBuyItAgainSkuSubtotalSaga),
  ]);
}
