import './news.page.scss';

import { FC, PropsWithChildren } from 'react';
import { CorDetailHeroBanner, CorDetailRelatedContent } from 'corporate-components';
import { ICSCorImage } from 'corporate-interfaces';
import { CorArticleSchema } from 'corporate-components/cor-pages/corporate/pages/components/cor-article-schema';
import { useContent } from 'hooks/use-content';
import { CorFeaturedContentContentTypeEnum } from 'constants/cor-featured-content.enum';

export const NewsPage: FC<PropsWithChildren> = ({ children }) => {
  const { getContentByKey } = useContent();
  const heading = getContentByKey('title', '');
  const tags = getContentByKey('superheading', '');
  const shortDescription = getContentByKey('short_description', '');
  const bannerImage = getContentByKey('banner_image', {} as ICSCorImage);
  const dateAuthor = getContentByKey('date_and_author', '');
  const iconTitleGroup = getContentByKey('icon_title_group', []);

  return (
    <>
      <CorArticleSchema
        title={heading}
        shortDescription={shortDescription}
        image={bannerImage}
        publicationDate={dateAuthor}
      />

      <CorDetailHeroBanner
        heading={heading}
        tags={tags}
        description={shortDescription}
        image={bannerImage}
        dateAuthor={dateAuthor}
        iconTitleGroup={iconTitleGroup}
      />
      {children || null}

      <CorDetailRelatedContent
        baseKey="related_news_section"
        relatedContentKey="related_news_section.related_news"
        contentType={CorFeaturedContentContentTypeEnum.News}
        className="cor-detail-related-content__news"
        titleExclusion={heading}
      />
    </>
  );
};
