import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ProductImage } from 'components/product-image';
import { selectProductSelectedSkuInfo } from 'store/product-details/selectors';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { ModalHeader } from 'components/modals/common/modal/components';

import './product-zoom-gallery-modal.scss';

export interface IProductZoomGalleryModalProps extends IModalProps {
  activeImage: number;
  setActiveImage: (activeImage: number) => void;
}

const zoomedImageMobileSize = { width: 1400, height: 1400 };
const mainImageDesktopSize = { width: 560, height: 560 };
const thumbnailMobileSize = { width: 80, height: 80 };
const thumbnailDesktopSize = { width: 104, height: 104 };

export const ProductZoomGalleryModal: FC<IProductZoomGalleryModalProps> = ({
  isOpen,
  onClose,
  activeImage,
  setActiveImage,
}) => {
  const selectedSkuInfo = useSelector(selectProductSelectedSkuInfo);
  const hasImages = selectedSkuInfo && selectedSkuInfo.imageUrls.length > 1;
  const { isMobile } = useBreakpoint();
  const [activeZoomImage, setActiveZoomImage] = useState(activeImage);

  useEffect(() => {
    setActiveZoomImage(activeImage);
  }, [activeImage]);

  const updateActiveImage = (index: number) => {
    setActiveZoomImage(index);
    setActiveImage(index);
  };

  const renderThumbnail = (src: string) => (
    <ProductImage
      src={src}
      altText={selectedSkuInfo?.name}
      mobileSize={thumbnailMobileSize}
      desktopSize={thumbnailDesktopSize}
      withLoading
    />
  );

  return (
    <Modal isOpen={isOpen} className="product-zoom-gallery-modal" size="medium_large" onClose={onClose}>
      <ModalHeader className="product-zoom-gallery-modal__header">
        <p className="product-zoom-gallery-modal__brand">{selectedSkuInfo?.brand}</p>
        <p className="product-zoom-gallery-modal__name">{selectedSkuInfo?.name}</p>
      </ModalHeader>
      <div className="product-zoom-gallery-modal__gallery">
        {!isMobile && hasImages && (
          <div className="product-zoom-gallery-modal__items-wrapper">
            <div className="product-zoom-gallery-modal__items">
              {selectedSkuInfo.imageUrls.map((image, index) => (
                <div className="product-zoom-gallery-modal__item" key={index} onClick={() => updateActiveImage(index)}>
                  <div
                    className={classNames('product-zoom-gallery-modal__thumbnail', {
                      'product-zoom-gallery-modal__thumbnail--active': index === activeZoomImage,
                    })}
                  >
                    {renderThumbnail(image)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="product-zoom-gallery-modal__main-image">
          <ProductImage
            src={selectedSkuInfo?.imageUrls[activeZoomImage]}
            altText={selectedSkuInfo?.name}
            zoomedMobileSize={zoomedImageMobileSize}
            desktopSize={mainImageDesktopSize}
            withLoading
          />
        </div>
        {isMobile && hasImages && (
          <Swiper
            scrollbar={{ draggable: true }}
            speed={300}
            className="product-zoom-gallery-modal__slider"
            slidesPerView="auto"
            spaceBetween={isMobile ? 12 : 16}
            allowTouchMove
          >
            {selectedSkuInfo.imageUrls.map((image, index) => (
              <SwiperSlide
                className={classNames('product-zoom-gallery-modal__thumbnail', {
                  'product-zoom-gallery-modal__thumbnail--active': index === activeZoomImage,
                })}
                key={index}
                onClick={() => updateActiveImage(index)}
              >
                {renderThumbnail(image)}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </Modal>
  );
};
