enum shoppingCartActionTypes {
  GET_CART_INFO = 'GET_CART_INFO',
  SET_CART_MESSAGE_VIEWED = 'SET_CART_MESSAGE_VIEWED',
  ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART',
  ADD_MULTIPLE_PRODUCTS_TO_CART = 'ADD_MULTIPLE_PRODUCTS_TO_CART',
  CHANGE_CART_ITEM_QUANTITY = 'CHANGE_CART_ITEM_QUANTITY',
  SET_CART_ITEM_QUANTITY = 'SET_CART_ITEM_QUANTITY',
  DELETE_CART_ITEM = 'DELETE_CART_ITEM',
  DELETE_ALL_CART_ITEMS = 'DELETE_ALL_CART_ITEMS',
  REORDER_PRODUCTS = 'REORDER_PRODUCTS',
  CLEAR_ORIGINAL_TO_REPLACEMENT_SKU_MAP = 'CLEAR_ORIGINAL_TO_REPLACEMENT_SKU_MAP',
  SET_REORDER_ORIGINAL_TO_REPLACEMENT_SKU_MAP = 'SET_REORDER_ORIGINAL_TO_REPLACEMENT_SKU_MAP',
  GET_PRODUCT_RECOMMENDATIONS = 'GET_PRODUCT_RECOMMENDATIONS',
  GET_PROMOTION_COUPON_CODE = 'GET_PROMOTION_COUPON_CODE',
  DELETE_PROMOTION_COUPON_CODE = 'DELETE_PROMOTION_COUPON_CODE',
  SET_IS_PROMOTIONAL_CODE_VALID = 'SET_IS_PROMOTIONAL_CODE_VALID',
  RESET_PROMOTIONAL = 'RESET_PROMOTIONAL',
  CLEAR_IS_BANNER_SHOWN_IF_PROMO_DOESNOT_MEET_CRITERIA = 'CLEAR_SHOULD_SHOW_INFO_BANNER',
}

export default shoppingCartActionTypes;
