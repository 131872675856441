enum locationsActionsTypes {
  GET_LOCATIONS_BY_NAME = 'GET_LOCATIONS_BY_NAME',
  GET_LOCATIONS_BY_DAY_OF_WEEK = 'GET_LOCATIONS_BY_DAY_OF_WEEK',
  GET_LOCATIONS_BY_RECENT_ORDERS = 'GET_LOCATIONS_BY_RECENT_ORDERS',
  DASHBOARD_MSR_PAGINATION_CHANGE = 'DASHBOARD_MSR_PAGINATION_CHANGE',
  GET_LOCATIONS_BY_ACCOUNT_NUMBER = 'GET_LOCATIONS_BY_ACCOUNT_NUMBER',
  GET_LOCATIONS_FOR_MSR = 'GET_LOCATIONS_FOR_MSR',
  GET_MSR_LIST_BY_NAME = 'GET_MSR_LIST_BY_NAME',
  LOCATIONS_BY_MSR_PAGINATION_CHANGE = 'LOCATIONS_BY_MSR_PAGINATION_CHANGE',
  LOCATIONS_BY_ACCOUNT_NUMBER_PAGINATION_CHANGE = 'LOCATIONS_BY_ACCOUNT_NUMBER_PAGINATION_CHANGE',
  LOCATIONS_BY_RECENT_ORDERS_PAGINATION_CHANGE = 'LOCATIONS_BY_RECENT_ORDERS_PAGINATION_CHANGE',
  CLEAR_CSR_LOCATIONS = 'CLEAR_CSR_LOCATIONS',
  SET_ACTIVE_MSM_VIEW = 'SET_ACTIVE_MSM_VIEW',
  GET_LOCATIONS_FOR_MSM = 'GET_LOCATIONS_FOR_MSM',
  UNLOCK_CSR_CUSTOMER_ACCOUNT = 'UNLOCK_CSR_CUSTOMER_ACCOUNT',
  CHANGE_CSR_CUSTOMER_EMAIL = 'CHANGE_CSR_CUSTOMER_EMAIL',
  CHANGE_CSR_CUSTOMER_PASSWORD = 'CHANGE_CSR_CUSTOMER_PASSWORD',
  CLEAR_CSR_FORM_SERVER_ERRORS = 'CLEAR_CSR_FORM_SERVER_ERRORS',
  CONFIRM_CSR_ACCOUNT_UPDATE = 'CONFIRM_CSR_ACCOUNT_UPDATE',
  CONFIRM_CSR_ACCOUNT_UPDATE_BY_LOCATION = 'CONFIRM_CSR_ACCOUNT_UPDATE_BY_LOCATION',
  UPDATE_ACCOUNT_FOR_ALL_LOADED_LOCATIONS = 'UPDATE_ACCOUNT_FOR_ALL_LOADED_LOCATIONS',
  SET_HAS_UNSAVED_CHANGES = 'SET_HAS_UNSAVED_CHANGES',
  GET_MSR_LIST_BY_NAME_MSM = 'GET_MSR_LIST_BY_NAME_MSM',
  GET_MSR_LOCATIONS_CART_STATUS_AND_ORDERS_FOR_MSM = 'GET_MSR_LOCATIONS_CART_STATUS_AND_ORDERS_FOR_MSM',
  GET_LOCATIONS_BY_MSR_ID_MSM = 'GET_LOCATIONS_BY_MSR_ID_MSM',
  GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_NAME = 'GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_NAME',
  GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_DAY_OF_WEEK = 'GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_DAY_OF_WEEK',
  GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_RECENT_ORDERS = 'GET_CSR_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_RECENT_ORDERS',
  SET_ACTIVE_CSR_WITH_ASSIGNED_ACCOUNTS_VIEW = 'SET_ACTIVE_CSR_WITH_ASSIGNED_ACCOUNTS_VIEW',
  SET_CURRENT_SORTING_OPTION_CSR_WITH_ASSIGNED_ACCOUNTS_VIEW = 'SET_CURRENT_SORTING_OPTION_CSR_WITH_ASSIGNED_ACCOUNTS_VIEW',
  CSR_WITH_ASSIGNED_ACCOUNTS_PAGINATION_CHANGE = 'CSR_WITH_ASSIGNED_ACCOUNTS_PAGINATION_CHANGE',
  GET_LOCATIONS_BY_NAME_FOR_CSR = 'GET_LOCATIONS_BY_NAME_FOR_CSR',
  GET_LOCATIONS_BY_DAY_OF_WEEK_FOR_CSR = 'GET_LOCATIONS_BY_DAY_OF_WEEK_FOR_CSR',
  GET_LOCATIONS_BY_RECENT_ORDERS_FOR_CSR = 'GET_LOCATIONS_BY_RECENT_ORDERS_FOR_CSR',
  CSR_PAGINATION_CHANGE = 'CSR_PAGINATION_CHANGE',
  SET_ALL_CUSTOMERS_SEARCH_VALUES = 'SET_ALL_CUSTOMERS_SEARCH_VALUES',
}

export default locationsActionsTypes;
