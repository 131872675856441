import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getSaveForLaterItemsActionConstants = createConstants(types.GET_SAVE_FOR_LATER_ITEMS);
export const deleteSaveForLaterItemActionConstants = createConstants(types.DELETE_SAVE_FOR_LATER);
export const addSaveForLaterItemsActionConstants = createConstants(types.ADD_SAVE_FOR_LATER_ITEMS);

export const getSaveForLaterItems = createRequestActions(getSaveForLaterItemsActionConstants);
export const deleteSaveForLaterItem = createRequestActions(deleteSaveForLaterItemActionConstants);

export const deleteSaveForLaterItemFromStore = (skuNumber: string) =>
  action(types.DELETE_SAVE_FOR_LATER_ITEM_FROM_STORE, {
    skuNumber,
  });
export const clearSaveForLater = () => action(types.CLEAR_SAVE_FOR_LATER);
export const addSaveForLaterItems = createRequestActions(addSaveForLaterItemsActionConstants);
