import { FC } from 'react';
import { get, template } from 'lodash-es';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';

export interface IContentstackHtmlComponentProps {
  contentKey?: string;
  htmlContent?: string;
  component?: keyof JSX.IntrinsicElements;
  interpolateParams?: object;
}

export const ContentstackHtml: FC<IContentstackHtmlComponentProps> = ({
  contentKey,
  component = 'div',
  interpolateParams = {},
  htmlContent,
}) => {
  const content = useContentstackContent();
  let html = htmlContent ?? get(content, contentKey, '');

  html = [
    ['&lt;%', '<%'],
    ['%&gt;', '%>'],
    [
      '(.*src=")(.*\\/\\/images.contentstack.io\\/v3\\/assets.*?)(".*)',
      `$1$2?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}$3`,
    ],
  ].reduce((acc, replacementPair) => acc.replace(new RegExp(replacementPair[0], 'g'), replacementPair[1]), html);

  const Tag = component;
  const compiled = template(html);
  return (
    <Tag
      dangerouslySetInnerHTML={{
        __html: compiled(interpolateParams),
      }}
    />
  );
};
