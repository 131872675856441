import { useEffect } from 'react';

export const useBodyScrollLock = (flag: boolean = true) => {
  useEffect(() => {
    const notScrollableClassName = 'not-scrollable';
    document.body.classList[flag ? 'add' : 'remove'](notScrollableClassName);
    document.body.style.overflow = flag ? 'hidden' : 'auto';

    return () => {
      document.body.classList.remove(notScrollableClassName);
      document.body.style.overflow = 'auto';
    };
  }, [flag]);
};
