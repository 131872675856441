import React, { FC, HTMLAttributes } from 'react';
import { history } from 'store';
import { get } from 'lodash-es';
import { Link } from 'react-router-dom';
import { useContentstackContent } from 'hooks/use-contentstack-content';
import { isExternalUrl } from 'utils/is-external-url';
import { Routes } from 'constants/routes.enum';
import { useLocalStorage } from 'hooks/use-local-storage';
import { CorContentStackLanguageURLMap } from 'constants/cor-locale.enum';
import { isInternationalSite } from 'utils/get-locale-params';

interface ICorContentstackLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  contentKey?: string;
  opensInNewTab?: boolean;
  fallbackElement?: string;
  disabled?: boolean;
  data?: ICorContentstackLink;
}

export interface ICorContentstackLink {
  href?: string;
  title?: string;
}

const hasQueryParams = (relativePath = '') => {
  const url = new URL(relativePath, window.location.origin);
  return !!url.search;
};

export const CorContentstackLink: FC<ICorContentstackLinkProps> = (props) => {
  const { contentKey = '', opensInNewTab, fallbackElement = 'span', data, ...elementProps } = props;
  const [locale] = useLocalStorage('locale', null);
  const localeUrl = CorContentStackLanguageURLMap.get(locale);
  const content = useContentstackContent();
  const link: ICorContentstackLink = !!data ? data : get(content, contentKey, {});
  const relativePath = link?.href?.replace(window.location.origin, '');
  const isAnchorElement = isExternalUrl(link?.href || '') || hasQueryParams(relativePath);
  const linkProps = {
    ...elementProps,
    ...(isAnchorElement && link.href && { href: link.href }),
    ...(!isAnchorElement &&
      link.href && {
        to: {
          pathname: isInternationalSite(localeUrl)
            ? `${localeUrl}${relativePath === Routes.HomePage ? '' : relativePath}`
            : relativePath,
          ...((relativePath?.includes(Routes.LoginPage) || relativePath?.includes(Routes.CanadaFrenchLoginPage)) && {
            state: { from: history.location },
          }),
        },
      }),
    ...(!!opensInNewTab && { target: '_blank' }),
  } as any;
  const element = link?.href && link?.href !== '#' ? (isAnchorElement ? 'a' : Link) : fallbackElement;

  return React.createElement(element, linkProps, elementProps?.children || link.title);
};
