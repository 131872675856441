import { takeLatest, put, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { deleteSaveForLaterItemActionConstants, deleteSaveForLaterItem } from 'store/save-for-later/actions';
import { IAction } from 'types/actions';

export interface IDeleteSaveForLaterItemPayload {
  itemNumber: string;
  onSuccessCallback?: () => void;
}

export function* deleteSaveForLaterItemSuccessHandler({ payload }: IAction<IDeleteSaveForLaterItemPayload>) {
  yield take(deleteSaveForLaterItemActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallback) {
    payload.onSuccessCallback();
  }
}

export function* deleteSaveForLaterItemHandler({ payload: { itemNumber } }: IAction<IDeleteSaveForLaterItemPayload>) {
  yield put(doRequest(deleteSaveForLaterItem, `${Endpoints.SAVE_FOR_LATER}/${itemNumber}`, 'delete'));
}

export default function* deleteSaveForLaterItemSaga() {
  yield takeLatest(deleteSaveForLaterItemActionConstants[Actions.REQUEST], deleteSaveForLaterItemHandler);
  yield takeLatest(deleteSaveForLaterItemActionConstants[Actions.REQUEST], deleteSaveForLaterItemSuccessHandler);
}
