import { createSelector } from 'reselect';
import { includes, isEmpty } from 'lodash-es';
import { Roles } from 'constants/roles.enum';
import { IState } from 'store/root-reducer';
import { IAuthState } from 'store/auth/reducer';
import { Countries } from 'constants/countries.enum';

export const selectAuthEntity = (state: IState): IAuthState => state.app.auth;

export const selectUser = createSelector(selectAuthEntity, ({ user }) => user);

export const selectIsAuthorised = createSelector(selectAuthEntity, ({ user }) => {
  const roles = user?.roles || [];
  const authorisedUserRoles = [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.USER, Roles.SUPER_ADMIN_INVOICE_ONLY];

  return (
    Boolean(roles.some((role) => authorisedUserRoles.includes(role)) && user?.locationName) ||
    [Roles.MSR, Roles.CSR, Roles.TSR, Roles.MSM].some((role) => includes(roles, role))
  );
});

export const selectIsLoggedInUser = createSelector(
  selectAuthEntity,
  ({ user }) =>
    !isEmpty(user?.roles) &&
    window.location.pathname.includes('/shop') &&
    !window.location.pathname.includes('/login') &&
    !window.location.pathname.includes('/select-location')
);

export const selectIsBlockedAccount = createSelector(selectAuthEntity, ({ user }) =>
  includes(user?.roles || [], Roles.BLOCKED)
);

export const selectHasTemporaryPassword = createSelector(
  selectAuthEntity,
  ({ user }) => !!user.temporaryPasswordCreatedAt
);

export const selectIsMsrUser = createSelector(selectAuthEntity, ({ user }) => includes(user?.roles || [], Roles.MSR));

export const selectIsInvoiceOnlyCustomer = createSelector(selectAuthEntity, ({ user }) =>
  includes(user?.roles || [], Roles.SUPER_ADMIN_INVOICE_ONLY)
);

export const selectIsCsrUser = createSelector(selectAuthEntity, ({ user }) => includes(user?.roles || [], Roles.CSR));

export const selectIsMsmUser = createSelector(selectAuthEntity, ({ user }) => includes(user?.roles || [], Roles.MSM));

export const selectIsTsrUser = createSelector(selectAuthEntity, ({ user }) => includes(user?.roles || [], Roles.TSR));

export const selectIsHouseAccountUser = createSelector(selectAuthEntity, ({ user }) =>
  includes(user?.roles || [], Roles.HOUSE_ACCOUNT)
);

export const selectIsPickupAccountUser = createSelector(selectAuthEntity, ({ user }) =>
  includes(user?.roles || [], Roles.PICKUP_ACCOUNT)
);

export const selectIsKeyAccountUser = createSelector(selectAuthEntity, ({ user }) =>
  includes(user?.roles || [], Roles.KEY_ACCOUNT)
);

export const selectIsInternalUser = createSelector(selectAuthEntity, ({ user }) =>
  [Roles.MSR, Roles.CSR, Roles.TSR, Roles.MSM].some((role) => includes(user?.roles || [], role))
);

export const selectUserRole = createSelector(selectUser, (user) => user?.roles || []);

export const selectIsImpersonateModeOn = createSelector(
  selectUser,
  selectIsInternalUser,
  (user, isInternalUser) => isInternalUser && !!user.internalId
);

export const selectHasAssignedCustomers = createSelector(selectAuthEntity, ({ user }) => user?.hasAssignedCustomers);

export const selectImpersonateInfo = createSelector(selectUser, selectIsInternalUser, (user, isInImpersonationMode) =>
  isInImpersonationMode
    ? {
        firstName: user?.firstName,
        lastName: user?.lastName,
        accountNumber: user?.soldToId,
      }
    : {}
);

export const selectUserName = createSelector(selectUser, ({ firstName, lastName }) => ({
  firstName,
  lastName,
}));

export const selectUserLocationId = createSelector(selectAuthEntity, ({ user }) => user?.locationId);

export const selectIsUserLocationDeleted = createSelector(selectUser, (user) => user.locationDeleted);

export const selectAlgoliaProductsSuggestionsConfig = createSelector(
  selectUser,
  ({ algoliaApplicationId = '', securedApiKey = '', algoliaApplicationProductIndexName = '' }) => ({
    algoliaApplicationId,
    securedApiKey,
    algoliaApplicationProductIndexName,
  })
);

export const selectIsUserCountryCanada = createSelector(selectUser, ({ country }) => country === Countries.CANADA);
export const selectUserDcNumber = createSelector(selectUser, ({ dcNumber }) => dcNumber ?? '');
