import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { LoadingIndicator } from 'components/loading-indicator';
import { ContentstackMessage } from 'components/contentstack';
import { clearDiscontinuedItemsReplacements } from 'store/items-replacements/actions';
import { DiscontinuedWithReplacementSlider } from 'features/discontinued-items-with-replacements';
import { useGetOriginalToReplacementsMap } from 'hooks/use-get-original-to-replacement-map';
import { selectDiscontinuedItemsWithReplacementsLoading } from 'store/items-replacements/selectors';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { useRequestQuoteModal, useRequestQuoteSuccessMessageModal } from 'hooks/use-global-modal';

import './discontinued-items-with-replacements-modal.scss';

export interface IDiscontinuedItemsWithReplacementsModalProps extends IModalProps {
  messageType: 'error' | 'notifications' | 'success' | 'tooltips';
  messageId: string;
  originalToReplacementItemsMap?: {
    [key: string]: string;
  };
  openPreviousModal: () => void;
}

export const DiscontinuedItemsWithReplacementsModal: FC<IDiscontinuedItemsWithReplacementsModalProps> = ({
  isOpen = false,
  onClose,
  originalToReplacementItemsMap,
  messageType,
  messageId,
  openPreviousModal,
}) => {
  const contentstackPath = 'modals.0.discontinued_item_modal.0';
  const dispatch = useDispatch();
  const discontinuedItemsWithReplacementsLoading = useSelector(selectDiscontinuedItemsWithReplacementsLoading);
  const originalToReplacementMap = useGetOriginalToReplacementsMap(isOpen, originalToReplacementItemsMap);

  const showRequestQuoteModal = useRequestQuoteModal();
  const showRequestQuoteSuccessMessageModal = useRequestQuoteSuccessMessageModal();

  const requestQuoteSuccessCallBack = () => {
    showRequestQuoteSuccessMessageModal({
      onClose: () => {
        openPreviousModal();
      },
    });
  };

  const createRequestQuoteClickHandler = (sku: string) => () => {
    handleDiscontinuedItemModalClose();
    showRequestQuoteModal({
      skuKey: sku,
      requestQuoteSuccessCallBack,
      requestQuoteFailCallBack: openPreviousModal,
      onClose: openPreviousModal,
    });
  };

  const handleDiscontinuedItemModalClose = () => {
    dispatch(clearDiscontinuedItemsReplacements());
    onClose && onClose();
  };

  return (
    <>
      <Modal
        className="discontinued-items-with-replacements-modal"
        isOpen={isOpen}
        onClose={handleDiscontinuedItemModalClose}
        withBackdropClick
        size="medium"
      >
        {discontinuedItemsWithReplacementsLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ModalHeader
              iconType="error"
              contentstackPath={contentstackPath}
              titleContentstackPath={`${contentstackPath}.modal_title`}
            />
            <div className="discontinued-items-with-replacements-modal__content">
              <p className="discontinued-items-with-replacements-modal__description">
                <ContentstackMessage type={messageType} messageId={messageId} />
              </p>
              {originalToReplacementMap && (
                <DiscontinuedWithReplacementSlider
                  originalToReplacementMap={originalToReplacementMap}
                  createRequestQuoteClickHandler={createRequestQuoteClickHandler}
                />
              )}
            </div>
            <ModalFooter
              contentstackPath={contentstackPath}
              closeButtonHandler={handleDiscontinuedItemModalClose}
              hasCloseButton
            />
          </>
        )}
      </Modal>
    </>
  );
};
