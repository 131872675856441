import { FC } from 'react';
import { Modal } from 'components/modals/common/modal';
import { ContentstackText } from 'components/contentstack';
import { formatTimestamp } from 'utils/format-time';
import { ModalFooter } from 'components/modals/common/modal/components';
import './transaction-timestamp-modal.scss';

export interface ITransactionTimestampModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentCreationTimestamp?: string;
  scheduledOn?: string;
  timeZone?: string;
}

export const TransactionTimestampModal: FC<ITransactionTimestampModalProps> = ({
  isOpen,
  onClose,
  paymentCreationTimestamp,
  timeZone,
  scheduledOn,
}) => {
  const contentstackPath = 'modals.0.transaction_timestamp_modal.0';

  return (
    <Modal
      size="small"
      isOpen={isOpen}
      className="transaction-timestamp-modal"
      onClose={onClose}
      withBackdropClick
      hideCloseButton
    >
      <div className="transaction-timestamp-modal__body">
        {scheduledOn && (
          <div className="medium-12 transaction-timestamp-modal__scheduled-on">
            <ContentstackText contentKey={`${contentstackPath}.scheduled_on_label`} />{' '}
            {formatTimestamp(scheduledOn, timeZone)}
          </div>
        )}
        {paymentCreationTimestamp && (
          <div className="medium-12 transaction-timestamp-modal__paid-on">
            <ContentstackText contentKey={`${contentstackPath}.paid_on_label`} />{' '}
            {formatTimestamp(paymentCreationTimestamp, timeZone)}
          </div>
        )}
      </div>
      <ModalFooter contentstackPath={contentstackPath} closeButtonHandler={onClose} hasCloseButton />
    </Modal>
  );
};
