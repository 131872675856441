import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getRecentOrdersInfoConstants = createConstants(types.GET_RECENT_ORDERS_INFO);
export const getBuyItAgainProductsConstants = createConstants(types.GET_BUY_IT_AGAIN_PRODUCTS);
export const getRecentlyViewedItemsConstants = createConstants(types.GET_RECENTLY_VIEWED_ITEMS);
export const getHomeShoppingListsConstants = createConstants(types.GET_HOME_SHOPPING_LISTS);
export const getBuyItAgainSkuSubtotalConstants = createConstants(types.GET_BUY_IT_AGAIN_SKU_SUBTOTAL);

export const getRecentOrdersInfo = createRequestActions(getRecentOrdersInfoConstants);
export const getBuyItAgainProducts = createRequestActions(getBuyItAgainProductsConstants);
export const getRecentlyViewedItems = createRequestActions(getRecentlyViewedItemsConstants);
export const getHomeShoppingLists = createRequestActions(getHomeShoppingListsConstants);
export const getBuyItAgainSkuSubtotal = createRequestActions(getBuyItAgainSkuSubtotalConstants);

export const clearHomeTabsData = () => action(types.CLEAR_HOME_TABS_DATA);
export const clearBuyItAgainSkuSubtotal = (skuNumber: string) =>
  action(types.CLEAR_BUY_IT_AGAIN_SUBTOTAL, { skuNumber });
export const setLastRequestSku = (sku: string) => action(types.SET_LAST_REQUEST_SKU, sku);
