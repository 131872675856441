import { FC, useState, SyntheticEvent, SetStateAction } from 'react';
import { FormikProps } from 'formik';
import { PageLeaveConfirmationModal } from 'components/page-leave-confirmation-modal';
import { compose } from 'utils/compose';

export interface IFormState {
  setInvalidFormIsSubmitted: SetStateAction<any>;
  invalidFormIsSubmitted: boolean;
}

interface IFormProps extends Partial<FormikProps<any>> {
  onSubmit?: (event: SyntheticEvent) => void;
  className?: string;
  handleSubmit: () => void;
  onChange?: (event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
  children: ({ setInvalidFormIsSubmitted, invalidFormIsSubmitted }: IFormState) => JSX.Element;
}

export const FormWithValidationOnSubmit: FC<IFormProps> = ({
  children,
  onSubmit,
  handleSubmit,
  isValid,
  dirty,
  onChange = () => {},
  ...restProps
}) => {
  const [invalidFormIsSubmitted, setInvalidFormIsSubmitted] = useState(false);

  const internalSubmitHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    setInvalidFormIsSubmitted(!isValid);
    handleSubmit();
  };

  const onChangeHandler = (event: SyntheticEvent) => {
    setInvalidFormIsSubmitted(false);
    onChange(event);
  };

  return (
    <form onSubmit={compose(onSubmit, internalSubmitHandler)} onChange={onChangeHandler} {...restProps}>
      <>
        {children({ setInvalidFormIsSubmitted, invalidFormIsSubmitted })}
        {dirty && <PageLeaveConfirmationModal />}
      </>
    </form>
  );
};
