import { FC, useRef } from 'react';
import { findIndex } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { CorRegionSelector } from 'corporate-components';
import { UtilityNavDesktopMenu, UtilityNavMenuItem } from 'components/layout/cor-header/components/utility-navigation';
import { useMainNavState } from 'components/layout/cor-header/context/headerContext';
import {
  IAllProductsCategory,
  ICSHeaderCorpNavMenuOut,
  ICSUtilityNavSearchBar,
  IMainMenuLink,
  IMenuItemProps,
  IUtilityNavUserInfo,
} from 'components/layout/cor-header/cor-header.interface';
import userIcon from 'assets/images/icons/icon-user-outlined.svg';
import { Button } from 'components/button';
import { Routes } from 'constants/routes.enum';
import {
  AlgoliaSearch,
  ICategoryWithOptionalURL,
  MainNavItem,
  MainNavItemSubnav,
} from 'components/layout/cor-header/components';
import {
  ALL_PRODUCTS_KEYS,
  ALL_PRODUCTS_LABELS,
  HEADER_STATIC_LABELS,
} from 'components/layout/cor-header/cor-header.constants';
import { useNavigationStateContext } from 'hooks/use-navigation-state-context';
import { selectNewArrivalVisibility } from 'store/categories-navigation/selectors';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';
import { selectIsAuthorised, selectIsInvoiceOnlyCustomer } from 'store/auth/selectors';
import { CorContentStackLanguages } from 'constants/cor-locale.enum';

export interface IUtilityMenuDesktopProps {
  className?: string;
  menuItems?: IMenuItemProps[];
  searchField?: ICSUtilityNavSearchBar;
  shopButtonLabel?: string;
  userInfo: IUtilityNavUserInfo;
  shopMenu?: ICSHeaderCorpNavMenuOut;
  menuDropdownOptions?: IMainMenuLink[];
}

export const UtilityNavDesktop: FC<IUtilityMenuDesktopProps> = ({ menuDropdownOptions, ...props }) => {
  const history = useHistory();
  const [locale] = useLocalStorage('locale', null);
  const { isLoggedInUser, shopNavOpen } = useMainNavState();
  const { menuItems, shopButtonLabel, userInfo, shopMenu } = props;
  const { userName, menuOptions, text } = userInfo;
  const [firstName] = !!userName ? userName.split(' ') : [''];
  const showDropdown = isLoggedInUser && userName && menuOptions && text;
  const isInvoiceOnlyUser = useSelector(selectIsInvoiceOnlyCustomer);
  const isUserAuthorized = useSelector(selectIsAuthorised);
  const isCanadianUser =
    locale === CorContentStackLanguages.ENGLISH_CANADA || locale === CorContentStackLanguages.FRENCH_CANADA;
  const isInvoiceOnlyOrCanadianUser = isUserAuthorized && (isInvoiceOnlyUser || isCanadianUser);
  const navItemsWithoutLoginRegistration = menuItems?.filter(
    (item) =>
      item.url !== Routes.LoginPage &&
      item.url !== Routes.CanadaFrenchLoginPage &&
      item.url !== Routes.CustomerRegistrationPage &&
      item.url !== Routes.CanadaFrenchRegistrationPage
  );
  const navItemsWithoutPayments = menuItems?.filter(
    (item) => item.url !== Routes.AccountPayments && item.url !== Routes.CanadaFrenchPayments
  );

  const cleanUpImages = !isInvoiceOnlyOrCanadianUser
    ? navItemsWithoutPayments?.map((item) => {
        return {
          ...item,
          image: isLoggedInUser ? item.image : null,
          url: item.url,
        };
      })
    : navItemsWithoutLoginRegistration?.map((item) => {
        return {
          ...item,
          image: null,
          url: item.url,
        };
      });

  const shopMenuRef = useRef<CSSTransition>(null);
  const shopMenuDivRef = useRef<HTMLDivElement>(null);
  const { visibleCategories } = useNavigationStateContext();
  const showNewArrivals = useSelector(selectNewArrivalVisibility);

  const shopProductsMenu = shopMenu?.links[0];

  const newArrivalItem: any = {
    categoryKey: ALL_PRODUCTS_KEYS.NEW_ARRIVALS,
    categoryName: shopMenu?.new_arrivals_link?.title || ALL_PRODUCTS_LABELS.NEW_ARRIVALS,
    breadcrumbs: [
      {
        key: shopMenu?.new_arrivals_link?.href,
        name: shopMenu?.new_arrivals_link?.title || ALL_PRODUCTS_LABELS.NEW_ARRIVALS,
      },
    ],
    children: [],
    count: 0,
    url: shopMenu?.new_arrivals_link?.href,
  };

  const transposeMenuItems = (): ICategoryWithOptionalURL[] => {
    const addlProductsIndex = findIndex(
      visibleCategories,
      (category) => category.categoryKey === ALL_PRODUCTS_KEYS.ADDITIONAL_PRODUCTS
    );
    if (addlProductsIndex === -1) {
      return [];
    }

    const addlProducts = visibleCategories[addlProductsIndex];
    const rearranged = visibleCategories.slice();
    const newArrivals = showNewArrivals ? [newArrivalItem] : [];

    rearranged.splice(addlProductsIndex, 1);

    return [...rearranged, ...newArrivals, addlProducts];
  };

  const handleShopButtonClick = () => {
    if (isInvoiceOnlyUser && isUserAuthorized) {
      history.push(Routes.AccountPayments);
      return;
    }
    history.push(Routes.ShopHomePage);
  };

  return (
    <>
      {showDropdown && (
        <UtilityNavDesktopMenu
          label={`${HEADER_STATIC_LABELS.ACCOUNT_HI} ${firstName}`}
          panelContent={menuDropdownOptions}
          panelTitle={text}
          className={'utility-menu__user-dropdown'}
          icon={userIcon}
        />
      )}
      {!isInternationalSite(locale) && !isInvoiceOnlyUser && (
        <Button onClick={handleShopButtonClick} className={'utility-menu__shop-button'}>
          {shopButtonLabel}
        </Button>
      )}

      {!isInternationalSite(locale) && isLoggedInUser && shopProductsMenu && (
        <CSSTransition key={'shop-menu-nav'} ref={shopMenuRef} in={shopNavOpen} timeout={0} classNames="shop-menu">
          <div
            ref={shopMenuDivRef}
            className={` utility-menu__products-menu main-nav__menu${
              shopNavOpen ? ' is-open ' : ' '
            }main-nav__menu--shop`}
          >
            <MainNavItem
              name={`${shopProductsMenu.url}-menu`}
              label={shopMenu?.sticky_header_products_menu_label ?? ''}
              url={shopProductsMenu.url}
              hasSubmenu
              level={1}
            >
              <MainNavItemSubnav
                parent={`${shopProductsMenu.url}-menu`}
                url={shopProductsMenu.url}
                label={shopProductsMenu.label}
                childItems={() => transposeMenuItems()}
                resourceMenus={shopProductsMenu.resourceMenus}
                drillDownMenu={{
                  ...shopProductsMenu.drillDownMenu,
                  items: (transposeMenuItems() as unknown) as IAllProductsCategory[],
                }}
                menuId="shopMenu"
              />
            </MainNavItem>
          </div>
        </CSSTransition>
      )}
      <div className={'utility-menu__search-box'}>
        <AlgoliaSearch id={'utilitiyMenuSearchIn'} isLoggedIn={isLoggedInUser} expandable />
      </div>
      {cleanUpImages && cleanUpImages?.length > 0 && (
        <ul className={'utility-menu__items'}>
          {cleanUpImages.map((menuItemProps, index) => (
            <UtilityNavMenuItem key={index} {...menuItemProps} isLast={index === cleanUpImages.length - 1} />
          ))}
        </ul>
      )}

      {!isLoggedInUser && <CorRegionSelector direction="down" />}
    </>
  );
};
