import './cor-product-list.scss';

import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { MultiValue, SingleValue, components } from 'react-select';
import classNames from 'classnames';
import { ProductProvider } from 'corporate-components/cor-product-list/context/cor-products-context';
import { useProductContext } from 'corporate-components/cor-product-list/hooks/use-product-context';
import { CorTypography } from 'corporate-ui';
import { CorProductTile } from 'corporate-components/cor-product-list/components/cor-product-tile';
import { useContent } from 'hooks/use-content';
import { useReorder } from 'hooks/use-reorder';
import { CorButton } from 'components/cor-button';
import { CustomDropdown } from 'components/custom-dropdown';
import { useAddToShoppingList } from 'hooks/use-add-to-shopping-list';
import { addMultipleProductsToCart } from 'store/shopping-cart/actions';
import { IAddMultipleProductsToCartPayload } from 'store/shopping-cart/sagas/add-multiple-products-to-cart';
import { ISelectedProduct } from 'pages/shopping-list-details/shopping-list-details';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { useSelectOptionModal } from 'hooks/use-global-modal';
import { selectAllShoppingLists } from 'store/shopping-lists/selectors';
import { useCreateList } from 'hooks/use-create-list';
import { ISku } from 'types/product';
import {
  addItemsToShoppingLists,
  getAllShoppingLists,
  setShoppingListNameDuplicateError,
} from 'store/shopping-lists/actions';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { selectIsKeyAccountUser, selectIsTsrUser, selectIsUserLocationDeleted } from 'store/auth/selectors';

const DIGITS_TOTAL = 18;

export interface ICustomOptionType {
  value: () => void;
  label: string;
  isDisabled?: boolean;
}

const leftPadNum = (num: number, targetLength: number) => {
  return num.toString().padStart(targetLength, '0');
};

const CorProductListWrapper: FC = () => {
  const dispatch = useDispatch();
  const productListRef = useRef<HTMLDivElement | null>(null);
  const { getContentByKey } = useContent();
  const shoppingLists = useSelector(selectAllShoppingLists);
  const isTsrUser = useSelector(selectIsTsrUser);
  const sharedDetailsContentStackPath = 'common[0].shared_recipe_detail_page[0]';
  const contactRepLabel: string = getContentByKey('common[0].contact_your_rep_label', '');
  const [showMoreButtonPosition, setShowMoreButtonPosition] = useState<string>('0');
  const { isMobile, isDesktop } = useBreakpoint();
  const [expanded, setExpanded] = useState<boolean>(false);
  const productContext = useProductContext();
  const { products, toggleSelected, unSelectProducts } = productContext;

  const contentStackPath = 'common[0].product_characteristics.product_status_labels[0]';
  const selectedItems = products?.filter((product) => product.isSelected);
  const allSelectedIsSpecialOrder = selectedItems?.every((product) => product.isSpecialOrder);
  const isItemSelected = !isEmpty(selectedItems);
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const isKeyAccountUser = useSelector(selectIsKeyAccountUser);

  useEffect(() => {
    const $current: HTMLDivElement | null = productListRef?.current || null;
    if (products?.length && $current) {
      const $tile = $current.querySelector('.cor-product-tile');
      const listHeight = $current.getBoundingClientRect().height;
      const tileHeight = $tile ? $tile.getBoundingClientRect().height : 0;
      const tileMarginSpace = isMobile ? listHeight - tileHeight * 2.58 : 240;
      setShowMoreButtonPosition(`-${tileMarginSpace}px`);

      if (isMobile ? products?.length <= 4 : products?.length <= 5) {
        setExpanded(true);
      }
    }
  }, [products, productListRef, isMobile]);

  const selectedAddToItems: ISelectedProduct[] = selectedItems?.map((item) => ({
    sku: item.sku,
    quantity: 1,
  })) as ISelectedProduct[];

  const addSelectedProductsToCart = useCallback(() => {
    if (!isEmpty(selectedAddToItems)) {
      dispatch(
        addMultipleProductsToCart.request<IAddMultipleProductsToCartPayload>({
          lineItems: selectedAddToItems,
          onSuccessCallBack: () => {
            unSelectProducts();
          },
        })
      );
    }
  }, [dispatch, unSelectProducts, selectedAddToItems]);

  const createListSuccessCallBack = (shoppingListName: string) => {
    showAddedItemsSuccessfullyModal({
      messageId: 'MSG080',
      messageInterpolateParams: {
        itemsQuantity: selectedAddToItems.length,
        listName: shoppingListName,
      },
    });
    dispatch(getAllShoppingLists.request());
    unSelectProducts();
  };

  const createListFailCallBack = (deactivatedLineItems: string[], status: number) => {
    if (status === 409) {
      dispatch(setShoppingListNameDuplicateError(true));
    } else if (status === 422) {
      showFailedAddItemsErrorModal();
    } else {
      showAddedItemsFailModal({ messageId: 'MSG135' });
    }
    dispatch(getAllShoppingLists.request());
  };

  const { showFailedAddItemsErrorModal, createListHandler } = useCreateList({
    createHandlerLineItems: selectedAddToItems,
    createListSuccessCallBack,
    createListFailCallBack,
  });

  const openCreateListModalHandler = (sku: ISku) => showCreateShoppingListModal({ sku });
  const addSelectedItemsToShoppingListsSuccessCallBack = (shoppingLists: string[]) => () => {
    showAddedItemsSuccessfullyModal({
      messageId: 'MSG079',
      messageInterpolateParams: {
        itemsQuantity: selectedAddToItems.length,
        listsQuantity: shoppingLists.length,
      },
    });
    dispatch(getAllShoppingLists.request());
    unSelectProducts();
  };

  const addSelectedItemsToShoppingListsFailCallBack = () => {
    showAddedItemsFailModal({ messageId: 'MSG136', children: <ContentstackMessage type="error" messageId="MSG046" /> });
  };

  const addLineItemsToShoppingLists = (selectedShoppingLists: string[]) => {
    dispatch(
      addItemsToShoppingLists.request({
        lineItems: selectedAddToItems,
        selectedShoppingLists,
        onSuccessCallback: addSelectedItemsToShoppingListsSuccessCallBack(selectedShoppingLists),
        onFailCallback: addSelectedItemsToShoppingListsFailCallBack,
      })
    );
  };

  const {
    showCreateShoppingListModal,
    showAddItemsToShoppingListModal,
    showAddedItemsSuccessfullyModal,
    showAddedItemsFailModal,
  } = useAddToShoppingList({
    createListHandler,
    openCreateListModalHandler,
    addLineItemsToShoppingLists,
  });

  const addSelectedItemsToShoppingList = () => {
    if (isEmpty(shoppingLists)) {
      showCreateShoppingListModal();
      return;
    }
    showAddItemsToShoppingListModal({
      itemsCounter: selectedAddToItems.length,
      includesFavorites: true,
    });
  };

  const dropDownValue: ICustomOptionType = {
    label: getContentByKey(`${sharedDetailsContentStackPath}.add_selection_to`, ''),
    value: () => null,
    isDisabled: !isItemSelected,
  };

  const addToSelectionOptions: ICustomOptionType[] = [
    {
      label: getContentByKey(`${sharedDetailsContentStackPath}.shopping_list_label`, ''),
      value: addSelectedItemsToShoppingList,
    },
    {
      label: getContentByKey(`${sharedDetailsContentStackPath}.cart_label`, ''),
      value: addSelectedProductsToCart,
      isDisabled: allSelectedIsSpecialOrder,
    },
  ];

  const showSelectOptionModal = useSelectOptionModal({
    contentstackModalTitlePath: `${sharedDetailsContentStackPath}.add_selection_to`,
    areOptionsClickable: false,
    handler: (option: ICustomOptionType) => option.value(),
    options: isTsrUser ? [addToSelectionOptions[0]] : addToSelectionOptions,
    defaultSelectedOption: addToSelectionOptions[0],
    withBackdropClick: true,
  });

  useReorder({ recipeDetailContentstackPath: contentStackPath });

  const addSelectionRenderOptions = ({ children, ...props }: any) => {
    return props.data.isDisabled ? (
      <components.Option className="cor-product-list__add-to-dropdown--disabled" {...props} isDisabled>
        {children}
      </components.Option>
    ) : (
      <components.Option {...props}>{children}</components.Option>
    );
  };

  const onAddSelectionToOptionChange = (
    addSelectionToOption: MultiValue<ICustomOptionType> | SingleValue<ICustomOptionType>
  ) => {
    const newOption = addSelectionToOption as ICustomOptionType;
    newOption?.value();
  };

  if (!products?.length) {
    return null;
  }

  const displayShowMore = isMobile ? products?.length > 4 : products?.length > 5;

  return (
    <div className={' grid-container cor-product-list'}>
      <div className="cor-product-list__heading">
        <CorTypography className={'cor-product-list__title'} color={'dark-gray'} variant={'h2'} component={'div'}>
          <ContentstackText contentKey={`${sharedDetailsContentStackPath}.list_title`} />
        </CorTypography>
        {isDesktop && (
          <CustomDropdown
            className="cor-product-list__add-to-dropdown"
            inputId="cor-product-list__add-to-dropdown--id"
            items={isTsrUser ? [addToSelectionOptions[0]] : addToSelectionOptions}
            isDisabled={!isItemSelected || isUserLocationDeleted}
            onChange={onAddSelectionToOptionChange}
            renderOption={addSelectionRenderOptions}
            value={dropDownValue}
          />
        )}
      </div>
      <div ref={productListRef} className="cor-product-list__products">
        {products?.map((product, idx) => {
          return (
            <CorProductTile
              key={idx.toString()}
              {...product}
              clickHandler={() => toggleSelected(product.sku)}
              contactRepLabel={contactRepLabel}
              isUserLocationDeleted={isUserLocationDeleted}
              isKeyAccountUser={isKeyAccountUser}
            />
          );
        })}
      </div>
      <div
        className={classNames('cor-product-list__see-all-wrapper', {
          'is-expanded': expanded,
        })}
        style={{
          marginTop: showMoreButtonPosition,
        }}
      >
        {displayShowMore && (
          <CorButton
            className={'cor-product-list__see-all-button'}
            color={'secondary'}
            onClick={() => setExpanded(!expanded)}
          >
            <ContentstackText
              contentKey={`${sharedDetailsContentStackPath}.${expanded ? 'close_label' : 'see_all_label'}`}
            />
          </CorButton>
        )}

        {isMobile && (
          <CorButton
            className={'cor-product-list__add-to-button'}
            color={'primary'}
            onClick={() => showSelectOptionModal()}
            disabled={!isItemSelected}
          >
            <ContentstackText contentKey={`${sharedDetailsContentStackPath}.add_selection_to`} />
          </CorButton>
        )}
      </div>
    </div>
  );
};

export const CorProductList: FC = () => {
  const { getContentByKey } = useContent();
  const skus = getContentByKey('product_group.product_list', []).map(({ product_sku }) => product_sku);
  const paddedSkus = skus.map((sku) => leftPadNum(sku, DIGITS_TOTAL));

  if (!paddedSkus) {
    return null;
  }

  return (
    <ProductProvider skus={paddedSkus}>
      <CorProductListWrapper />
    </ProductProvider>
  );
};
