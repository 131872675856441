import { all, spawn } from 'redux-saga/effects';
import getLocationsByDayOfWeekSaga from './msr/get-locations-by-day-of-week';
import getLocationsByNameSaga from './msr/get-locations-by-name';
import getLocationsByRecentOrdersSaga from './msr/get-locations-by-recent-orders';
import getLocationsByAccountNumberSaga from './csr/get-locations-by-account-number';
import getLocationsForMsrSaga from './csr/get-locations-for-msr';
import getMsrListByNameHandlerSaga from './csr/get-msr-list-by-name';
import getLocationsForMsm from './msm/get-locations-for-msm';
import changeCustomerEmailAddressSaga from './csr/change-customer-email-address';
import changeCustomerPasswordSaga from './csr/change-customer-password';
import getMsrListByNameForNameSaga from './msm/get-msr-list-by-name';
import unlockCsrCustomerAccountHandlerSaga from './csr/unlock-csr-customer-account';
import getLocationsByMSrIdForMsmSaga from './msm/get-locations-for-msr';
import getLocationsWithAssignedAccountsByDayOfWeekSaga from './csr/get-locations-with-assigned-accounts-by-day-of-week';
import getLocationsWithAssignedAccountsByNameSaga from './csr/get-locations-with-assigned-accounts-by-name';
import getLocationsWithAssignedAccountsByRecentOrdersSaga from './csr/get-locations-with-assigned-accounts-by-recent-orders';
import getMsrLocationsCartStatusAndOrdersForMsmSaga from './msm/get-orders-cart-status-for-msm';
import getCsrLocationsByDayOfWeekSaga from './csr/get-locations-by-day-of-week';
import getCsrLocationsByNameSaga from './csr/get-locations-by-name';
import getCsrLocationsByRecentOrdersSaga from './csr/get-locations-by-recent-orders';

export default function* dashboardSagas() {
  yield all([
    spawn(getLocationsByNameSaga),
    spawn(getLocationsByDayOfWeekSaga),
    spawn(getLocationsByAccountNumberSaga),
    spawn(getLocationsForMsrSaga),
    spawn(getMsrListByNameHandlerSaga),
    spawn(getLocationsByRecentOrdersSaga),
    spawn(getLocationsForMsm),
    spawn(changeCustomerEmailAddressSaga),
    spawn(changeCustomerPasswordSaga),
    spawn(unlockCsrCustomerAccountHandlerSaga),
    spawn(getMsrListByNameForNameSaga),
    spawn(getLocationsByMSrIdForMsmSaga),
    spawn(getLocationsWithAssignedAccountsByDayOfWeekSaga),
    spawn(getLocationsWithAssignedAccountsByNameSaga),
    spawn(getLocationsWithAssignedAccountsByRecentOrdersSaga),
    spawn(getMsrLocationsCartStatusAndOrdersForMsmSaga),
    spawn(getCsrLocationsByDayOfWeekSaga),
    spawn(getCsrLocationsByNameSaga),
    spawn(getCsrLocationsByRecentOrdersSaga),
  ]);
}
