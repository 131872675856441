import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useContent } from 'hooks/use-content';
import { relocateCurrencyMinusSign } from 'utils/currency-format';
import { IAdditionalChargesExtended } from 'types/additional-charges';
import { AdditionalChargesTooltip } from 'components/additional-charges-tooltip';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { OrderSummaryDiscount } from 'components/order/order-summary-discount';
import { IDiscounts } from 'types/order-details';
import { Routes } from 'constants/routes.enum';
import { GeneralWarning } from 'components/general-warning';

import './order-summary.scss';

export interface IOrderSummaryProps {
  contentstackRootPath: string;
  totalItemsQuantity: number;
  shippingSurcharge: string;
  totalTaxes: string;
  total: string;
  subTotal: string;
  additionalCharges: string;
  additionalChargesExtended: IAdditionalChargesExtended;
  className?: string;
  children?: ReactNode;
  discountsExtended: IDiscounts;
  totalDiscounts?: string;
}

export const OrderSummary: FC<IOrderSummaryProps> = ({
  children,
  className,
  contentstackRootPath,
  totalItemsQuantity,
  shippingSurcharge,
  totalTaxes,
  total,
  subTotal,
  additionalCharges,
  discountsExtended,
  totalDiscounts,
  additionalChargesExtended,
}) => {
  const { getContentByKey } = useContent();
  const location = useLocation();
  const priceEstimatedLabel = getContentByKey('page_content.order_summary[0].price_estimated', '');
  const isAdditionalChargesApplied = Math.abs(parseFloat(additionalCharges?.replace(/[^0-9.-]/g, ''))) > 0;
  const isOrderConfirmationPage = location.pathname.includes(Routes.OrderConfirmationPage);
  const showAdditionalChargesWarning = isAdditionalChargesApplied && isOrderConfirmationPage;

  return (
    <div className={classNames('order-summary', className)}>
      <div className="order-summary__content">
        <div className="order-summary__title">
          <ContentstackText contentKey={`${contentstackRootPath}.title`} />
        </div>
        <div
          className={classNames('order-summary__details', {
            'order-summary__details--with-additional-charges': showAdditionalChargesWarning,
          })}
        >
          <div className="order-summary__item">
            <span className="order-summary__item-label">
              <ContentstackText
                contentKey={`${contentstackRootPath}.subtotal`}
                interpolateParams={{ quantity: totalItemsQuantity }}
              />
            </span>
            {subTotal}
          </div>
          <OrderSummaryDiscount
            discountsExtended={discountsExtended}
            totalDiscounts={totalDiscounts}
            contentstackRootPath={contentstackRootPath}
          />
          <div className="order-summary__item">
            <span className="order-summary__item-label">
              <ContentstackText contentKey={`${contentstackRootPath}.shipping_surcharge`} />
            </span>
            {shippingSurcharge || priceEstimatedLabel}
          </div>
          <div className="order-summary__item">
            <span className="order-summary__item-label">
              <ContentstackText contentKey={`${contentstackRootPath}.total_for_taxes`} />
            </span>
            {totalTaxes || priceEstimatedLabel}
          </div>
          <div className="order-summary__additional-charges">
            <div className="order-summary__additional-charges-label">
              <div className="order-summary__additional-charges-label-text">
                <ContentstackText contentKey={`${contentstackRootPath}.additional_charges`} />
              </div>
              <div className="order-summary__additional-charges-tooltip">
                <AdditionalChargesTooltip additionalChargesExtended={additionalChargesExtended} />
              </div>
            </div>
            <div className="order-summary__additional-charges-value">
              {relocateCurrencyMinusSign(additionalCharges || priceEstimatedLabel)}
            </div>
          </div>
        </div>
        {showAdditionalChargesWarning && (
          <GeneralWarning
            title={getContentByKey(`${contentstackRootPath}.additional_charges`, '').replace(':', '')}
            contentstackPath={contentstackRootPath}
            titleClassName="order-summary__additional-charges-warning-title"
            className="order-summary__additional-charges-warning"
          >
            <ContentstackMessage messageId="MSG215" type="notifications" />
          </GeneralWarning>
        )}
        <div className="order-summary__total">
          <span className="order-summary__total-label">
            <ContentstackText contentKey={`${contentstackRootPath}.order_total`} />
          </span>
          <span className="order-summary__total-value">{total || priceEstimatedLabel}</span>
        </div>
      </div>
      {children}
    </div>
  );
};
