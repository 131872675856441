import './cor-small-card-title.scss';

import React from 'react';
import { CorContentstackImage, CorContentstackLink } from 'corporate-components/cor-contentstack';
import {
  ICorFeaturedContentCardVariant,
  ICorFeaturedContentEntry,
} from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { useContent } from 'hooks/use-content';

const CorSmallCardTitle = ({ contentKeyBase }: ICorFeaturedContentCardVariant) => {
  const { getContentByKey } = useContent();
  const entry: ICorFeaturedContentEntry = getContentByKey(contentKeyBase, {});
  const { title = 'Image Link' } = entry;

  return (
    <div className="cor-featured-content__small-card-title cor-featured-content__card cor-featured-content__card--small-size">
      <CorContentstackLink
        className="cor-featured-content__small-card-title__image__container cor-featured-content__card__image__container"
        contentKey={`${contentKeyBase}.pageUrl`}
        fallbackElement="div"
      >
        <span className={'cor-featured-content__small-card-title__link-label'}>{title}</span>
        {!!entry?.image && (
          <CorContentstackImage
            contentKey={`${contentKeyBase}.image`}
            className="cor-featured-content__small-card-title__image cor-featured-content__card__image"
          />
        )}
      </CorContentstackLink>

      {!!entry?.title && (
        <CorContentstackLink
          contentKey={`${contentKeyBase}.pageUrl`}
          fallbackElement="h4"
          className="cor-featured-content__small-card-title__title"
          data-testid="cor-featured-content__small-card-title__title"
        >
          {entry?.title}
        </CorContentstackLink>
      )}
    </div>
  );
};

export default React.memo(CorSmallCardTitle);
