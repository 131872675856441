import { all, spawn } from 'redux-saga/effects';
import getSimilarItemsSaga from './get-similar-items';
import getRequestQuoteSimilarItemsSaga from './get-request-quote-similar-items';
import getSimilarItemsSkuSubtotalSaga from './get-similar-items-sku-subtotal';

export default function* similarItemsSagas() {
  yield all([spawn(getSimilarItemsSaga)]);
  yield all([spawn(getRequestQuoteSimilarItemsSaga)]);
  yield all([spawn(getSimilarItemsSkuSubtotalSaga)]);
}
