import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { ISimilarItemsState } from 'store/similar-items/reducer';

export const selectSimilarItemsEntity = (state: IState): ISimilarItemsState => state.app.similarItems;

export const selectSimilarItemsData = createSelector(
  selectSimilarItemsEntity,
  ({ similarItemsData }) => similarItemsData
);

export const selectSimilarItemsLoading = createSelector(selectSimilarItemsEntity, ({ loading }) => loading);

export const selectRequestQuoteSimilarItemsData = createSelector(
  selectSimilarItemsEntity,
  ({ requestQuoteSimilarItemsData }) => requestQuoteSimilarItemsData
);

export const selectRequestQuoteSimilarItemsLoading = createSelector(
  selectSimilarItemsEntity,
  ({ requestQuoteSimilarItemsLoading }) => requestQuoteSimilarItemsLoading
);

export const selectSimilarItemLastRequestedSku = createSelector(
  selectSimilarItemsEntity,
  ({ lastRequestedSku }) => lastRequestedSku
);
