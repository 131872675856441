import { Actions } from 'constants/actions.enum';
import { DEFAULT_PRICE_MODEL } from 'constants/price.enum';
import { createReducer } from 'store/reducer-creator';
import { IAccountLocation } from 'types/locations';
import { IOrderSummary } from 'types/order-details';
import { ILineItem } from 'types/line-item';
import { rootReducerActionTypes } from 'store/root-reducer-actions';
import types from './action-types';
import * as actions from './actions';

export interface ICheckoutState {
  orderDate: string;
  orderNumber: string;
  shipToLocation: IAccountLocation;
  billToLocation: IAccountLocation;
  accountInformation: {
    accountStatus: string;
    paymentTerms: string;
  };
  priceSummary: IOrderSummary;
  expectedDeliveryDate: string;
  availableDeliveryDates: string[];
  loading: boolean;
  poNumber: string;
  selectedDeliveryDate: string | null;
  lineItems: ILineItem[];
  ctId: string;
  offline: boolean;
  isSapBackup: boolean;
  generalErrorMessage: string;
  proceededToOrderConfirmationFromCheckout: boolean;
  isPickupOrder?: boolean;
}

export interface IDeliveryGroup {
  deliveryDate?: string;
  lineItemNumbers: string[];
}

const checkoutLocationInitialState = { address: '', city: '', country: '', state: '', zipCode: '' };

export const checkoutInfoInitialState: ICheckoutState = {
  orderDate: '',
  orderNumber: '',
  billToLocation: {
    locationId: '',
    name: '',
    locationNumber: '',
    address: checkoutLocationInitialState,
  },
  shipToLocation: {
    locationId: '',
    name: '',
    locationNumber: '',
    address: checkoutLocationInitialState,
  },
  accountInformation: {
    accountStatus: '',
    paymentTerms: '',
  },
  priceSummary: {
    subTotal: DEFAULT_PRICE_MODEL,
    totalItemsQuantity: 0,
    shippingSurcharge: DEFAULT_PRICE_MODEL,
    totalTaxes: DEFAULT_PRICE_MODEL,
    additionalCharges: DEFAULT_PRICE_MODEL,
    total: DEFAULT_PRICE_MODEL,
    additionalChargesExtended: {},
    discountsExtended: {},
    totalDiscounts: {},
  },
  expectedDeliveryDate: '',
  availableDeliveryDates: [],
  selectedDeliveryDate: null,
  lineItems: [],
  poNumber: '',
  ctId: '',
  offline: false,
  loading: false,
  isSapBackup: false,
  generalErrorMessage: '',
  proceededToOrderConfirmationFromCheckout: false,
  isPickupOrder: false,
};

const checkoutInfo = createReducer<ICheckoutState>(
  {
    [actions.validateCartActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      poNumber: '',
      loading: true,
    }),
    [actions.validateCartActionConstants[Actions.SUCCESS]]: (
      state,
      {
        payload: {
          billToLocation,
          shipToLocation,
          accountInformation,
          expectedDeliveryDate,
          availableDeliveryDates,
          priceSummary,
          lineItems,
          offline,
          additionalChargesExtended,
          isPickupOrder,
        },
      }
    ) => ({
      ...state,
      billToLocation: { ...billToLocation },
      shipToLocation: { ...shipToLocation },
      accountInformation: { ...accountInformation },
      priceSummary,
      expectedDeliveryDate,
      availableDeliveryDates,
      lineItems: [...lineItems],
      loading: false,
      generalErrorMessage: '',
      offline,
      additionalChargesExtended,
      isPickupOrder,
    }),
    [actions.validateCartActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      generalErrorMessage:
        error.status !== 422 || (error.status === 422 && error.errorMessageId === 'MSG174')
          ? error.errorMessageId
          : error.errorMessages[0],
      loading: false,
    }),
    [actions.submitOrderActionConstants[Actions.REQUEST]]: (state) => ({ ...state, loading: true }),
    [actions.submitOrderActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { orderDate, expectedDeliveryDate, orderNumber, priceSummary, lineItems, ctId, offline } }
    ) => {
      const wasSapDown = state.offline;
      const isSapOnline = !offline;

      return {
        ...state,
        priceSummary,
        lineItems: [...lineItems],
        expectedDeliveryDate,
        orderDate,
        orderNumber,
        ctId,
        loading: false,
        offline,
        isSapBackup: wasSapDown && isSapOnline,
        availableDeliveryDates: [],
        selectedDeliveryDate: null,
      };
    },
    [actions.submitOrderActionConstants[Actions.FAIL]]: (state, { error }) => ({
      ...state,
      loading: false,
      isSapBackup: false,
      availableDeliveryDates: [],
      selectedDeliveryDate: null,
      generalErrorMessage: error.errorMessageId,
    }),
    [types.SET_PO_NUMBER]: (state, { payload }) => ({ ...state, poNumber: payload.poNumber }),
    [types.SET_SELECTED_DELIVERY_DATE]: (state, { payload }) => ({
      ...state,
      selectedDeliveryDate: payload.selectedDeliveryDate,
    }),
    [types.RESET_SAP_BACKUP_STATUS]: (state) => ({ ...state, isSapBackup: false }),
    [types.CLEAR_GENERAL_ERROR_MESSAGE]: (state) => ({ ...state, generalErrorMessage: '' }),
    [types.GO_TO_ORDER_SUBMITTION]: (state) => ({
      ...state,
      proceededToOrderConfirmationFromCheckout: true,
    }),
    [rootReducerActionTypes.RESET_LOCATION_RELATED_DATA]: (state) => ({
      ...state,
      generalErrorMessage: '',
    }),
  },
  checkoutInfoInitialState
);

export default checkoutInfo;
