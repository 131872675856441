enum skuReplacements {
  GET_SKU_REPLACEMENTS = 'GET_SKU_REPLACEMENTS',
  CLEAR_SKU_REPLACEMENTS = 'CLEAR_SKU_REPLACEMENTS',
  GET_DISCONTINUED_ITEMS_REPLACEMENTS = 'GET_DISCONTINUED_ITEMS_REPLACEMENTS',
  CLEAR_DISCONTINUED_ITEMS_REPLACEMENTS = 'CLEAR_DISCONTINUED_ITEMS_REPLACEMENTS',
  GET_PRODUCT_REPLACEMENT_SKU_SUBTOTAL = 'GET_PRODUCT_REPLACEMENT_SKU_SUBTOTAL',
  SET_LAST_REQUESTED_SKU = 'SET_LAST_REQUESTED_SKU',
  CLEAR_ITEM_REPLACEMENTS_SUBTOTAL = 'CLEAR_ITEM_REPLACEMENTS_SUBTOTAL',
}

export default skuReplacements;
