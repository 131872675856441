import { createReducer } from 'store/reducer-creator';
import * as actions from 'store/save-for-later/actions';
import { Actions } from 'constants/actions.enum';
import { ISaveForLaterLineItem } from 'types/line-item';
import types from './action-types';

export interface ISaveForLaterState {
  total: number;
  offset: number;
  limit: number;
  items: ISaveForLaterLineItem[];
  loading: boolean;
}

export const saveForLaterInitialState: ISaveForLaterState = {
  total: 0,
  offset: 0,
  limit: 0,
  items: [],
  loading: false,
};

const saveForLater = createReducer<ISaveForLaterState>(
  {
    [actions.getSaveForLaterItemsActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getSaveForLaterItemsActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      ...payload,
      items: [...state.items, ...payload.items],
      loading: false,
    }),
    [actions.getSaveForLaterItemsActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.DELETE_SAVE_FOR_LATER_ITEM_FROM_STORE]: (state, { payload }) => ({
      ...state,
      items: state.items.filter((item) => item.itemNumber !== payload.skuNumber),
      total: state.total - 1,
    }),
    [types.CLEAR_SAVE_FOR_LATER]: () => saveForLaterInitialState,
  },
  saveForLaterInitialState
);

export default saveForLater;
