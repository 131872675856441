import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { CorButton } from 'components/cor-button';
import { ProductImage } from 'components/product-image';
import { LoadingIndicator } from 'components/loading-indicator';
import { ProductStatusLabels } from 'components/product-status-labels';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { ProductGroupSelector } from 'features/product-group-selector';
import { ISku } from 'types/product';
import { selectQuickShopLoading } from 'store/quick-shop/selectors';
import {
  addItemsToShoppingLists,
  getAllShoppingLists,
  setShoppingListNameDuplicateError,
} from 'store/shopping-lists/actions';
import { useAddToShoppingList } from 'hooks/use-add-to-shopping-list';
import { getProductDetails, resetProductDetails, setSelectedSku } from 'store/product-details/actions';
import { IGetProductDetailsPayload } from 'store/product-details/sagas/get-product-details';
import { selectProductInfo, selectProductSelectedSkuInfo } from 'store/product-details/selectors';
import { useCreateList } from 'hooks/use-create-list';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';

import './quick-add-to-shopping-list-modal.scss';

export interface IQuickAddToShoppingListModalProps extends IModalProps {
  productKey?: string;
  selectedSkuNumber?: string;
}

export const QuickAddToShoppingListModal: FC<IQuickAddToShoppingListModalProps> = ({
  isOpen = false,
  onClose = () => {},
  productKey,
  selectedSkuNumber = '',
}) => {
  const dispatch = useDispatch();
  const imageSize = { width: 180, height: 180 };
  const isQuickShopLoading = useSelector(selectQuickShopLoading);
  const [productKeyNumber, setProductKeyNumber] = useState(productKey);
  const [skuNumber, setSkuNumber] = useState(selectedSkuNumber);

  const selectedSku = useSelector(selectProductSelectedSkuInfo);
  const productInfo = useSelector(selectProductInfo);

  const defaultSku = productInfo?.skus?.find(({ isDefault }) => isDefault);
  const sku = selectedSku || defaultSku;

  useEffect(() => {
    if (isOpen && productKeyNumber) {
      dispatch(
        getProductDetails.request<IGetProductDetailsPayload>({
          productId: productKeyNumber,
          selectedSku: skuNumber ? skuNumber : '',
        })
      );
    }
    return () => {
      dispatch(resetProductDetails());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen, skuNumber, productKeyNumber]);

  useEffect(() => {
    const hasShoppingListModalOpenedImmediately = isOpen && productInfo?.productKey && !productInfo?.groupSelectors;

    if (hasShoppingListModalOpenedImmediately) {
      onClose();
      showAddItemsToShoppingListModal({ includesFavorites: true, itemsCounter: 1, addedSku: selectedSku });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, productInfo]);

  useEffect(() => {
    return () => {
      if (isOpen) {
        return;
      }
      // TODO: Check the usage of this cleanup ( simplify if possible )
      dispatch(resetProductDetails());
    };
  }, [dispatch, isOpen]);

  const addItemToList = (sku: ISku) => {
    onClose();
    showAddItemsToShoppingListModal({ includesFavorites: true, itemsCounter: 1, addedSku: sku });
  };

  const createListSuccessCallBack = (shoppingListName: string) => {
    showAddedItemsSuccessfullyModal({
      messageId: 'MSG080',
      messageInterpolateParams: {
        itemsQuantity: 1,
        listName: shoppingListName,
      },
    });
    dispatch(getAllShoppingLists.request());
  };

  const createListFailCallBack = (deactivatedLineItems: string[], status: number) => {
    if (status === 409) {
      dispatch(setShoppingListNameDuplicateError(true));
      showCreateShoppingListModal();
    }
  };

  const addLineItemsToShoppingListsSuccessCallBack = (shoppingLists: string[]) => () => {
    showAddedItemsSuccessfullyModal({
      messageId: 'MSG079',
      messageInterpolateParams: {
        itemsQuantity: 1,
        listsQuantity: shoppingLists.length,
      },
    });
    dispatch(getAllShoppingLists.request());
  };

  const { createListHandler } = useCreateList({
    createListSuccessCallBack,
    createListFailCallBack,
    createHandlerLineItems: [
      {
        sku: selectedSku?.sku,
        quantity: 1,
      },
    ],
  });

  const addLineItemsToShoppingLists = (selectedShoppingLists: string[], shoppingListId, selectedSku?: ISku) => {
    dispatch(
      addItemsToShoppingLists.request({
        lineItems: [
          {
            sku: selectedSku?.sku,
            quantity: '1',
          },
        ],
        selectedShoppingLists,
        onSuccessCallback: addLineItemsToShoppingListsSuccessCallBack(selectedShoppingLists),
      })
    );
  };

  const onProductGroupSelectorChange = (sku, productKey) => {
    setProductKeyNumber(productKey);
    setSkuNumber(sku);
    dispatch(setSelectedSku(sku));
  };

  const openCreateListModalHandler = (sku: ISku) => showCreateShoppingListModal({ sku });

  const {
    showCreateShoppingListModal,
    showAddItemsToShoppingListModal,
    showAddedItemsSuccessfullyModal,
  } = useAddToShoppingList({
    createListHandler,
    openCreateListModalHandler,
    addLineItemsToShoppingLists,
    forceInitialRequest: false,
  });

  return (
    <>
      <Modal
        className="quick-add-to-shopping-list-modal"
        size="medium"
        isOpen={isOpen}
        onClose={onClose}
        withBackdropClick
      >
        <>
          {isQuickShopLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <div className="quick-add-to-shopping-list-modal__content-wrapper">
                <ModalHeader titleContentstackPath="modals.0.add_to_shopping_list_variant_selector_modal.0.heading" />
                <div className="quick-add-to-shopping-list-modal__inner-content">
                  <div className="quick-add-to-shopping-list-modal__image">
                    <ProductImage
                      src={selectedSku?.imageUrls[0]}
                      altText={selectedSku?.name}
                      desktopSize={imageSize}
                      mobileSize={imageSize}
                      withLoading
                    />
                    {(sku?.isDiscontinued || sku?.isSpecialOrder || sku?.isPhasingOut || sku?.isOutOfStock) && (
                      <div className="quick-add-to-shopping-list-modal__statuses-wrapper">
                        <ProductStatusLabels
                          isDiscontinued={sku?.isDiscontinued}
                          isPhasingOut={sku?.isPhasingOut}
                          isSpecialOrder={sku?.isSpecialOrder}
                        />
                      </div>
                    )}
                  </div>
                  {productInfo?.groupSelectors && (
                    <div className="quick-add-to-shopping-list-modal__group-selector">
                      <ProductGroupSelector onSelectorChange={onProductGroupSelectorChange} />
                    </div>
                  )}
                </div>
              </div>
              <ModalFooter className="quick-add-to-shopping-list-modal__footer">
                <CorButton color="text" onClick={onClose}>
                  <ContentstackText contentKey="modals.0.add_to_shopping_list_variant_selector_modal.0.cancel_button_label" />
                </CorButton>
                <CorButton
                  disabled={isEmpty(selectedSku) || sku?.isDiscontinued}
                  onClick={() => addItemToList(selectedSku)}
                >
                  <ContentstackText contentKey="modals.0.add_to_shopping_list_variant_selector_modal.0.next_button_label" />
                </CorButton>
              </ModalFooter>
            </>
          )}
        </>
      </Modal>
    </>
  );
};
