import './cor-small-card-title-category.scss';

import { CorTypography } from 'corporate-ui';
import React from 'react';
import classNames from 'classnames';
import { CorContentstackImage, CorContentstackLink } from 'corporate-components/cor-contentstack';
import {
  ICorFeaturedContentCardVariant,
  ICorFeaturedContentEntry,
} from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { useContent } from 'hooks/use-content';

const CorSmallCardTitleCategory = ({
  contentKeyBase,
  cardVariant = 'small-card-title-category-h4',
}: ICorFeaturedContentCardVariant) => {
  const { getContentByKey } = useContent();
  const entry: ICorFeaturedContentEntry = getContentByKey(contentKeyBase, {});
  const { category, title = 'Image Link' } = entry;

  return (
    <div className="cor-featured-content__small-card-title-category cor-featured-content__card cor-featured-content__card--small-size">
      <CorContentstackLink
        className="cor-featured-content__small-card-title-category__image__container cor-featured-content__card__image__container"
        contentKey={`${contentKeyBase}.pageUrl`}
        fallbackElement="div"
      >
        <span className={'cor-featured-content__small-card-title-category__link-label'}>{category || title}</span>
        {!!entry?.image && (
          <CorContentstackImage
            contentKey={`${contentKeyBase}.image`}
            className="cor-featured-content__small-card-title-category__image cor-featured-content__card__image"
          />
        )}
      </CorContentstackLink>

      {(!!entry?.category || !!entry?.title) && (
        <CorContentstackLink
          className="cor-featured-content__small-card-title-category__description"
          contentKey={`${contentKeyBase}.pageUrl`}
          fallbackElement="div"
        >
          {!!entry?.category && (
            <CorTypography
              variant="small-caps"
              component="h3"
              color="dawn-red"
              data-testid="cor-featured-content__small-card-title-category__category"
            >
              {entry?.category}
            </CorTypography>
          )}

          {!!entry?.title && (
            <CorTypography
              variant="h4"
              component="h4"
              className={classNames({
                'cor-featured-content__small-card-title-category__title--h4': ['small-card-title-category-h4'].includes(
                  cardVariant
                ),
                'cor-featured-content__small-card-title-category__title--h3': ['small-card-title-category-h3'].includes(
                  cardVariant
                ),
              })}
              data-testid="cor-featured-content__small-card-title-category__title"
            >
              {entry?.title}
            </CorTypography>
          )}
        </CorContentstackLink>
      )}
    </div>
  );
};

export default React.memo(CorSmallCardTitleCategory);
