import { FC } from 'react';
import { ICSCorImage, ICSCorPageConfiguration } from 'corporate-interfaces';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { useContent } from 'hooks/use-content';

export interface ICorArticleSchemaProps {
  publicationDate: string;
  image: ICSCorImage;
  shortDescription: string;
  title: string;
}

export const CorArticleSchema: FC<ICorArticleSchemaProps> = ({ title, publicationDate, image, shortDescription }) => {
  const excludeHtmlTagsRegEx = /<\/?(?!li\b)[^>]+(>|$)/g;
  const { getContentByKey } = useContent();
  const articleContent = getContentByKey('modular_blocks[0].rich_text.rich_text', '');
  const pageConfiguration: ICSCorPageConfiguration = getContentByKey(
    'page_configuration',
    {} as ICSCorPageConfiguration
  );

  const { seo_metadata } = pageConfiguration;

  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    name: title,
    image: corContentstackImageLinkResolver(image)?.url ?? '',
    shortDescription,
    keywords: seo_metadata?.keywords?.[0] ?? '',
    datePublished: publicationDate,
    articleBody: articleContent?.replace(excludeHtmlTagsRegEx, ''),
    author: {
      '@type': 'Organization',
      name: 'Dawn Food Products, Inc',
      url: 'https://www.dawnfoods.com/',
    },
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(articleStructuredData),
      }}
    />
  );
};
