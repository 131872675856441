import { combineReducers } from 'redux';
import { Actions } from 'constants/actions.enum';
import { ILineItem } from 'types/order-details';
import { ICarouselProduct, IProductItem, IShoppingList } from 'types/product';
import { createReducer } from 'store/reducer-creator';
import * as shoppingCartActions from 'store/shopping-cart/actions';
import types from './action-types';
import * as actions from './actions';

export interface IOrderData {
  ctId: string;
  orderDate: string;
  orderNumber: string;
  orderStatus: string;
  isQuoteOrder: boolean;
}

export interface IRecentOrdersState {
  limit: number;
  orders: IOrderData[] | null;
  loading: boolean;
}

export interface IBuyItAgainState {
  products: IProductItem[] | null;
  loading: boolean;
  lastRequestedSku: string;
}

export interface IHomeShoppingListsState {
  lists: IShoppingList[] | null;
  loading: boolean;
  total: number;
}

export const recentOrdersInfoInitialState: IRecentOrdersState = {
  limit: 6,
  orders: null,
  loading: false,
};

export const buyItAgainProductsInitialState: IBuyItAgainState = {
  products: null,
  loading: false,
  lastRequestedSku: '',
};

export const homeShoppingListInitialState: IHomeShoppingListsState = {
  lists: null,
  loading: false,
  total: 0,
};

export interface IHomeState {
  recentOrdersInfo: IRecentOrdersState;
  buyItAgainProducts: IBuyItAgainState;
  recentlyViewedItems: ICarouselProduct[];
  homeShoppingLists: IHomeShoppingListsState;
}

export const homeInitialState = {
  recentOrdersInfo: recentOrdersInfoInitialState,
  buyItAgainProducts: buyItAgainProductsInitialState,
  recentlyViewedItems: [],
  homeShoppingLists: homeShoppingListInitialState,
};

export const recentOrdersInfo = createReducer<IRecentOrdersState>(
  {
    [actions.getRecentOrdersInfoConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getRecentOrdersInfoConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...payload,
      loading: false,
    }),
    [actions.getRecentOrdersInfoConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.CLEAR_HOME_TABS_DATA]: () => recentOrdersInfoInitialState,
  },
  recentOrdersInfoInitialState
);

export const buyItAgainProducts = createReducer<IBuyItAgainState>(
  {
    [actions.getBuyItAgainProductsConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
      lastRequestedSku: '',
    }),
    [actions.getBuyItAgainProductsConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      products: payload,
      loading: false,
      lastRequestedSku: '',
    }),
    [actions.getBuyItAgainProductsConstants[Actions.FAIL]]: () => buyItAgainProductsInitialState,
    [actions.getBuyItAgainSkuSubtotalConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: false,
    }),
    [actions.getBuyItAgainSkuSubtotalConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      loading: false,
      products: state?.products?.map((product: IProductItem) => {
        return {
          ...product,
          totalPrice:
            product.number === state.lastRequestedSku
              ? {
                  ...product.totalPrice,
                  amount: payload.amount,
                  currencySymbol: payload.currencySymbol,
                }
              : {
                  ...product.totalPrice,
                  amount: product.price?.amount,
                  currencySymbol: product.price?.currencySymbol,
                },
        };
      }),
    }),
    [types.CLEAR_HOME_TABS_DATA]: () => buyItAgainProductsInitialState,
    [types.CLEAR_BUY_IT_AGAIN_SUBTOTAL]: (state, { payload }) => ({
      ...state,
      loading: false,
      products: state?.products?.map((product: IProductItem) => {
        return {
          ...product,
          totalPrice:
            product.number === payload.skuNumber
              ? {
                  amount: product.price?.amount,
                  currencySymbol: product.price?.currencySymbol,
                }
              : product.totalPrice,
        };
      }),
    }),
    [types.SET_LAST_REQUEST_SKU]: (state, { payload }) => ({
      ...state,
      lastRequestedSku: payload,
    }),
  },
  buyItAgainProductsInitialState
);

const recentlyViewedItems = createReducer<ICarouselProduct[]>(
  {
    [actions.getRecentlyViewedItemsConstants[Actions.SUCCESS]]: (state, { payload }) => [...payload],
    [shoppingCartActions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) =>
      state.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
    [shoppingCartActions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems } }: { payload: { lineItems: ILineItem[] } }
    ) =>
      state.map((product) => ({
        ...product,
        isInCart: product.isInCart || lineItems.some((cartItem) => cartItem.itemNumber === product.sku),
      })),
  },
  []
);

export const homeShoppingLists = createReducer<IHomeShoppingListsState>(
  {
    [actions.getHomeShoppingListsConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.getHomeShoppingListsConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      lists: payload.shoppingLists,
      total: payload.total,
      loading: false,
    }),
    [actions.getHomeShoppingListsConstants[Actions.FAIL]]: (state) => ({
      ...state,
      loading: false,
    }),
    [types.CLEAR_HOME_TABS_DATA]: () => homeShoppingListInitialState,
  },
  homeShoppingListInitialState
);

export default combineReducers({
  recentOrdersInfo,
  buyItAgainProducts,
  recentlyViewedItems,
  homeShoppingLists,
});
