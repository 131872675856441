import {
  ICSHeaderMenuGroupLink,
  IAllProducts,
  IAllProductsItem,
  IAllProductsCategory,
  ICSHeaderCorpNavMenu,
  ICSHeaderCorpNavMenuOut,
} from 'components/layout/cor-header/cor-header.interface';
import { resolveLink, resolveResourceMenuItem } from 'components/layout/cor-header/cor-header.resolvers';
import { Routes } from 'constants/routes.enum';

export const corporateMenuMapper = (menu: ICSHeaderCorpNavMenu): ICSHeaderCorpNavMenuOut => {
  return {
    ...menu,
    links: menu.links.map((item: ICSHeaderMenuGroupLink) => {
      const { menu_link_set, menu_section_title } = item;
      return {
        label: menu_section_title?.title,
        url: menu_section_title?.href,
        ...menu_link_set.reduce(
          (memo: any, item) => {
            const { menu_links, menu_link_set_title, resource_menu_item } = item;
            const child = !!menu_links.length
              ? [
                  {
                    label: menu_link_set_title,
                    url: null,
                    children: menu_links.map((link) => resolveLink(link)),
                  },
                ]
              : [];
            const resourceMenu = !!resource_menu_item.length
              ? resource_menu_item.map((item) => resolveResourceMenuItem(item))
              : [];

            const drillDown =
              !menu_links.length && !!menu_link_set_title
                ? {
                    label: menu_link_set_title,
                    items: [],
                  }
                : {};

            return {
              ...memo,
              children: [...memo.children, ...child],
              resourceMenus: [...memo.resourceMenus, ...resourceMenu],
              drillDownMenu: drillDown,
            };
          },
          { children: [], resourceMenus: [], drillDownMenu: {} }
        ),
      };
    }),
  };
};

export const allProductsMapper = (
  data: IAllProductsCategory[],
  prev: IAllProductsItem | null,
  idx: string,
  panels: IAllProducts[]
): IAllProducts[] => {
  const panel: IAllProducts = {
    id: idx,
    back: prev,
    links: data.map(({ children, ...rest }) => {
      const id: string = rest.categoryKey;
      const next = !!children.length ? { next: id } : {};
      const thisItem: IAllProductsItem = {
        id,
        ...rest,
      };

      if (children) {
        panels = [...allProductsMapper(children, thisItem, id, panels)];
      }
      return {
        ...rest,
        ...next,
        hasNext: !!children.length,
      };
    }),
  };
  panels = [...panels, panel];

  return panels;
};

// The contact link is usually in the utility navigation...
// In mobile, it moves to the main navigation...
// This function handles that change.
// Can be made generic if needed.
export const mergedContactMenuItemIntoCorporate = (corpMenu, utilMenu) => {
  const crossoverMenuItem = utilMenu.filter(
    (item) => item.url === Routes.ContactUs || item.url === Routes.CanadaFrenchContactUs
  )[0];
  const newCorporateMenuItem = crossoverMenuItem
    ? [
        {
          children: [],
          drillDownMenu: {},
          label: crossoverMenuItem.text,
          resourceMenus: [],
          url: crossoverMenuItem.url,
          mobileOnly: true,
        },
      ]
    : [];
  return {
    ...corpMenu,
    links: [...corpMenu.links, ...newCorporateMenuItem],
  };
};
